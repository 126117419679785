<template>
  <div>
    <div class="mb-6">

      <h1 class="font-serif font-bold text-3xl text-gray-900">
        Create your account
      </h1>
      <h2 class="text-gray-600">
        This login will be used for all groups you're associated with.
      </h2>
    </div>
    <div class="flex flex-col sm:flex-row mb-6">
      <TextInput
        id="first_name"
        name="firstName"
        label="First Name"
        v-model="user.firstName"
        :invalid="invalidFields.includes('firstName')"
        class="mr-0 mb-2 sm:mb-0 sm:mr-2"
      />
      <TextInput
        id="last_name"
        name="lastName"
        label="Last Name"
        v-model="user.lastName"
        :invalid="invalidFields.includes('lastName')"
        class="mr-0 sm:ml-2"
      />
    </div>
    <div class="flex flex-col mb-4">
      <TextInput
        id="email"
        name="email"
        label="Email Address"
        type="email"
        v-model="user.email"
        :invalid="invalidFields.includes('email')"
        class="mb-2"
        placeholder="Email address in lowercase characters only"
      />
      <p class="text-gray-600 italic text-sm">
        We recommend using your personal email address (Gmail, etc.) as school
        email addresses may expire.
      </p>
    </div>
    <div class="flex flex-col mb-6">
      <TextInput
        id="password"
        name="password"
        label="Create a password"
        type="password"
        v-model="user.password"
        :invalid="invalidFields.includes('password')"
        class="mb-2"
      />
      <p class="text-gray-600 italic text-sm">
        Passwords must be at least 8 characters.
      </p>
    </div>
    <div class="mt-8 mb-4 p-4 border rounded">
      <span class="text-gray-600">
        By creating an account, you agree to our
        <a
          target="_blank"
          rel="noopener noreferrer"
          class="text-primary underline"
          href="https://reunacy.com/terms-of-use/"
        >
          terms of use</a
        >
        and
        <a
          target="_blank"
          rel="noopener noreferrer"
          class="text-primary underline"
          href="https://reunacy.com/privacy-policy/"
        >
          privacy policy</a
        >.
      </span>
    </div>
  </div>
</template>
<script>
import TextInput from '@/components/TextInput.vue';

export default {
  name: 'SignupFormStepOne',
  components: {
    TextInput,
  },
  props: {
    /**
     * user object to be submitted
     */
    user: {
      default: () => {},
    },
    /**
     * embedded in group form (affects titles)
     */
    inGroupForm: {
      default: () => {},
    },
    /**
     * fields that have errors
     */
    invalidFields: {
      default: () => [],
    },
  },
  methods: {
    navigateToLogin() {
      this.$router.push({ name: 'login' });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
