<template>
  <div class="mt-8 mx-6 sm:mx-auto">
    <template v-if="groupFeatureFlags && groupFeatureFlags.messageBoardEnabled">
      <div class="flex flex-wrap md:flex-nowrap sm:mt-12 gap-8">
        <div class="w-full md:w-2/3 order-2 md:order-1">
          <div class="flex flex-col rounded bg-white shadow-drop px-3 py-4 md:px-8 md:py-6">
            <MessageForm
              title="Post a New Message"
              is-wrapped
              is-can-add-image
              @input="sendMessage"
            />
          </div>

          <Post
            v-for="message in messages"
            :key="message.id"
            :message="message"
            @refetch="getMessages"
            :is-offensive-flag-enabled="groupFeatureFlags.offensiveContentFlaggingEnabled"
          />
        </div>
        <div class="w-full md:w-1/3 order-1 md:order-2">
          <div
            v-if="members.length"
            class="flex flex-col rounded bg-white shadow-drop pt-6 pb-8 px-6 max-h-[650px] overflow-y-auto sticky top-24"
          >
            <h2 class="pb-4 mb-4 border-b border-gray-400">
              Members <span class="text-gray-700 text-xs">({{ members.length }})</span>
            </h2>

            <Member
              :profile="member"
              v-for="member in isShowAllMembers ? members : firstTenMembers"
              :key="member.id"
            />
            <button
              v-if="members.length > LIMIT_MEMBERS"
              type="button"
              @click.stop="toggleAllMembers"
              class="show-all-btn mt-4"
            >
              {{ isShowAllMembers ? 'View less' : 'View all' }}
              <ArrowSortTop class="icon" :class="{ 'rotate-180': !isShowAllMembers }" />
            </button>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="p-8 flex w-full flex-col rounded bg-white">
        <div class="flex gap-2 border-b border-gray-400 pb-2 mb-8">
          <div class="flex flex-col w-full md:w-[60%]">
            <IconExclusive class="mb-2" />
            <h2 class="self-stretch text-gray-900 text-2xl mb-2">
              Post and share messages with our Exclusive Plan
            </h2>
            <p class="self-stretch text-gray-900 text-base mb-4">
              Your group is currently on the Free Plan. Upgrade to an Exclusive Plan subscription to
              give everyone in the group access to the message board feature!
            </p>
            <img
              alt="illustration"
              src="@/assets/images/feed_illustration.png"
              class="mb-4 max-w-full h-auto block md:hidden"
            />
            <h3 class="text-gray-900 text-base font-bold uppercase mb-3">
              What do you get with the message board feature?
            </h3>
            <ul>
              <li class="flex items-center mb-3">
                <span class="block w-2 h-2 bg-orange-500 rounded-full mr-2" />
                A place to reunite with members of your group
              </li>
              <li class="flex items-center mb-3">
                <span class="block w-2 h-2 bg-orange-500 rounded-full mr-2" />
                Discuss, plan, and share details for your events
              </li>
              <li class="flex items-center mb-3">
                <span class="block w-2 h-2 bg-orange-500 rounded-full mr-2" />
                Add a photo to a message (automatically adds it to your photo albums!)
              </li>
              <li class="flex items-center mb-3">
                <span class="block w-2 h-2 bg-orange-500 rounded-full mr-2" />
                See group highlights – like when a new member joins
              </li>
            </ul>
          </div>

          <div class="w-[40%] pt-3 hidden md:block">
            <img
              alt="illustration"
              src="@/assets/images/feed_illustration.png"
              class="max-w-full h-auto"
            />
          </div>
        </div>

        <Button
          class="h-[48px] max-w-full md:max-w-[200px]"
          compact
          :primary-full-size="false"
          type="primary"
          @onClick="isGroupAdmin ? subscribe() : showAdminUpgradeModal()"
        >
          {{ isGroupAdmin ? 'Subscribe Now' : 'Ask Your Admin To Upgrade' }}
        </Button>

        <transition name="component-fade" mode="out-in">
          <DialogDefault
            v-if="isShowAdminUpgradeModal"
            header="Ask Admin to Upgrade"
            text="The email will be sent to group admins who have agreed to receive messages."
            @cancel="hideAdminUpgradeModal"
            @apply="requestToAdmin"
            is-show-close-btn
            scheme="secondary"
            apply-btn-text="Send Message"
            container-class="sm:w-[800px]"
            action-btn-class="w-full md:w-[220px] text-sm"
            action-wrapper-class="justify-between"
          >
            <form class="pb-12">
              <input
                type="text"
                v-model="adminMessage"
                placeholder="Enter message"
                class="input h-[56px] py-4 px-5 w-full text-gray-900 bg-white rounded shadow border border-gray-300 text-base placeholder-gray-500 focus:outline-none transition-colors duration-200 ease"
              />
            </form>
          </DialogDefault>
        </transition>
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ArrowSortTop from '@/assets/arrow_sort_top.svg';
import Member from '@/views/Group/components/feed/Member.vue';
import MessageForm from '@/components/forms/MessageForm.vue';
import Post from '@/views/Group/components/feed/Post.vue';
import { createMessageService, getMessagesService } from '@/services/feed.service';
import { MessageRecord } from '@/records/message.record';
import DialogDefault from '@/components/DialogDefault.vue';
import IconExclusive from '@/components/svgComponents/IconExclusive.vue';
import Button from '@/components/Button.vue';
import { requestSubscriptionService } from '@/services/global.service';

const LIMIT_MEMBERS = 10;

export default {
  name: 'Feed',
  components: {
    Button,
    IconExclusive,
    DialogDefault,
    Post,
    ArrowSortTop,
    Member,
    MessageForm,
  },
  data() {
    return {
      LIMIT_MEMBERS,
      adminMessage: 'Please consider upgrading our group account to access Message Board.',
      isShowAdminUpgradeModal: false,
      messages: [],
      members: [],
      isShowAllMembers: false,
    };
  },
  computed: {
    ...mapGetters(['group', 'profiles', 'user', 'groupFeatureFlags']),
    firstTenMembers() {
      return this.members.slice(0, LIMIT_MEMBERS);
    },
    isGroupAdmin() {
      const currentProfile = this.profiles.find((profile) => profile.id === this.user.id);
      return currentProfile && currentProfile.isGroupAdmin;
    },
  },
  watch: {
    profiles(newValue) {
      if (newValue.length) this.getAdminsFromList();
    },
  },
  created() {
    this.getMessages();
    if (this.profiles.length) {
      this.getAdminsFromList();
    }
  },
  methods: {
    toggleAllMembers() {
      this.isShowAllMembers = !this.isShowAllMembers;
    },

    showAdminUpgradeModal() {
      this.isShowAdminUpgradeModal = true;
    },

    hideAdminUpgradeModal() {
      this.isShowAdminUpgradeModal = false;
    },

    async requestToAdmin() {
      await requestSubscriptionService(this.group.slug, this.adminMessage, 'message_board');
      this.hideAdminUpgradeModal();
    },

    shuffleMembers(array) {
      const shuffledArray = [...array];

      for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
      }

      return shuffledArray;
    },

    getAdminsFromList() {
      if (this.profiles.length) {
        const admins = [];
        const members = [];
        this.profiles.forEach((member) => {
          if (member.isGroupAdmin) {
            admins.push(member);
          } else {
            members.push(member);
          }
        });

        this.members = [...admins, ...this.shuffleMembers(members)];
      }
    },

    async getMessages() {
      if (this.groupFeatureFlags && !this.groupFeatureFlags.messageBoardEnabled) return;
      const result = await getMessagesService(this.group.slug);
      this.messages = result.map((msg) => new MessageRecord(msg));
    },

    async sendMessage(message, imageBlob) {
      await createMessageService(this.group.slug, message, imageBlob);
      await this.getMessages();
    },

    subscribe() {
      this.$router.push({
        name: 'admin',
        params: {
          group: this.group.slug,
          currentPageViewProp: 'subscription',
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.show-all-btn {
  @apply flex items-center justify-start hover:text-orange-400 max-w-[100px] h-[40px] py-2 rounded-sm;

  &:hover {
    .icon path {
      @apply stroke-orange;
    }
  }
}
</style>
