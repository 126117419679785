<template>
  <div class="relative">
    <MenuTriggerIcon
      @click="isMenuOpen = !isMenuOpen"
      v-click-outside="close"
      class="trigger-icon w-5 h-5 animation-focus-default cursor-pointer"
    />

    <div
      :class="isMenuOpen ? 'opacity-100 scale-100' : 'opacity-0 scale-95'"
      class="z-10 origin-top-right absolute right-0 top-7 w-48 transition transform ease-out duration-100 rounded-md shadow-lg bg-white focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="pinned-project-options-menu-0-button"
      tabindex="-1"
    >
      <div v-if="isMenuOpen" class="py-1">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import MenuTriggerIcon from '@/assets/icon_more.svg';

export default {
  name: 'MenuDropdownContainer',
  components: {
    MenuTriggerIcon,
  },
  data() {
    return {
      isMenuOpen: false,
    };
  },
  methods: {
    close() {
      this.isMenuOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.trigger-icon {
  &:hover circle {
    @apply fill-current;
  }
}
</style>
