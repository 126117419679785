<template>
  <span>
    <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="9" cy="9" r="3" stroke="#2A2B2E" stroke-width="1.5" />
      <path
        d="M7.34689 2.0087C8.09521 0.759913 9.90479 0.759913 10.6531 2.0087V2.0087C11.0895 2.73697 11.9511 3.09386 12.7747 2.88748V2.88748C14.1868 2.53359 15.4664 3.81316 15.1125 5.22532V5.22532C14.9061 6.04888 15.263 6.91049 15.9913 7.34689V7.34689C17.2401 8.09521 17.2401 9.90479 15.9913 10.6531V10.6531C15.263 11.0895 14.9061 11.9511 15.1125 12.7747V12.7747C15.4664 14.1868 14.1868 15.4664 12.7747 15.1125V15.1125C11.9511 14.9061 11.0895 15.263 10.6531 15.9913V15.9913C9.90479 17.2401 8.09521 17.2401 7.34689 15.9913V15.9913C6.91049 15.263 6.04888 14.9061 5.22532 15.1125V15.1125C3.81316 15.4664 2.53359 14.1868 2.88748 12.7747V12.7747C3.09386 11.9511 2.73697 11.0895 2.0087 10.6531V10.6531C0.759913 9.90479 0.759913 8.09521 2.0087 7.34689V7.34689C2.73697 6.91049 3.09386 6.04888 2.88748 5.22532V5.22532C2.53359 3.81316 3.81316 2.53359 5.22532 2.88748V2.88748C6.04888 3.09386 6.91049 2.73697 7.34689 2.0087V2.0087Z"
        stroke="#2A2B2E"
        stroke-width="1.5"
      />
    </svg>
  </span>
</template>
<script>
export default {
  name: 'IconSettings',
};
</script>
