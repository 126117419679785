<template>
  <div class="mx-6 sm:mx-auto">
    <h4 class="font-serif text-2_25xl sm:text-2_5xl lg:text-2_8xl font-normal mb-7 sm:mb-8 lg:mb-7">
      When will it happen?
    </h4>

    <div class="flex flex-col">
      <div class="mb-3 sm:mb-7 ">
        <div class="text-gray-800 font-semibold text-sm mb-2">Start</div>
        <div class="flex flex-col sm:flex-row">
            <CalendarInput
              id="nextEventDate"
              label="Select date"
              focusedPlaceholder="MM-DD-YYYY"
              customWidth
              :readonly="true"
              :showLabel="true"
              class="mr-0 md:w-full w-full mb-4 sm:mb-0"
              v-model="eventObject.startDate"
              :localStringFormat="timeLocalFormat"
              :dateRestrictFunction="notBeforeToday"
            >
              <template v-slot:icon>
                <CalendarIcon class="calendar-icon w-7 h-7"></CalendarIcon>
              </template>
            </CalendarInput>
        <div class="flex flex-col sm:flex-row">
          <DropdownAutocomplete
            label="Begins at..."
            :options="items"
            class="w-80 mb-3 sm:mb-0 h-56px sm:ml-2"
            v-model="eventObject.startTime"
            :showLabel="true"
            :readonly="true"
          >
            <template v-slot:icon>
              <ClockIcon class="w-5 h-5"></ClockIcon>
            </template>
          </DropdownAutocomplete>
        </div>
      </div>
      </div>
      <div class="mt-2">
      <div class="mb-3 sm:mb-7">
        <div class="text-gray-800 font-semibold text-sm mb-2">End</div>
        <div class="flex flex-col sm:flex-row">
        <CalendarInput
          id="nextEventDate"
          label="Select date"
          focusedPlaceholder="MM-DD-YYYY"
          customWidth
          :readonly="true"
          :showLabel="true"
          class="mr-0 md:w-full w-full mb-4 sm:mb-0"
          v-model="eventObject.endDate"
          :localStringFormat="timeLocalFormat"
          :dateRestrictFunction="notBeforeToday"
        >
          <template v-slot:icon>
            <CalendarIcon class="calendar-icon w-7 h-7"></CalendarIcon>
          </template>
        </CalendarInput>

        <div class="flex flex-col sm:flex-row">
          <DropdownAutocomplete
            label="Ending at..."
            :options="items"
            class="w-80 sm:ml-2 h-56px"
            v-model="eventObject.endTime"
            :showLabel="true"
            :readonly="true"
          >
            <template v-slot:icon>
              <ClockIcon class="w-5 h-5"></ClockIcon>
            </template>
          </DropdownAutocomplete>
        </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { DateTime } from 'luxon';
import CalendarInput from '@/components/CalendarInput.vue';
import CalendarIcon from '@/assets/icon_calendar_input.svg';
import ClockIcon from '@/assets/icon_clock_large.svg';
import DropdownAutocomplete from '@/components/baseComponents/DropdownAutocomplete.vue';
import { timelineLabels } from '../../helpers';

export default {
  name: 'EventTime',
  components: {
    // TextInput,
    CalendarInput,
    CalendarIcon,
    ClockIcon,
    DropdownAutocomplete,
  },
  props: {
    eventObject: {
      type: Object,
    },
  },
  methods: {
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    // todo: how to make a function, that binds option and opposite dropdown value to eval disabling
    disableBeginOption(option) {
      if (this.eventObject.endTime) {
        return DateTime.fromFormat(this.eventObject.endTime, 'h:mm a').millisecond < DateTime.fromFormat(option, 'h:mm a').millisecond;
      }

      return false;
    },
    disableEndOption(option) {
      if (this.eventObject.startTime) {
        return DateTime.fromFormat(this.eventObject.startTime, 'h:mm a').millisecond > DateTime.fromFormat(option, 'h:mm a').millisecond;
      }

      return false;
    },
  },
  computed: {
    ...mapGetters(['globalErrors', 'isLoading']),
    items() {
      return timelineLabels('09:00', 15, 'minute');
    },
    timeLocalFormat() {
      return DateTime.DATE_FULL;
    },
  },
  watch: {
    globalErrors() {
    },
    'eventObject.startDate': function (newVal) {
      this.eventObject.endDate = newVal;
    },


  },
};
</script>

<style lang="scss">
.calendar-icon {
  width: 20px !important;
  height: 20px !important;
  margin-top: -2px !important;
  fill: none !important;
}
</style>
