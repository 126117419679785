<template>
  <div class="relative inline-flex" v-click-outside="close">
    <button
      @click="toggleMenu"
      @keydown.up.stop.prevent="close"
      @keydown.down.stop.prevent="open"
      @keydown.tab="close"
      class="trigger flex justify-between items-center text-sm font-normal
      cursor-pointer origin-bottom w-auto
      focus:outline-none py-0 px-0"
      aria-haspopup="listbox"
      :aria-expanded="ariaExpanded"
      :disabled="disabled"
      ref="trigger">

      <span v-if="label" class="dropdown-label text-sm font-sans font-normal">
        {{ label }}
      </span>

      <span v-if="selectedOptions.length" class="counter">
        {{ selectedOptions.length }}
      </span>

      <span class="inline-block transition-transform duration-200 ease transform ml-2 mt-1"
            :class="{
              'rotate-180': ariaExpanded,
            }"
            aria-hidden="true">
        <Chevron class="w-3"/>
      </span>
    </button>

    <div class="menu absolute bg-white rounded z-20"
         :class="[
           {'menu-hidden pointer-events-none': !ariaExpanded},
           ]"
         role="listbox"
         ref="menu"
         @keydown.tab="close"
         @keydown.down.stop.prevent="moveDown()"
         @keydown.up.stop.prevent="moveUp()"
    >
      <ul class="p-2 border border-gray-300 rounded">
        <li v-for="(option, index) in options"
            @keydown.enter.stop.prevent="selectOption(option)"
            @keydown.space.stop.prevent="selectOption(option)"
            @click="selectOption(option)"
            class="cursor-pointer px-3 py-2 text-base text-left w-full whitespace-no-wrap
          hover:bg-gray-100 focus:outline-none focus:bg-gray-100
          transition-colors duration-100 ease rounded flex items-center justify-between"
            :class="[{
            'selected': selected(option),
            'mb-1': index !== options.length - 1
           }]"
            role="option"
            tabindex="-1"
            :key="`${option.value}_${index}`"
        >
          <div class="text-sm font-normal flex items-center">
            <component class="mr-2" v-if="option.iconImport" :is="option.iconImport"></component>
            {{ option.label }}
          </div>

          <div class="px-2 text-small font-semibold text-center min-w-8 rounded-3xl bg-gray-200"
               :class="{'bg-group-color-primaryBlue text-white': selected(option)}"
          >
            {{option.count}}
          </div>
        </li>
        <slot></slot>
      </ul>
    </div>

  </div>
</template>

<script>
import Chevron from '@/assets/chevron.svg';

export default {
  name: 'DropdownCounter',
  components: {
    Chevron,
  },
  props: {
    /**
     * The options passed into the Dropdown component.
     */
    options: {
      type: Array,
      required: true,
    },
    /**
     * Determines whether Dropdown can be interacted with
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Label for dropdown
     */
    label: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      ariaExpanded: false,
      menuItems: [],
      selectedOptions: [],
      selectedItemIndex: 0,
    };
  },
  methods: {
    selectOption(option) {
      this.$refs.trigger.focus();

      const optionIndex = this.selectedOptions.indexOf(option);
      if (this.selectedOptions.length && optionIndex >= 0) {
        this.selectedOptions.splice(optionIndex, 1);
      } else {
        this.selectedOptions.push(option);
      }

      this.$emit('selection', this.selectedOptions);
    },
    toggleMenu() {
      return this.ariaExpanded ? this.close() : this.open();
    },
    open() {
      this.ariaExpanded = true;
      this.$nextTick(() => {
        this.menuItems[this.selectedItemIndex].focus();
      });
    },
    close() {
      this.ariaExpanded = false;
    },
    moveDown(increment = 1) {
      this.selectedItemIndex = this.limitIndexRange(
        this.selectedItemIndex + increment,
        0,
        this.menuItems.length - 1,
      );
      this.menuItems[this.selectedItemIndex].focus();
      this.menuItems[this.selectedItemIndex].scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    },
    moveUp(increment = -1) {
      this.moveDown(increment);
    },
    limitIndexRange(operand, min, max) {
      return Math.min(Math.max(operand, min), max);
    },
    selected(option) {
      return this.selectedOptions.some((el) => el.value === option.value);
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.menu) {
        this.menuItems = Array.prototype.slice.call(this.$refs.menu.querySelectorAll('li'));
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.menu {
  min-width: 224px;
  max-height: 206px;
  overflow-y: hidden;
  top: 36px;
  right: 0;
  transition: max-height 0.2s ease-in-out, transform 0.2s ease;

  &.big-menu {
    max-height: 200px;
  }

  &.menu-hidden {
    @apply max-h-0;
  }

  .count {
    padding-top: 3px;
    padding-bottom: 3px;
  }
}

.counter {
  margin-bottom: -2px;
  border-radius: 20px;
  padding: 2px 12px;
  @apply text-small bg-gray-300 font-semibold ml-2 min-w-7;
}

.trigger:focus > span > svg {
  @apply text-primary;
}

.disabled {
  @apply pointer-events-none cursor-default shadow-none bg-gray-200 text-gray-500;
}

.selected {
  @apply bg-gray-100;
}
</style>
