<template>
  <div class="dropdown overflow-visible" v-if="options">

    <!-- Dropdown Input -->
    <input class="focus:outline-none input border border-gray-300 w-full h-full
     text-gray-900 bg-white rounded shadow text-sm placeholder-gray-500"
           :class="[
          {'input--filled': selected && selected !== ''},
          {'border-red-600 border-1': invalid},
          {'disabled': disabled},
          {'py-5 pl-12 pr-4': (hasIcon && !showLabel) && label && !compact},
          {'py-3 pl-14 pr-4': (hasIcon && showLabel) && label && !compact},
          {'pt-7 pb-3 px-4': !hasIcon && label && !compact},
          {'py-5 px-4': !label && !compact},
          {'py-2 pl-10 pr-2': hasIcon && compact},
          {'py-2 px-4': !hasIcon && compact},
          {'h-full': customHeight},
          {'cursor-pointer': readonly}
        ]"
           :name="name"
           @focus="showOptions()"
           @blur="exit()"
           @keyup="keyMonitor"
           v-model="searchFilter"
           :disabled="disabled"
           autocomplete="off"
           autocorrect="false"
           :readonly="readonly"
           :placeholder="placeholder || (focused && focusedPlaceholder)"
    />

    <template v-if="label">
      <label v-if="!hasIcon"
             class="input__label absolute left-0 text-gray-600 mx-4 pointer-events-none mt-2
        origin-top-left
        transition duration-200 ease-out"
             :class="[
          {'text-red-600': invalid},
        ]"
             :for="id">
        {{ label }}
      </label>
      <label v-if="hasIcon && showLabel"
             class="input__label absolute left-0 text-gray-600 mt-2px mr-4 ml-14 pointer-events-none
          origin-top-left transition duration-200 ease-out"
             :class="[
            {'text-red-600': invalid},
          ]"
             :for="id">
        {{ label }}
      </label>
      <span v-if="hasIcon"
            class="icon-wrapper absolute top-0 left-0 h-full flex items-center ml-4 text-gray-400
            transition-colors duration-200 ease-out pointer-events-none px-1">
          <label :for="id" class="pointer-events-none opacity-0 w-0 h-0">{{ label }}</label>
          <slot name="icon"></slot>
        </span>
    </template>

    <!-- Dropdown Menu -->
    <div class="dropdown-content"
         v-show="optionsShown">
      <div
        class="dropdown-item"
        :class="{
        'bg-gray-400': disableOption(),
        'hover:bg-gray-300 hover:cursor-pointer': !disableOption(),
        }"
        @mousedown="selectOption(option)"
        v-for="(option, index) in filteredOptions"
        :key="index"
      >
        {{ option }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DropdownAutocomplete',
  props: {
    name: {
      type: String,
      required: false,
      default: 'dropdown',
      note: 'Input name',
    },
    options: {
      type: Array,
      required: true,
      default: () => [],
      note: 'Options of dropdown. An array of options with id and name',
    },
    label: {
      type: String,
      required: false,
      note: 'Placeholder of dropdown',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Disable the dropdown',
    },
    maxItem: {
      type: Number,
      required: false,
      default: 100,
      note: 'Max items showing',
    },
    value: {
      type: String,
      default: '',
    },
    id: {
      type: String,
    },
    /**
     * Placeholder text inside the input
     */
    placeholder: {
      type: String,
      default: '',
    },
    /**
     * Placeholder text inside the input when input is focused
     */
    focusedPlaceholder: {
      type: String,
      default: '',
    },
    /**
     * Input type. Can be changed to password or email, for instance
     */
    type: {
      type: String,
      default: 'text',
    },
    /**
     * Determines whether a field is invalid and should display differently (e.g. a red border)
     */
    invalid: {
      type: Boolean,
      default: false,
    },
    /**
     * The max number of characters of the input field
     */
    maxLength: {
      type: Number,
      default: 524288, // The HTML spec default value
    },
    /**
     * Flag specifically for text inputs with icons that should show the label too
     */
    showLabel: {
      type: Boolean,
      default: false,
    },
    /**
     * removes full width styling if true
     */
    customWidth: {
      type: Boolean,
      default: false,
    },
    customHeight: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disableFn: {
      type: Function,
    },
  },
  data() {
    return {
      selected: this.value || '',
      optionsShown: false,
      searchFilter: this.value || '',
      focused: false,
    };
  },
  computed: {
    filteredOptions() {
      const filtered = [];
      const regOption = new RegExp(this.searchFilter, 'ig');
      for (const option of this.options) {
        if (this.searchFilter.length < 1 || option.match(regOption)) {
          if (filtered.length < this.maxItem) filtered.push(option);
        }
      }
      return filtered;
    },
    hasIcon() {
      return this.$slots.icon;
    },
  },
  methods: {
    selectOption(option) {
      this.selected = option;
      this.optionsShown = false;
      this.searchFilter = this.selected;
      this.$emit('input', this.selected);
    },
    showOptions() {
      if (!this.disabled) {
        this.searchFilter = '';
        this.optionsShown = true;
        this.focused = true;
      }
    },
    disableOption(option) {
      return this.disableFn ? this.disableFn.call(option) : false;
    },
    exit() {
      if (!this.selected) {
        this.selected = '';
        this.searchFilter = '';
      } else {
        this.searchFilter = this.selected;
      }
      this.$emit('input', this.selected);
      this.focused = false;
      this.optionsShown = false;
    },
    // Selecting when pressing Enter
    keyMonitor(event) {
      if (event.key === 'Enter' && this.filteredOptions[0]) this.selectOption(this.filteredOptions[0]);
    },
  },
  watch: {
    searchFilter() {
      if (this.filteredOptions.length === 0) {
        this.selected = '';
      }
      this.$emit('filter', this.searchFilter);
    },
    value() {
      if (this.value !== '') {
        this.selected = this.value;
        this.searchFilter = this.value;
      }
      this.$emit('input', this.selected);
    },
  },
};
</script>


<style lang="scss" scoped>
.input__label {
  transform: translateY(0.85rem);
}

.input:focus {
  @apply border-gray-400;
}

.input:focus + .input__label, .input--filled + .input__label {
  display: none;
}

.input:focus + .input__label {
  @apply text-primary;
}

.input:focus + .icon-wrapper {
  @apply text-primary;
}

.disabled {
  @apply bg-gray-200 shadow-none text-gray-700;
}

.dropdown {
  position: relative;
  display: block;

  .dropdown-content {
    position: absolute;
    background-color: #fff;
    min-width: 100%;
    max-height: 248px;
    border: 1px solid #e7ecf5;
    box-shadow: 0px -8px 34px 0px rgba(0, 0, 0, 0.05);
    overflow: auto;
    z-index: 1;

    .dropdown-item {
      line-height: 1em;
      padding: 8px;
      margin: 2px 4px;
      border-radius: 4px;
      text-decoration: none;
      display: block;
      cursor: pointer;
    }
  }

  .dropdown:hover .dropdowncontent {
    display: block;
  }
}
</style>
