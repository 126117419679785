<template>
  <span>
    <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 5.5C11 7.40009 8.63271 9.76281 7.15592 11.0519C6.48789 11.635 5.51211 11.635 4.84408 11.0519C3.36729 9.76281 1 7.40009 1 5.5C1 2.73858 3.23858 0.5 6 0.5C8.76142 0.5 11 2.73858 11 5.5Z"
        stroke="currentColor"
      />
      <circle cx="6" cy="5.5" r="1.5" stroke="currentColor" />
    </svg>
  </span>
</template>
<script>
export default {
  name: 'IconLocation',
};
</script>
