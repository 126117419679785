<template>
  <span>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.00004 10V2M8.00004 10L5.33337 7.33333M8.00004 10L10.6667 7.33333"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.33337 11.3335L1.74737 12.9902C1.81948 13.2786 1.98592 13.5347 2.22026 13.7177C2.4546 13.9007 2.74338 14.0001 3.04071 14.0002H12.9594C13.2567 14.0001 13.5455 13.9007 13.7798 13.7177C14.0142 13.5347 14.1806 13.2786 14.2527 12.9902L14.6667 11.3335"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </span>
</template>
<script>
export default {
  name: 'IconDownload',
};
</script>
