import { ProfileRecord } from '@/records/profile.record';
import { DateTime } from 'luxon';
import { EventRecord } from '@/records/event.record';
import { FundraiserRecord } from '@/records/fundraiser.record';
import { MemberRecord } from '@/records/member.record';

export class MessageRecord {
  constructor(record = {}) {
    // eslint-disable-next-line no-underscore-dangle
    this._record = record;
    this.content = record.content || '';
    this.event = record.event ? new EventRecord(record.event) : null;
    this.fundraiser = record.fundraiser ? new FundraiserRecord(record.fundraiser) : null;
    this.id = record.id || '';
    this.isDeleted = record.isDeleted || false;
    this.likes = (record.likes && record.likes.map((d) => new ProfileRecord(d.profile))) || [];
    this.newGroupMember = record.newGroupMember ? new MemberRecord(record.newGroupMember) : null;
    this.photo = record.photo || '';
    this.postedDatetime = record.postedDatetime || '';
    this.profile = record.profile ? new ProfileRecord(record.profile) : null;
    this.isFlaggedAsOffensive = record.isFlaggedAsOffensive || null;
    this.isHidden = record.isHidden || false;
    this.subDiscussions =
      (record.subDiscussions && record.subDiscussions.map((d) => new MessageRecord(d))) || [];
  }

  get postedDate() {
    if (this.postedDatetime) {
      return DateTime.fromISO(this.postedDatetime, { zone: 'utc' }).toRelative();
    }

    return '';
  }
}
