<template>
  <div class="py-6 pl-6 max-h-[440px] overflow-y-auto relative">
    <span class="absolute m-2 right-0 top-0 text-sm text-gray-700 sm:text-gray-500">
      (Optional)
    </span>

    <h4
      class="font-serif pr-16 sm:pr-0 text-2_25xl sm:text-2_5xl lg:text-2_8xl font-light sm:font-normal mb-3"
    >
      Is there a cost to attend?
    </h4>

    <p class="w-80 sm:w-116 text-gray-700 text-sm sm:pr-8">
      Provide a link to your preferred payment account (PayPal, Venmo, Cash or Zelle.) This will
      allow group members to send money directly to you for entry.
    </p>
    <!-- TODO: make this hook up to maps -->
    <div class="flex flex-col w-[90%]">
      <div class="mt-6 lg:mt-8">
        <div class="text-gray-800 font-semibold text-sm mb-2">Ticket Price / Cost</div>
        <TextInput
          label="$25"
          class="w-full"
          v-model="price"
          customWidth
          :invalid="invalidInput('Ticket Price')"
          :error-message="getErrorMessage()"
        >
          <template v-slot:icon>
            <TicketIcon class="ticket-icon w-5 h-5" />
          </template>
        </TextInput>
      </div>
      <div class="flex justify-between items-center pt-4">
        <p class="text-gray-900 text-[17px] font-bold leading-[140%]">Payment Links</p>
      </div>

      <div v-for="provider in providers" :key="provider.id" class="mt-6 lg:mt-8">
        <div class="flex items-center text-gray-800 font-semibold text-sm mb-2">
          {{ provider.name }}
          <IconQuestion
            v-if="provider.helpLink"
            v-tooltip="{
              content: generateTooltipContent(provider.helpLink),
              autoHide: false,
            }"
            class="alert-icon"
          />
        </div>
        <div class="flex gap-4 items-center">
          <div class="flex justify-center items-center w-10 h-10 shrink-0 rounded">
            <img :src="provider.image" alt="provider-picture" />
          </div>
          <div class="relative w-full">
            <TextInput
              placeholder="Enter Link"
              v-model="eventObject.paymentLinks[provider.name].url"
              @focus="clearError"
              customWidth
            >
              <template v-slot:icon>
                <TicketIcon class="ticket-icon w-5 h-5" />
              </template>
            </TextInput>
            <span
              v-if="errorObject && errorObject.providers.includes(provider.name)"
              class="error-message"
            >
              {{ errorObject.message }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from '@/components/TextInput.vue';
import TicketIcon from '@/assets/icon_ticket.svg';
import IconQuestion from '@/assets/icon_question.svg';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'EventTickets',
  components: {
    TextInput,
    TicketIcon,
    IconQuestion,
  },
  props: {
    eventObject: {
      type: Object,
    },
    providers: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['globalErrors', 'isLoading']),
    errorObject() {
      return this.globalErrors.find((el) => el.fieldName === 'Payment Links') || null;
    },
    price: {
      // getter
      get() {
        return `$${this.eventObject.ticketPrice || ''}`;
      },
      // setter
      set(newValue) {
        let setValue = newValue;
        if (newValue.indexOf('$') !== -1) {
          setValue = newValue.slice(1, newValue.length);
        }
        if (setValue === '') {
          this.eventObject.ticketPrice = null;
        } else {
          this.eventObject.ticketPrice = setValue;
        }
      },
    },
  },

  methods: {
    ...mapActions(['clearGlobalErrors']),
    generateTooltipContent(link) {
      return `<p class="text-white text-center"><a class="text-blue-200 underline" href="${link}" target="_blank">Click here</a> for steps on <br /> how to get your payment link.</p>`;
    },

    clearError() {
      this.clearGlobalErrors();
    },

    getErrorMessage() {
      if (this.globalErrors.find((el) => el.fieldName === 'Ticket Price')) {
        return this.globalErrors.find((el) => el.fieldName === 'Ticket Price').message;
      }

      return '';
    },
    invalidInput(name) {
      return this.globalErrors.some((el) => el.fieldName === name);
    },
  },
};
</script>

<style lang="scss" scoped>
.ticket-icon path {
  stroke: #808285;
}

.alert-icon {
  @apply w-[18px] ml-[8px];

  circle,
  path {
    @apply stroke-green;
  }
}
</style>
