<template>
  <div>
    <p class="flex items-center justify-between bg-gray-100 rounded w-full my-2 pl-4 pr-2 py-3">
      <span class="min-w-[80%] text-gray-900 text-sm font-semibold pr-2">
        {{ promptInfo.prompt }}
      </span>
      <span class="flex">
        <button type="button" class="action-btn" @click="showEditModal">
          <Edit />
        </button>
        <button type="button" class="action-btn" @click="showDeleteModal">
          <Delete />
        </button>
      </span>
    </p>

    <transition-group name="component-fade" mode="out-in">
      <DialogDefault
        v-if="isShowDeleteModal"
        header="Delete Prompt"
        @cancel="hideDeleteModal"
        @apply="deletePrompt"
        is-show-close-btn
        scheme="secondary"
        key="DeleteModal"
        apply-btn-text="Delete"
      >
        <p class="text-gray-900 text-base font-normal leading-[160%]">
          Are you sure you want to delete this video prompt?
        </p>
        <p
          class="rounded px-3 py-4 bg-gray-100 text-gray-900 text-sm font-semibold leading-[140%] mt-4 mb-8"
        >
          "{{ promptInfo.prompt }}"
        </p>
      </DialogDefault>

      <DialogDefault
        v-if="isShowEditModal"
        header="Edit Prompts"
        @cancel="hideEditModal"
        @apply="updatePrompt"
        is-show-close-btn
        scheme="secondary"
        container-class="sm:w-[600px]"
        apply-btn-text="Update"
        key="EditModal"
      >
        <TextInput
          class="mt-6 mb-10"
          label="Phrase your prompt as an open-ended question."
          placeholder="Type a prompt..."
          v-model="editablePrompt"
        />
      </DialogDefault>
    </transition-group>

    <Toast v-if="error">{{ error }}</Toast>
  </div>
</template>

<script>
import Edit from '@/assets/edit.svg';
import Delete from '@/assets/delete.svg';
import { mapActions } from 'vuex';
import DialogDefault from '@/components/DialogDefault.vue';
import Toast from '@/components/Toast.vue';
import TextInput from '@/components/TextInput.vue';

export default {
  name: 'CustomPromptItem',
  components: {
    TextInput,
    Toast,
    Delete,
    Edit,
    DialogDefault,
  },
  props: {
    /**
     * prompt info (id, text, etc)
     */
    promptInfo: {
      type: Object,
    },
    /**
     * total number of visible prompts
     */
    promptCount: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      error: '',
      isShowEditModal: false,
      isShowDeleteModal: false,
      editablePrompt: '',
    };
  },

  created() {
    this.editablePrompt = this.promptInfo.prompt;
  },

  methods: {
    ...mapActions(['deleteGroupVideoPrompt', 'fetchGroupVideoPrompts', 'updateGroupVideoPrompt']),

    hideDeleteModal() {
      this.isShowDeleteModal = false;
    },

    showDeleteModal() {
      this.isShowDeleteModal = true;
    },

    hideEditModal() {
      this.isShowEditModal = false;
    },

    showEditModal() {
      this.isShowEditModal = true;
    },

    async deletePrompt() {
      if (this.promptCount === 1) {
        this.error = 'Sorry, groups must have at least one video prompt.';
        return;
      }
      try {
        await this.deleteGroupVideoPrompt({
          groupSlug: this.$route.params.group,
          promptId: this.promptInfo.id,
        });
        await this.fetchGroupVideoPrompts(this.$route.params.group);
      } catch (error) {
        this.error = 'Your video prompt could not be deleted.';
      }
    },

    async updatePrompt() {
      try {
        await this.updateGroupVideoPrompt({
          prompt: this.editablePrompt,
          groupSlug: this.$route.params.group,
          promptId: this.promptInfo.id,
        });
        this.hideEditModal();
        await this.fetchGroupVideoPrompts(this.$route.params.group);
      } catch (error) {
        this.error = 'Your video prompt could not be updated.';
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.action-btn {
  @apply flex items-center justify-center hover:bg-white w-[30px] md:w-[40px] h-[30px] md:h-[40px] p-0.5 md:p-2 rounded-sm;
  &:hover {
    path,
    circle,
    ellipse {
      @apply stroke-gray-800;
    }
  }
}
</style>
