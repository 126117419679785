<template>
  <div class="mx-auto px-4 sm:px-8 max-w-screen-xl flex flex-col-reverse xl:flex-row sm:mt-12">
    <div class="flex flex-col max-w-4xl mx-auto w-full">
      <h3 class="font-bold text-gray-900 font-serif text-2xl mb-8">Privacy Settings</h3>
      <div class="bg-gray-200 flex flex-col rounded p-4 mb-8">
        <h4 class="font-bold text-gray-900 font-serif text-2xl mb-4">Group Visibility</h4>
        <div class="flex flex-col ml-2">
          <RadioButton
            id="groupVisibility1"
            label="Public"
            value="true"
            name="group"
            v-model="isPublicGroup"
            @change="changedIsPublic"
          />
          <RadioButton
            id="groupVisibility2"
            label="Private"
            value="false"
            name="group"
            v-model="isPublicGroup"
            @change="changedIsPublic"
          />
        </div>
        <p class="text-base text-gray-600 m-2">
          <template v-if="isPublicGroup === 'true'">
            <strong class="font-bold text-gray-700">
              Your group's members will be visible to anyone with a link to the group's page.
            </strong>
            Reunacy members who are not members of your group will be able to view your group's
            upcoming events and group details.
          </template>
          <template v-else>
            <strong class="font-bold text-gray-700">
              Your group's members will only be visible to other members of the group.
            </strong>
            The public and Reunacy members who don't belong to your group will
            <strong class="font-bold text-gray-700">
              <em class="italic">not</em>
            </strong>
            be able to see your group's members, upcoming events, or group details.
          </template>
        </p>
      </div>
      <div class="bg-gray-200 flex flex-col rounded p-4 mb-8">
        <h4 class="font-bold text-gray-900 font-serif text-2xl mb-4">Member Visibility</h4>
        <div class="flex flex-col ml-2">
          <RadioButton
            id="memberVisibility1"
            label="All members are visible to one another"
            value="false"
            name="member"
            v-model="hasIsolatedMembers"
            @change="changedIsolateMembers"
          />
          <RadioButton
            id="memberVisibility2"
            label="Only Admins can see members"
            value="true"
            name="member"
            v-model="hasIsolatedMembers"
            @change="changedIsolateMembers"
          />
        </div>
        <p class="text-base text-gray-600 m-2">
          <template v-if="hasIsolatedMembers === 'true'">
            <strong class="font-bold text-gray-700">
              Group members will not be able to see the profiles of other group members.
            </strong>
            Only Admins will be able to see all of the members and profiles in the group.
          </template>
          <template v-else>
            <strong class="font-bold text-gray-700">
              Group members and Admins will be able to see the profiles of all other group members.
            </strong>
          </template>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import RadioButton from '@/components/RadioButton.vue';

export default {
  name: 'Privacy',
  components: {
    RadioButton,
  },
  computed: {
    ...mapGetters(['group']),
  },
  data() {
    return {
      isPublicGroup: 'true',
      hasIsolatedMembers: 'false',
    };
  },
  methods: {
    ...mapActions(['updateGroup']),
    changedIsolateMembers() {
      this.updateGroup({
        groupSlug: this.group.slug,
        group: {
          isolateMembers: this.hasIsolatedMembers === 'true',
        },
      });
    },
    changedIsPublic() {
      this.updateGroup({
        groupSlug: this.group.slug,
        group: {
          isPublic: this.isPublicGroup === 'true',
        },
      });
    },
  },
  mounted() {
    if (this.group) {
      this.isPublicGroup = this.group.isPublic != null && this.group.isPublic.toString();
      this.hasIsolatedMembers =
        this.group.isolateMembers != null && this.group.isolateMembers.toString();
    }
  },
  watch: {
    group(group) {
      this.isPublicGroup = group.isPublic != null && group.isPublic.toString();
      this.hasIsolatedMembers = group.isolateMembers != null && group.isolateMembers.toString();
    },
  },
};
</script>

<style scoped></style>
