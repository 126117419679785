<template>
  <div class="mx-auto px-0 max-w-screen-xl flex sm:mt-8 flex-col">
    <Toast v-if="error">{{ error }}</Toast>

    <div class="w-full">
      <LoadingText v-if="loading" />
      <div v-else>
        <div class="flex flex-col md:flex-row mb-4 md:mb-0 justify-between">
          <div>
            <h3 class="font-serif text-gray-900 text-lg font-normal">Video Story Prompts</h3>

            <p class="text-gray-700 mt-2 text-sm">
              These prompts are available for group members to use to record video stories.
            </p>
            <p class="text-gray-700 mb-6 text-sm">Groups must have 1 - 5 prompts.</p>
          </div>

          <Button
            v-if="!addingNewPrompt"
            :primary-full-size="false"
            type="secondary"
            @onClick="showAddEditPrompt"
            custom-class="h-[40px] w-full sm:max-w-[170px]"
          >
            <template v-slot:icon>
              <IconCircle class="w-[16px]" />
            </template>
            <span class="text-orange-400 text-center text-sm font-semibold leading-[140%]">
              Add Prompt
            </span>
          </Button>
        </div>

        <CustomPromptItem
          v-for="promptInfo in visiblePrompts"
          :key="promptInfo.id"
          :promptInfo="promptInfo"
          :promptCount="visiblePrompts.length"
        />

        <DialogDefault
          v-if="isShowAddEditModal"
          header="Add Prompts"
          @cancel="hideAddEditPrompt"
          applyBtnText="Save"
          is-show-close-btn
          scheme="secondary"
          container-class="sm:w-[800px]"
          key="AddEditModal"
          @apply="addNewPrompt"
        >
          <TextInput
            class="mt-6 mb-10"
            label="Phrase your prompt as an open-ended question."
            placeholder="Type a prompt..."
            v-model="newPrompt"
          />
        </DialogDefault>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingText from '@/components/LoadingText.vue';
import CustomPromptItem from '@/components/CustomPromptItem.vue';
import Toast from '@/components/Toast.vue';
import Button from '@/components/Button.vue';
import { mapActions, mapGetters } from 'vuex';
import IconCircle from '@/assets/icon_plus_circled.svg';
import DialogDefault from '@/components/DialogDefault.vue';
import TextInput from '@/components/TextInput.vue';
import { DEFAULT_PROMPTS } from './constants/video';

export default {
  name: 'CustomVideoPrompts',
  components: {
    TextInput,
    DialogDefault,
    IconCircle,
    CustomPromptItem,
    LoadingText,
    Toast,
    Button,
  },
  data() {
    return {
      isShowAddEditModal: false,
      promptOptions: DEFAULT_PROMPTS,
      loading: true,
      visiblePrompts: null,
      addingNewPrompt: false,
      newPrompt: '',
      showToast: false,
      error: null,
    };
  },
  created() {
    this.fetchGroupVideoPrompts(this.$route.params.group).then(() => {
      this.loading = false;
    });
  },
  computed: {
    ...mapGetters(['prompts']),
  },
  methods: {
    ...mapActions(['fetchGroupVideoPrompts', 'createGroupVideoPrompt']),
    async addNewPrompt() {
      try {
        await this.createGroupVideoPrompt({
          groupSlug: this.$route.params.group,
          prompt: this.newPrompt,
          isVisible: true,
        });
        this.newPrompt = '';
        this.hideAddEditPrompt();
        await this.fetchGroupVideoPrompts(this.$route.params.group);
      } catch (error) {
        this.error = 'Your video prompt could not be saved.';
      }
    },

    showAddEditPrompt() {
      this.isShowAddEditModal = true;
    },
    hideAddEditPrompt() {
      this.isShowAddEditModal = false;
    },
  },
  watch: {
    prompts(prompts) {
      if (prompts) {
        this.visiblePrompts = prompts.filter((prompt) => prompt.isVisible);
      }
    },
  },
};
</script>

<style scoped></style>
