<template>
  <div class="max-w-screen-xl mx-6 sm:mx-auto sm:w-full border-t border-gray-400 sm:border-0 flex
   flex-col-reverse xl:flex-row xl:justify-between xl:mt-14"
       v-if="!notAuthenticatedOrNotMember && groupEvents">
    <EventList class="w-full xl:w-3/4 mb-12 xl:pr-8"
               :showPastEvents="true"
               :isPreview="false"
               :show-controls="false"
    >
    </EventList>

    <div :class="{'empty': !groupEvents.length}" v-if="isGroupAdmin"
         class="new-group-event new-group-container pt-8 sm:pt-12 xl:pt-0">
      <div class="new-group-card" :class="{'stick': isNewGroupCardStick}"
           :style="{'right': isNewGroupCardStick ? stickLeftOffset + 'px' : 'auto'}"
      >
        <div
          class="flex items-center flex-col sm:flex-row lg:flex-col justify-center sm:justify-start lg:justify-center w-full">
          <div
            class="h-90px w-90px min-w-90px hidden justify-center items-center bg-gray-100 rounded-full sm:flex mr-6 lg:mb-6 lg:mx-auto">
            <CalendarIcon class="w-10 h-10"></CalendarIcon>
          </div>

          <div class="header-container">
            <h2 class="font-normal sm:font-medium text-center sm:text-left lg:text-center">
              Create new event
            </h2>
            <p
              class="text-sm lg:text-md text-center sm:text-left lg:text-center font-normal lg:mx-12 mt-4 sm:mt-2 mb-6 sm:mb-4 sm:mb-0 lg:mb-6">
              Create a new event and invite your crew.
            </p>
          </div>
        </div>
        <Button class="sm:w-auto sm:min-w-max lg:w-full sm:ml-3 lg:ml-0 bg-primary" color-custom
                :primary-full-size="false" type="primary" @onClick="handleCreateEvent()">
          Create new event
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import EventList from '@/components/events/EventList.vue';
import CalendarIcon from '@/assets/icon_calendar_large.svg';
import { mapGetters } from 'vuex';
import Button from '@/components/Button.vue';

export default {
  name: 'GroupEventList',
  components: {
    EventList,
    Button,
    CalendarIcon,
  },
  data() {
    return {
      isNewGroupCardStick: false,
      stickLeftOffset: 0,
    };
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  },
  mounted() {
    if (document.querySelector('.new-group-card')) {
      this.stickLeftOffset = document.querySelector('.new-group-card')
        .getBoundingClientRect().x;
    }
  },
  computed: {
    ...mapGetters([
      'isAuthenticated',
      'user',
      'groupEvents',
      'profiles',
    ]),
    isSuperuser() {
      return !!this.user && !!this.user.user && !!this.user.user.isSuperuser;
    },
    memberOfGroup() {
      return this.user &&
        this.user.groups &&
        this.user.groups.some((group) => group.slug === this.$route.params.group);
    },
    notAuthenticatedOrNotMember() {
      if (this.isSuperuser) return false;
      return !this.isAuthenticated || (this.isAuthenticated && !this.memberOfGroup);
    },
    isGroupAdmin() {
      const currentUser = this.profiles
        .slice()
        .filter((member) => member.id === this.user.id)[0];
      return (
        (currentUser && currentUser.isGroupAdmin) ||
        (this.user && this.user.user && this.user.user.isSuperuser)
      );
    },
  },
  methods: {
    handleCreateEvent() {
      this.$router.push({
        name: 'create-event',
      });
    },
    handleScroll() {
      this.isNewGroupCardStick = document.documentElement.scrollTop > 734;
    },
    handleResize() {
      if (document.querySelector('.new-group-card')) {
        this.stickLeftOffset = document.querySelector('.new-group-card')
          .getBoundingClientRect().x;
      }
    },
  },
};
</script>

<style lang="scss">
@screen lg {
  .new-group-event.new-group-container {
    margin-top: 0;
  }
  .new-group-event.new-group-container .new-group-card.stick {
    position: relative;
    right: auto !important;
  }
}

@screen xl {
  .new-group-event.new-group-container:not(.empty) {
    margin-top: 97px;
  }
  .new-group-event.new-group-container .new-group-card.stick {
    position: fixed;
  }
}
</style>
