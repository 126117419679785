<template>
  <div class="relative" :class="[{ 'w-full': !customWidth }]">
    <span class="symbol-prefix absolute text-sm">{{ symbolPrefix }}</span>
    <input
      autocomplete="disabled"
      autocorrect="false"
      spellcheck="false"
      @input="updateValue()"
      @focus="focused = true"
      @blur="focused = false"
      :id="id"
      :name="name"
      :type="type"
      v-on:keyup.enter="handleEnterKey()"
      ref="input"
      class="input bg-white rounded shadow text-sm placeholder-gray-500 border border-gray-300 focus:outline-none transition-colors duration-200 ease py-2 z-50 w-full"
      :class="[
        { 'input--filled': value && value.length > 0 },
        { 'border-red-600 border-2': invalid },
        { disabled: disabled },
        { 'pl-10 pr-2': hasIcon && !hasEndIcon },
        { 'px-4 py-3': !hasIcon && !hasEndIcon },
        { 'pl-7 pr-4': !hasIcon && !hasEndIcon && symbolPrefix },
        { 'pl-10 pr-10': hasIcon && hasEndIcon },
        { 'pl-2 pr-10': !hasIcon && hasEndIcon },
      ]"
      :value="value"
      :placeholder="placeholder || (focused && focusedPlaceholder)"
      :maxlength="maxLength"
      :disabled="disabled"
    />
    <span
      v-if="hasIcon"
      class="icon-wrapper absolute top-0 left-0 h-full flex items-center ml-2 text-gray-400 transition-colors duration-200 ease-out pointer-events-none px-1"
    >
      <slot name="icon"></slot>
    </span>
    <span
      v-if="hasEndIcon"
      class="icon-wrapper absolute top-0 right-0 h-full flex items-center justify-end pr-2 text-gray-400 transition-colors duration-200 ease-out px-1"
    >
      <slot name="endIcon"></slot>
    </span>
  </div>
</template>
<script>
export default {
  name: 'TextInputCompact',
  data() {
    return {
      focused: false,
    };
  },
  props: {
    /**
     * id of the input
     */
    id: {
      type: String,
    },
    /**
     * name of the input
     */
    name: {
      type: String,
    },
    /**
     * Placeholder text inside the input
     */
    placeholder: {
      type: String,
      default: '',
    },
    /**
     * Placeholder text inside the input when input is focused
     */
    focusedPlaceholder: {
      type: String,
      default: '',
    },
    /**
     * Input type. Can be changed to password or email, for instance
     */
    type: {
      type: String,
      default: 'text',
    },
    /**
     * The value for the text input
     */
    value: {
      type: String,
    },
    /**
     * The text that will be displayed for the Label
     */
    label: {
      type: String,
    },
    /**
     * Determines whether a field is invalid and should display differently (e.g. a red border)
     */
    invalid: {
      type: Boolean,
      default: false,
    },
    /**
     * The max number of characters of the input field
     */
    maxLength: {
      type: Number,
      default: 524288, // The HTML spec default value
    },
    /**
     * E.g. currency, math symbol etc.
     */
    symbolPrefix: {
      type: String,
      default: '',
    },
    /**
     * Determines if the field is disabled or not
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Flag specifically for text inputs with icons that should show the label too
     */
    showLabel: {
      type: Boolean,
      default: false,
    },
    /**
     * removes full width styling if true
     */
    customWidth: {
      type: Boolean,
      default: false,
    },
    /**
     * set full or auto width for input
     */
    compact: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasIcon() {
      return this.$slots.icon != null;
    },
    hasEndIcon() {
      return this.$slots.endIcon != null;
    },
  },
  methods: {
    updateValue() {
      if (this.$refs.input) {
        this.$emit('input', this.$refs.input.value);
      }
    },
    handleEnterKey() {
      this.$emit('onEnterBtn');
    },
  },
};
</script>
<style scoped lang="scss">
.input__label {
  transform: translateY(0.85rem);
}

.input:focus + .input__label,
.input--filled + .input__label {
  @apply font-bold;
  transform: translateY(0) scale(0.78);
}

.input:focus {
  @apply border-gray-400;
}

.input:focus + .input__label {
  @apply text-primary;
}

.input:focus + .icon-wrapper {
  @apply text-primary;
}

.disabled {
  @apply bg-gray-200 shadow-none text-gray-700;
}

.symbol-prefix {
  top: 40%;
  transform: translateY(-50%);
  left: 18px;
}
</style>
