<template>
  <span>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Mask group">
        <mask
          id="mask0_4951_1546"
          style="mask-type: luminance"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="16"
          height="16"
        >
          <g id="Group">
            <path id="bg" d="M16 0H0V16H16V0Z" fill="white" />
          </g>
        </mask>
        <g mask="url(#mask0_4951_1546)">
          <g id="Group_2">
            <g id="g675">
              <g id="g677">
                <g id="Clip path group">
                  <mask
                    id="mask1_4951_1546"
                    style="mask-type: luminance"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="16"
                    height="16"
                  >
                    <g id="clipPath683">
                      <path id="path681" d="M0 9.53674e-07H16V16H0V9.53674e-07Z" fill="white" />
                    </g>
                  </mask>
                  <g mask="url(#mask1_4951_1546)">
                    <g id="g679">
                      <g id="g685">
                        <path
                          id="path687"
                          d="M2.40625 7.99609H0.625"
                          stroke="#6B6D70"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <g id="g689">
                        <path
                          id="path691"
                          d="M15.375 7.99609H7.625"
                          stroke="#6B6D70"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <g id="g693">
                        <path
                          id="path695"
                          d="M7.90625 2.6875H0.625"
                          stroke="#6B6D70"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <g id="g697">
                        <path
                          id="path699"
                          d="M15.375 2.6875H12.9062"
                          stroke="#6B6D70"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <g id="g701">
                        <path
                          id="path703"
                          d="M7.90481 13.3438H0.625"
                          stroke="#6B6D70"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <g id="g705">
                        <path
                          id="path707"
                          d="M15.375 13.3438H12.9688"
                          stroke="#6B6D70"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <g id="g709">
                        <path
                          id="path711"
                          d="M6.15625 10.0312C5.46591 10.0312 4.90625 9.47159 4.90625 8.78125V7.15625C4.90625 6.46591 5.46591 5.90625 6.15625 5.90625C6.84659 5.90625 7.40625 6.46591 7.40625 7.15625V8.78125C7.40625 9.47159 6.84659 10.0312 6.15625 10.0312Z"
                          stroke="#6B6D70"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <g id="g713">
                        <path
                          id="path715"
                          d="M11.6548 15.375C10.9644 15.375 10.4048 14.8153 10.4048 14.125V12.5312C10.4048 11.8409 10.9644 11.2812 11.6548 11.2812C12.3451 11.2812 12.9048 11.8409 12.9048 12.5312V14.125C12.9048 14.8153 12.3451 15.375 11.6548 15.375Z"
                          stroke="#6B6D70"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <g id="g717">
                        <path
                          id="path719"
                          d="M11.6562 4.71875C10.9659 4.71875 10.4062 4.15909 10.4062 3.46875V1.875C10.4062 1.18466 10.9659 0.625 11.6562 0.625C12.3466 0.625 12.9062 1.18466 12.9062 1.875V3.46875C12.9062 4.15909 12.3466 4.71875 11.6562 4.71875Z"
                          stroke="#6B6D70"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </span>
</template>
<script>
export default {
  name: 'IconGroupSettings',
};
</script>
