<template>
  <form class="flex flex-col">
    <h2 v-if="title" class="mb-2 md:mb-4 text-base sm:text-xl lg:text-2xl">{{ title }}</h2>
    <slot name="title"></slot>
    <div
      class="flex gap-4 self-stretch"
      :class="{ 'rounded shadow-drop p-2 md:px-4 md:py-5 bg-gray-100': isWrapped }"
    >
      <img
        class="mt-1.5 md:mt-1 block w-[28px] h-[28px] md:w-10 md:h-10 bg-white shadow-md rounded-full"
        :src="user && user.profilePicture"
        :alt="user && user.firstName"
      />
      <div class="flex flex-col gap-4 w-full">
        <div
          class="flex justify-between w-full rounded bg-white border border-gray-300 shadow-drop pb-1 pt-0.5 pl-4 pr-1 md:pl-4 md:pr-2 border-solid"
        >
          <div class="flex w-full items-start justify-between">
            <textarea
              v-model="content"
              @input="adjustTextareaHeight"
              :placeholder="placeholder"
              ref="textarea"
              class="pl-0 pt-0 md:pt-3 pr-2 mr-2 w-4/5 h-[24px] md:h-[36px] shadow-none outline-none resize-none text-sm text-gray-900 overflow-y-hidden"
            />
            <div class="relative flex items-center justify-end gap-1 w-1/5 md:min-w-[115px]">
              <button
                type="button"
                v-tooltip="'Emoji'"
                @click.stop="toggleEmojiMenu"
                class="action-btn"
                :class="{
                  'action-btn--active': this.isShowEmoji,
                }"
              >
                <IconSmile class="btn-icon" />
              </button>
              <button
                v-if="isCanAddImage"
                type="button"
                v-tooltip="'Attach Photo'"
                class="relative cursor-pointer action-btn"
                @click="$refs.fileInput.click()"
              >
                <IconPicture class="btn-icon" />
                <input
                  id="fileInput"
                  ref="fileInput"
                  class="hidden"
                  type="file"
                  accept="image/*"
                  @change="addImage"
                />
              </button>
              <button
                type="button"
                @click.stop="sendMessage"
                class="flex w-[28px] md:w-[40px] h-[24px] md:h-[32px] justify-center items-center p-0.5 md:p-3 rounded-sm"
                :class="{
                  'bg-orange-400 hover:bg-orange-600': content.length,
                }"
                :disabled="!content.length"
              >
                <IconSend class="send-icon" :class="{ active: content.length }" />
              </button>

              <div v-click-outside="closeEmojiMenu">
                <emoji-mart-picker
                  :showPreview="false"
                  :showSkinTones="false"
                  @select="handleEmojiSelect"
                  v-if="isShowEmoji"
                  class="absolute top-100 right-0 z-10"
                  :data="emojiIndex"
                  set="apple"
                  title="Pick your emoji..."
                  emoji="point_up"
                  :native="true"
                />
              </div>
            </div>
          </div>
        </div>

        <ImageItem v-if="image" :file="image" @delete="deleteImage" />
      </div>
    </div>
  </form>
</template>
<script>
import { mapGetters } from 'vuex';
import { FileUtil } from '@/utils/file.util';
import IconSend from '@/assets/icon_send.svg';
import IconSmile from '@/assets/icon_smile.svg';
import IconPicture from '@/assets/icon_picture.svg';
import data from 'emoji-mart-vue-fast/data/apple.json';
import { EmojiIndex, Picker as EmojiMartPicker } from 'emoji-mart-vue-fast';

import 'emoji-mart-vue-fast/css/emoji-mart.css';
import ImageItem from '@/components/ui/ImageItem.vue';

const emojiIndex = new EmojiIndex(data);
const MIN_SCROLL_HEIGHT = 47;
const MAX_SCROLL_HEIGHT = 108;

export default {
  name: 'MessageForm',

  components: { ImageItem, EmojiMartPicker, IconSmile, IconPicture, IconSend },

  props: {
    title: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Start typing a new message',
    },
    isWrapped: {
      type: Boolean,
      default: false,
    },
    isCanAddImage: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      emojiIndex,
      content: '',
      image: null,
      isShowEmoji: false,
    };
  },

  computed: {
    ...mapGetters(['group', 'user']),
  },

  mounted() {
    this.adjustTextareaHeight();
  },

  methods: {
    adjustTextareaHeight() {
      const { textarea } = this.$refs;
      if (textarea.scrollHeight > MIN_SCROLL_HEIGHT && textarea.scrollHeight < MAX_SCROLL_HEIGHT) {
        textarea.style.height = `${textarea.scrollHeight}px`;
      } else {
        textarea.style.overflowY = 'auto';
      }
    },

    handleEmojiSelect(emoji) {
      this.content += this.convertUnifiedToChar(emoji.unified);
      this.adjustTextareaHeight();
      this.isShowEmoji = false;
      this.$refs.textarea.focus();
    },

    convertUnifiedToChar(unified) {
      return String.fromCodePoint(...unified.split('-').map((hex) => parseInt(hex, 16)));
    },

    toggleEmojiMenu() {
      this.isShowEmoji = !this.isShowEmoji;
    },

    closeEmojiMenu() {
      if (this.isShowEmoji) {
        this.isShowEmoji = false;
      }
    },

    async sendMessage() {
      let blob = null;
      if (this.image) {
        blob = await FileUtil.getBlob(this.image);
      }
      this.$emit('input', this.content, blob);
      this.content = '';
      this.image = null;
    },

    async addImage(event) {
      try {
        this.image = FileUtil.getFileFromEvent(event);

        event.target.value = '';
      } catch (e) {
        console.error(e);
      }
    },

    deleteImage() {
      this.image = null;
    },
  },
};
</script>
<style lang="scss" scoped>
.send-icon {
  path {
    stroke: #c8cacd;
  }

  &.active path {
    stroke: #fff;
  }
}

.btn-icon {
  path,
  circle,
  ellipse {
    stroke: #808285;
  }
}

.action-btn {
  @apply flex items-center justify-center hover:bg-gray-200 w-[24px] md:w-[40px] h-[24px] md:h-[40px] p-0.5 md:p-2 rounded-sm;
  &:hover {
    path,
    circle,
    ellipse {
      @apply stroke-gray-800;
    }
  }
}

.action-btn--active {
  @apply bg-gray-900;
  path,
  circle,
  ellipse {
    @apply stroke-white;
  }
}
</style>
