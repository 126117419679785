export class MemberRecord {
  constructor(record = {}) {
    // eslint-disable-next-line no-underscore-dangle
    this._record = record;
    this.id = record.id || '';
    this.name = record.name || '';
    this.profilePicture = record.profilePicture || '';
    this.slug = record.slug || '';
  }
}
