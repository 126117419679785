<template>
  <div>
    <p class="flex justify-between items-center bg-gray-100 rounded w-full my-2 pl-4 pr-6 py-3">
      <span class="min-w-[70%] sm:min-w-[80%] text-gray-900 text-sm font-semibold">
        Which school(s) did / do you attend?
      </span>
      <Checkbox
        class="w-[30%] sm:w-[20%] min-w-[70px] justify-end"
        id="school_display_members"
        label="Visible"
        reverse-label
        scheme="blue"
        v-model="currentGroup.schoolShowYearbook"
        :disabled="isLoading"
      />
    </p>

    <p class="flex justify-between bg-gray-100 rounded w-full my-2 pl-4 pr-6 py-3">
      <span class="min-w-[70%] sm:min-w-[80%] text-gray-900 text-sm font-semibold">
        What did / do you study?
      </span>
      <Checkbox
        class="w-[30%] sm:w-[20%] min-w-[70px] justify-end"
        id="major_display_members"
        label="Visible"
        reverse-label
        scheme="blue"
        v-model="currentGroup.majorShowYearbook"
        :disabled="isLoading"
      />
    </p>

    <p class="flex justify-between bg-gray-100 rounded w-full my-2 pl-4 pr-6 py-3">
      <span class="min-w-[70%] sm:min-w-[80%] text-gray-900 text-sm font-semibold">
        Where do you work?
      </span>
      <Checkbox
        class="w-[30%] sm:w-[20%] min-w-[70px] justify-end"
        id="work_display_members"
        label="Visible"
        reverse-label
        scheme="blue"
        v-model="currentGroup.employerShowYearbook"
        :disabled="isLoading"
      />
    </p>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Checkbox from '@/components/Checkbox.vue';

export default {
  name: 'StandardProfileFields',
  components: {
    // Toast,
    Checkbox,
  },
  data() {
    return {
      loading: true,
      currentGroup: {},
    };
  },
  created() {
    if (this.$route.params.group) {
      this.fetchGroup({ groupSlug: this.$route.params.group }).then((data) => {
        if (data && !data.success) {
          this.$router.push({ name: 'not-found' });
        }
        this.loading = false;
        this.currentGroup = this.group;
        this.$watch(
          'currentGroup', // what you want to watch
          () => {
            // this causes issues
            const updateGroup = {
              ...this.currentGroup,
            };
            delete updateGroup.groupClassification;
            this.updateGroup({
              groupSlug: this.group.slug,
              group: updateGroup,
            });
          },
          { deep: true },
        );
      });
    }
  },
  computed: {
    ...mapGetters(['group', 'isLoading']),
  },
  methods: {
    ...mapActions(['fetchGroup', 'updateGroup']),
  },
};
</script>
