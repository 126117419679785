<template>
  <div class="relative" :class="{ 'hide-background': isHidden }">
    <div class="rounded bg-white shadow-drop px-3 py-4 md:px-8 md:py-6">
      <MessageForm
        placeholder="Something about the event..."
        is-can-add-image
        @input="addDiscussion"
      >
        <template v-slot:title>
          <div class="flex justify-between w-full mb-6">
            <div class="flex items-center">
              <h3>Discussion</h3>
              <span
                v-if="eventDiscussions.length"
                class="h-max ml-3 text-small px-3 py-1 rounded-2xl bg-gray-200"
              >
                {{ eventDiscussions.length }}
              </span>
            </div>

            <div v-if="isGroupAdmin && isEditMode" class="flex justify-end">
              <ToggleButton
                @input="$emit('toggleDiscussion')"
                v-model="discussionEnable"
                :options="{ isActive: discussionEnable }"
              />
            </div>
          </div>
        </template>
      </MessageForm>
    </div>

    <div
      v-for="post in eventDiscussions"
      :key="post.id"
      :id="'notification-' + post.id"
      class="mt-3 relative"
    >
      <div
        class="flex flex-col w-full rounded shadow-drop overflow-hidden gap-3 pt-4 pb-5 px-4 bg-white"
      >
        <div class="flex justify-between">
          <div class="flex items-center gap-2">
            <ProfilePhoto
              class="block w-[28px] md:w-[43px] h-[28px] md:h-[43px] shadow-md rounded-full"
              :url="post.profile.profilePicture"
            />
            <p class="flex items-center gap-2 text-gray-900 font-semibold">
              {{ `${post.profile.firstName} ${post.profile.lastName}` }}
              <span class="w-[4px] h-[4px] block rounded-full bg-gray-400 relative font-normal" />
              <span class="text-gray-700 text-xs opacity-80 font-normal">
                {{ getTimeAgo(post.postedDatetime) }}
              </span>
            </p>
          </div>
          <button
            type="button"
            class="flex items-center justify-center hover:bg-gray-200 w-[30px] h-[30px] p-1.5 rounded-sm"
            v-if="isGroupAdmin || post.profile.id === user.id"
            @click="deleteDiscussionDialog(post.id, user)"
          >
            <Delete />
          </button>
        </div>
        <p class="py-2 text-gray-900 text-sm">
          {{ post.content }}
        </p>
        <img
          v-if="post.photo"
          alt="discussion-image"
          class="max-w-full h-auto max-h-[400px] rounded object-contain"
          :src="post.photo"
        />

        <div class="flex mb-1.5">
          <div class="flex items-center gap-4">
            <div class="flex items-center">
              <button type="button" class="action-btn" @click="toggleLikePost(post)">
                <HeartEmptyIcon
                  class="post_icon w-5 h-5 opacity-80"
                  :class="{
                    liked: post.likes.filter((thing) => thing.profile.id === user.id).length !== 0,
                  }"
                />
              </button>
              <span v-if="post.likes.length" class="text-gray-700 text-sm opacity-80 pl-1">
                {{ post.likes.length }}
              </span>
            </div>
            <div class="flex items-center">
              <button type="button" class="action-btn" @click="setReplyToPost(post.id)">
                <CommentIcon class="post_icon w-5 h-5 opacity-80" />
              </button>
              <span v-if="post.subDiscussions.length" class="text-gray-700 text-sm opacity-80 pl-1">
                {{ post.subDiscussions.length }}
              </span>
            </div>
          </div>
        </div>

        <MessageForm
          v-if="replyingToPostId === post.id"
          :placeholder="replyPlaceholderText"
          @input="(content, picture) => addDiscussion(content, picture, replyingToPostId)"
        />

        <!--                 -->
        <!-- NESTED COMMENTS -->
        <!--                 -->

        <div
          v-for="reply in post.subDiscussions"
          :key="reply.id"
          :id="'reply-' + reply.id"
          class="relative flex w-full"
        >
          <ProfilePhoto
            class="block w-[28px] md:w-11 h-[28px] md:h-11 bg-white shadow-md rounded-full"
            :url="reply.profile.profilePicture"
          />
          <div class="w-full ml-4">
            <div class="flex flex-col mb-2 bg-gray-100 p-3">
              <p
                class="flex items-center gap-2 text-gray-900 mb-0.5 md:mb-2 font-semibold text-sm md:text-base"
              >
                {{ `${reply.profile.firstName} ${reply.profile.lastName}` }}
                <span class="w-[4px] h-[4px] block rounded-full bg-gray-400 relative font-normal" />
                <span class="text-gray-700 text-xs opacity-80 font-normal">
                  {{ getTimeAgo(reply.postedDatetime) }}
                </span>
              </p>
              <p class="self-stretch text-gray-900 text-sm">
                {{ reply.content }}
              </p>
            </div>

            <div class="flex">
              <div class="flex items-center mr-1">
                <button
                  type="button"
                  @click="toggleLikePost(reply)"
                  class="hover:bg-gray-200 px-2 py-1.5 text-center text-xs rounded-sm"
                  :class="{
                    'hover:text-orange-600 text-orange-400':
                      reply.likes.filter((thing) => thing.profile.id === user.id).length !== 0,
                    'hover:text-gray-900 text-gray-600': !reply.likes.filter(
                      (thing) => thing.profile.id === user.id
                    ).length,
                  }"
                >
                  {{
                    reply.likes.filter((thing) => thing.profile.id === user.id).length !== 0
                      ? 'Liked'
                      : 'Like'
                  }}
                </button>
                <span
                  v-if="reply.likes.length"
                  class="flex items-center gap-1 text-gray-700 text-xs opacity-80 pl-0.5"
                >
                  <HeartEmptyIcon class="liked_icon h-5 opacity-80 w-[12px]" />
                  {{ reply.likes.length }}
                </span>
              </div>
            </div>
          </div>

          <div
            v-if="isGroupAdmin || reply.profile.id === user.id"
            class="absolute right-0 top-0 self-end mb-auto"
          >
            <CloseIcon
              @click="deleteDiscussionDialog(post.id, user)"
              class="close-dialog-icon cursor-pointer animation-focus-default w-4 h-4 absolute top-3 right-3"
            />
          </div>
        </div>
      </div>
    </div>

    <!--                          -->
    <!-- DIALOG DELETE DISCUSSION -->
    <!--                          -->

    <transition name="component-fade" mode="out-in">
      <DialogDefault
        v-if="showRemoveDiscussionDialog"
        :icon="'delete'"
        :header="getDiscussionDialogName()"
        :text="getDiscussionDialogText()"
        :applyBtnText="'Delete'"
        @cancel="cancelRemovingDiscussion()"
        @apply="removeDiscussion()"
      />
    </transition>
  </div>
</template>

<script>
import ProfilePhoto from '@/components/ProfilePhoto.vue';
import CloseIcon from '@/assets/icon_close.svg';
import HeartEmptyIcon from '@/assets/icon_heart.svg';
import DialogDefault from '@/components/DialogDefault.vue';
import { DateTime } from 'luxon';
import { mapGetters, mapActions } from 'vuex';
import MessageForm from '@/components/forms/MessageForm.vue';
import ToggleButton from '@/components/baseComponents/ToggleButton.vue';
import CommentIcon from '@/assets/icon_comment.svg';
import Delete from '@/assets/delete.svg';

export default {
  name: 'EventDiscussion',
  components: {
    Delete,
    CommentIcon,
    ToggleButton,
    MessageForm,
    ProfilePhoto,
    CloseIcon,
    HeartEmptyIcon,
    DialogDefault,
  },
  props: {
    isHidden: {
      type: Boolean,
      default: false,
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    discussionEnable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      replyingToPostId: null,
      replyPlaceholderText: '',
      showRemoveDiscussionDialog: false,
      currentDiscussionData: {},
    };
  },
  created() {
    this.replyPlaceholderText =
      window.screen.width > 576 ? 'Say something about the event...' : 'Reply';
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    ...mapActions([
      'addEventDiscussion',
      'deleteEventDiscussion',
      'likePost',
      'unlikePost',
    ]),
    setReplyToPost(postId) {
      this.replyingToPostId = postId;
    },
    getTimeAgo(dateUTC) {
      if (dateUTC) {
        return DateTime.fromISO(dateUTC, { zone: 'utc' }).toRelative();
      }

      return '';
    },
    addDiscussion(content, photoBlob, parentPostId) {
      const postFormatted = {
        content,
      };
      if (photoBlob) {
        postFormatted.photo = photoBlob;
      }
      if (parentPostId) {
        postFormatted.parentPost = parentPostId;
      }

      this.addEventDiscussion({
        groupSlug: this.$route.params.group,
        eventId: this.$route.params.eventId,
        post: postFormatted,
      }).then(() => {
        this.replyingToPostId = null;
      });
    },
    removeDiscussion() {
      this.deleteEventDiscussion({
        groupSlug: this.$route.params.group,
        eventId: this.$route.params.eventId,
        postId: this.currentDiscussionData.postId,
      }).then(() => {
        this.replyingToPostId = null;
        this.closeRemovingDiscussionDialog();
      });
    },
    toggleLikePost(post) {
      if (post.profile.id !== this.user.id) {
        if (post.likes.filter((thing) => thing.profile.id === this.user.id).length !== 0) {
          this.unlikePost({
            groupSlug: this.$route.params.group,
            eventId: this.$route.params.eventId,
            postId: post.id,
          });
        } else {
          this.likePost({
            groupSlug: this.$route.params.group,
            eventId: this.$route.params.eventId,
            postId: post.id,
          });
        }
      }
    },
    handleResize() {
      this.replyPlaceholderText =
        window.screen.width > 576 ? 'Say something about the event...' : 'Reply';
    },
    deleteDiscussionDialog(postId, user) {
      this.currentDiscussionData = {
        postId,
        user,
      };
      this.showRemoveDiscussionDialog = true;
    },
    cancelRemovingDiscussion() {
      this.currentDiscussionData = {};
      this.closeRemovingDiscussionDialog();
    },
    closeRemovingDiscussionDialog() {
      this.showRemoveDiscussionDialog = false;
    },
    getDiscussionDialogName() {
      return `Delete ${this.currentDiscussionData.user.firstName} ${this.currentDiscussionData.user.lastName} comment`;
    },
    getDiscussionDialogText() {
      return `Are you sure you want to delete ${this.currentDiscussionData.user.firstName} ${this.currentDiscussionData.user.lastName}'s comment?`;
    },
  },
  computed: {
    ...mapGetters(['eventDiscussions', 'profiles', 'user', 'notificationId']),
    isGroupAdmin() {
      const currentUser = this.profiles.slice().filter((member) => member.id === this.user.id)[0];
      return (
        (currentUser && currentUser.isGroupAdmin) ||
        (this.user && this.user.user && this.user.user.isSuperuser)
      );
    },
  },
  watch: {
    notificationId() {
      const notificationSelector = document.getElementById(`notification-${this.notificationId}`);
      const replySelector = document.getElementById(`reply-${this.notificationId}`);

      // TODO: scroll to expected discussion post
      if (this.notificationId) {
        setTimeout(() => {
          if (notificationSelector) {
            notificationSelector.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
            });
          } else if (replySelector) {
            replySelector.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
            });
          }
        }, 200);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.action-btn {
  @apply flex items-center justify-center hover:bg-gray-200 w-[30px] h-[30px] p-1.5 rounded-sm;

  path {
    @apply stroke-gray-600;
  }
  &:hover {
    path {
      @apply stroke-gray-900;
    }
  }
}
.post_icon {
  path {
    @apply stroke-gray-600;
  }
  &.liked path {
    @apply stroke-orange fill-orange;
  }
}

.hide-background:after {
  content: '';
  position: absolute;
  inset: 0;
  background: #ffffff;
  opacity: 0.5;
}

.close-icon:hover path {
  @apply stroke-primary;
}
</style>
