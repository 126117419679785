<template>
  <div class="mt-8 mx-6 sm:mx-auto">
    <template v-if="groupFeatureFlags && groupFeatureFlags.groupPhotosEnabled">
      <div class="pt-1">
        <Breadcrumbs
          class="pt-4 sm:pt-0 mb-1 border-t border-gray-400 sm:border-0"
          scheme="secondary"
          :crumbs="breadcrumbs"
          @action="goToAllFiles"
        />

        <div
          class="py-1 flex flex-wrap sm:flex-nowrap justify-between items-center mx-6 sm:mx-auto"
        >
          <Checkbox
            :label="isSelectAll ? 'Reset All' : 'Select all'"
            v-model="isSelectAll"
            large-only
          />
          <template v-if="selectedMedia.length">
            <div class="flex gap-0.5 w-full mt-6 sm:mt-0 justify-center sm:justify-end">
              <button
                @click="downloadSelectedItem"
                type="button"
                class="flex items-center p-2 sm:pr-4 text-sm sm:text-base rounded transition hover:bg-white"
              >
                <span class="p-2 rounded-[40px]">
                  <Download />
                </span>
                Download
              </button>

              <button
                v-if="!isSelectedMediaIncludeFolder"
                @click="showMoveMediaModal"
                type="button"
                class="flex items-center p-2 sm:pr-4 text-sm sm:text-base rounded transition hover:bg-white"
              >
                <span class="p-2 rounded-[40px]">
                  <MoveTo />
                </span>
                Move&nbsp;to
              </button>

              <button
                @click="showDeleteModal"
                type="button"
                class="flex items-center p-2 sm:pr-4 text-sm sm:text-base rounded transition hover:bg-white"
              >
                <span class="p-2 rounded-[40px]">
                  <Delete />
                </span>
                Delete
              </button>
            </div>
          </template>
          <template v-else>
            <div class="flex gap-1 items-center">
              <Dropdown
                class="w-[150px]"
                :options="orderOptions"
                :selectedOption="selectedOrderOption"
                @selection="changeOrderOption"
                scheme="transparent"
              />
              <button
                type="button"
                class="sort-btn rounded-sm w-[30px] h-[30px]"
                @click="changeSortingDirection"
              >
                <ArrowSortTop class="sort-icon" :class="{ 'rotate-180': isDESCSorting }" />
              </button>
              <Dropdown
                class="hidden sm:block w-[130px]"
                :options="typeOptions"
                :selectedOption="selectedTypeOption"
                @selection="changeType"
              />
            </div>
            <Dropdown
              class="block sm:hidden w-full"
              :options="typeOptions"
              :selectedOption="selectedTypeOption"
              @selection="changeType"
            />
          </template>
        </div>

        <div
          class="border-t border-gray-400 mt-4 py-4 mx-6 sm:mx-auto flex flex-col-reverse sm:flex-row flex-wrap sm:flex-nowrap justify-between"
        >
          <div class="w-full md:w-[60%] lg:w-[70%]">
            <template v-if="mediaList.length">
              <div class="flex flex-wrap gap-3">
                <MediaItem
                  v-for="item in mediaList"
                  :key="item.id"
                  :media="item"
                  :folderName="folder ? folder.name : 'Photos'"
                  @action="showPreviewModal"
                  @edit="changeMedia"
                  @select="handleSelectMedia"
                  @refetch="getMedia"
                  @move="moveSelectedItem"
                />
              </div>
              <Pagination
                :count="totalItems"
                :entries="numberItemsToDisplay"
                @changeEntries="changeNumberToDisplay"
                @changePage="changeCurrentPage"
              />
            </template>
            <template v-else> <NoData /> </template>
          </div>
          <div
            class="flex w-full md:w-[40%] lg:w-[30%] mb-4 max-w-full sm:max-w-[320px] sm:min-w-[280px]"
          >
            <div
              class="bg-white w-full max-h-[400px] flex flex-col items-start gap-2.5 rounded border border-color-gray-300 shadow-[0px_2px_4px_0px_rgba(17,17,17,0.08)] px-4 sm:px-6 py-4 sm:py-8 border-solid"
            >
              <div class="flex items-center flex-col justify-center w-full">
                <div
                  class="hidden sm:flex mb-6 justify-center items-center w-[90px] h-[90px] shrink-0 rounded-full bg-gray-100"
                >
                  <IconCameraLarge />
                </div>
                <div class="header-container">
                  <h2 class="font-normal sm:font-medium text-center">Add new Media</h2>
                  <p class="text-sm lg:text-md text-center font-normal mt-4 sm:mt-2 mb-4 lg:mb-6">
                    Add new photos and share them with group members
                  </p>
                </div>
              </div>
              <Button
                class="w-full h-[48px] mb-2"
                :compact="true"
                :primary-full-size="false"
                type="primary"
                @onClick="showUploadFormModal"
              >
                Upload Media
              </Button>
              <Button
                v-if="isGroupAdmin && !folder"
                class="w-full h-10 flex"
                border-none
                :compact="true"
                :primary-full-size="false"
                type="secondary"
                @onClick="showCreateFolderModal"
              >
                <template v-slot:icon>
                  <IconPlus class="w-[16px]" />
                </template>
                <span class="text-orange-400">Create a Folder</span>
              </Button>
            </div>
          </div>
        </div>

        <transition-group name="component-fade" mode="out-in">
          <FloatingButton
            key="FloatingButton"
            @upload="showUploadFormModal"
            @create="showCreateFolderModal"
            :is-can-create-folder="isGroupAdmin && !folder"
          />

          <DialogDefault
            v-if="isShowDeleteModal"
            header="Delete file(s)?"
            :text="textForDeletingMedia"
            @cancel="hideDeleteModal"
            @apply="deleteSelectedItems"
            is-show-close-btn
            scheme="secondary"
            apply-btn-text="Delete"
            container-class="sm:w-[512px]"
            key="DeleteModal"
          />

          <DialogDefault
            v-if="isShowPreviewModal"
            :header="previewMedia.name"
            @cancel="hidePreviewModal"
            is-show-close-btn
            is-dark-overlay
            :is-show-control-buttons="false"
            scheme="secondary"
            container-class="max-w-[900px] w-[90vw] md:w-[80vw]"
            is-truncate
            key="PreviewModal"
          >
            <PreviewMedia
              class="pt-6"
              :media="previewMedia"
              @edit="changeMedia"
              @move="moveSelectedItem"
              @delete="deleteSelectedItem"
              @next="nextMedia"
              @previous="previousMedia"
            />
          </DialogDefault>

          <DialogDefault
            v-if="isShowMoveMediaForm"
            header="Move Media"
            @cancel="hideMoveMediaModal"
            :is-show-control-buttons="false"
            is-show-close-btn
            scheme="secondary"
            apply-btn-text="Move"
            key="MoveMediaForm"
          >
            <MoveMediaForm
              class="pt-6"
              :currentFolder="folder"
              :allFolders="allFolders"
              @cancel="hideMoveMediaModal"
              @submit="moveMedia"
            />
          </DialogDefault>

          <DialogDefault
            v-if="isShowCreateFolderModal"
            header="Create Folder"
            @cancel="hideCreateFolderModal"
            is-show-close-btn
            :is-show-control-buttons="false"
            scheme="secondary"
            key="CreateFolderModal"
          >
            <CreateFolderForm class="pt-6" @submit="createFolder" @cancel="hideCreateFolderModal" />
          </DialogDefault>

          <DialogDefault
            v-if="isShowUploadModal"
            header="Upload Media"
            @cancel="hideUploadFormModal"
            is-show-close-btn
            :is-show-control-buttons="false"
            scheme="secondary"
            container-class="max-w-[900px]"
            key="UploadModal"
          >
            <UploadMediaForm
              class="pt-6"
              @upload="uploadMedia"
              :parent-id="folder && folder.id"
              :group-id="group.id"
              @cancel="hideUploadFormModal"
            />
          </DialogDefault>

          <DialogDefault
            v-if="isShowEditModal"
            :header="isFirstUpload ? 'Add Information to Media' : 'Edit Media'"
            @cancel="hideEditModal"
            is-show-close-btn
            :is-show-control-buttons="false"
            scheme="secondary"
            container-class="max-w-[900px] w-[90vw] md:w-[80vw]"
            key="EditModal"
          >
            <EditMediaForm
              class="pt-6"
              :media="editMedia"
              @submit="updateMedia"
              @cancel="hideEditModal"
            />
          </DialogDefault>
        </transition-group>
      </div>
    </template>
    <template v-else>
      <div class="p-8 flex w-full flex-col rounded bg-white">
        <div class="flex gap-2 border-b border-gray-400 pb-2 mb-8">
          <div class="flex flex-col w-full md:w-[60%]">
            <IconExclusive class="mb-2" />
            <h2 class="self-stretch text-gray-900 text-2xl mb-2">
              Upload and share photos with our Exclusive Plan
            </h2>
            <p class="self-stretch text-gray-900 text-base mb-4">
              Your group is currently on the Free Plan. Upgrade to an Exclusive Plan subscription to
              give everyone in the group access to the photos feature!
            </p>
            <img
              alt="illustration"
              src="@/assets/images/photos_illustration.png"
              class="mb-4 max-w-full h-auto block md:hidden"
            />
            <h3 class="text-gray-900 text-base font-bold uppercase mb-3">
              What do you get with the photos feature?
            </h3>
            <ul>
              <li class="flex items-center mb-3">
                <span class="block w-2 h-2 bg-orange-500 rounded-full mr-2" /> A home for all of
                your group’s photos
              </li>
              <li class="flex items-center mb-3">
                <span class="block w-2 h-2 bg-orange-500 rounded-full mr-2" /> Upload photos before,
                during, and after the event
              </li>
              <li class="flex items-center mb-3">
                <span class="block w-2 h-2 bg-orange-500 rounded-full mr-2" /> Organize photos with
                albums
              </li>
              <li class="flex items-center mb-3">
                <span class="block w-2 h-2 bg-orange-500 rounded-full mr-2" /> Let everyone share
                and download their favorites
              </li>
            </ul>
          </div>

          <div class="w-[40%] pt-3 hidden md:block">
            <img
              alt="illustration"
              src="@/assets/images/photos_illustration.png"
              class="max-w-full h-auto"
            />
          </div>
        </div>

        <Button
          class="h-[48px] max-w-full md:max-w-[200px]"
          compact
          :primary-full-size="false"
          type="primary"
          @onClick="isGroupAdmin ? subscribe() : showAdminUpgradeModal()"
        >
          {{ isGroupAdmin ? 'Subscribe Now' : 'Ask Your Admin To Upgrade' }}
        </Button>

        <transition name="component-fade" mode="out-in">
          <DialogDefault
            v-if="isShowAdminUpgradeModal"
            header="Ask Admin to Upgrade"
            text="The email will be sent to group admins who have agreed to receive messages."
            @cancel="hideAdminUpgradeModal"
            @apply="requestToAdmin"
            is-show-close-btn
            scheme="secondary"
            apply-btn-text="Send Message"
            container-class="sm:w-[800px]"
            action-btn-class="w-full md:w-[220px] text-sm"
            action-wrapper-class="justify-between"
          >
            <form class="pb-12">
              <input
                type="text"
                v-model="adminMessage"
                placeholder="Enter message"
                class="input h-[56px] py-4 px-5 w-full text-gray-900 bg-white rounded shadow border border-gray-300 text-base placeholder-gray-500 focus:outline-none transition-colors duration-200 ease"
              />
            </form>
          </DialogDefault>
        </transition>
      </div>
    </template>
  </div>
</template>
<script>
import Button from '@/components/Button.vue';
import Checkbox from '@/components/Checkbox.vue';
import Dropdown from '@/components/Dropdown.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import MediaItem from '@/views/Group/components/media/MediaItem.vue';

import Delete from '@/assets/delete.svg';
import MoveTo from '@/assets/move-to.svg';
import Download from '@/assets/download.svg';
import IconPlus from '@/assets/icon_plus.svg';
import ArrowSortTop from '@/assets/arrow_sort_top.svg';
import DialogDefault from '@/components/DialogDefault.vue';
import IconCameraLarge from '@/assets/icon_camera_large.svg';
import Pagination from '@/components/ui/Pagination.vue';
import CreateFolderForm from '@/components/forms/CreateFolderForm.vue';
import UploadMediaForm from '@/components/forms/UploadMediaForm.vue';
import EditMediaForm from '@/components/forms/EditMediaForm.vue';
import NoData from '@/views/Group/components/media/NoData.vue';
import PreviewMedia from '@/views/Group/components/media/PreviewMedia.vue';
import MoveMediaForm from '@/components/forms/MoveMediaForm.vue';
import { mapGetters } from 'vuex';
// eslint-disable-next-line import/named
import {
  createFolderService,
  getMediaService,
  deleteMediaService,
  moveMediaService,
  downloadMediaService,
} from '@/services/media.service';
import { FileUtil } from '@/utils/file.util';
import { FILE_TYPE } from '@/symbols/file-type';
import FloatingButton from '@/components/ui/FloatingButton.vue';
import IconExclusive from '@/components/svgComponents/IconExclusive.vue';
import { requestSubscriptionService } from '@/services/global.service';

const TYPE_OPTIONS = [
  {
    label: 'All items',
    value: FILE_TYPE.ALL,
  },
  {
    label: 'Folders',
    value: FILE_TYPE.FOLDER,
  },
  {
    label: 'Photos',
    value: FILE_TYPE.PHOTO,
  },
];
const ORDER_OPTIONS = [
  {
    label: 'Date Created',
    value: 'created_at',
  },
  { label: 'Name (A-Z)', value: 'name' },
];

export default {
  name: 'photos',
  components: {
    IconExclusive,
    FloatingButton,
    MoveMediaForm,
    PreviewMedia,
    NoData,
    EditMediaForm,
    UploadMediaForm,
    CreateFolderForm,
    DialogDefault,
    Pagination,
    Button,
    MediaItem,
    ArrowSortTop,
    Download,
    IconCameraLarge,
    MoveTo,
    IconPlus,
    Delete,
    Dropdown,
    Checkbox,
    Breadcrumbs,
  },
  data() {
    return {
      typeOptions: TYPE_OPTIONS,
      selectedTypeOption: TYPE_OPTIONS[0],
      orderOptions: ORDER_OPTIONS,
      selectedOrderOption: ORDER_OPTIONS[0],
      isSelectAll: false,
      isShowEditModal: false,
      isShowPreviewModal: false,
      isShowUploadModal: false,
      isShowCreateFolderModal: false,
      isShowAdminUpgradeModal: false,
      isShowMoveMediaForm: false,
      isFirstUpload: false,
      isDESCSorting: false,
      isShowDeleteModal: false,
      editMedia: null,
      previewMedia: null,
      folder: null,
      adminMessage: 'Please consider upgrading our group account to access Photos.',
      mediaList: [],
      selectedMedia: [],
      totalItems: 0,
      numberItemsToDisplay: '10',
      currentPage: 1,
    };
  },
  computed: {
    ...mapGetters(['profiles', 'user', 'group', 'groupFeatureFlags']),

    breadcrumbs() {
      const list = [
        {
          label: 'Photos',
          link: 'photos',
        },
      ];

      if (this.folder) {
        list.push({
          label: this.folder.name,
          link: this.folder.name,
        });
      }
      return list;
    },

    isGroupAdmin() {
      const currentProfile = this.profiles.find((profile) => profile.id === this.user.id);
      return currentProfile && currentProfile.isGroupAdmin;
    },

    isSelectedMediaIncludeFolder() {
      return !!this.selectedMedia.filter((m) => m.fileType === FILE_TYPE.FOLDER).length;
    },

    textForDeletingMedia() {
      const folderName = this.folder ? this.folder.name : 'Photos';
      const fileTitle =
        this.selectedMedia.length > 1 || this.isSelectedMediaIncludeFolder
          ? 'files'
          : this.selectedMedia[0].name;
      return `Are you sure to delete ${fileTitle} from ${folderName}?`;
    },

    allFolders() {
      return this.mediaList.filter((m) => m.fileType === FILE_TYPE.FOLDER);
    },
  },

  watch: {
    isSelectAll(newValue) {
      this.handleSelectAllPhoto(newValue);
    },
    async numberItemsToDisplay(newValue, oldValue) {
      if (newValue !== oldValue) {
        await this.getMedia();
      }
    },
    async currentPage(newValue, oldValue) {
      if (newValue !== oldValue) {
        await this.getMedia();
      }
    },
    async selectedTypeOption(newValue, oldValue) {
      if (newValue !== oldValue) {
        await this.getMedia();
      }
    },
    async selectedOrderOption(newValue, oldValue) {
      if (newValue !== oldValue) {
        await this.getMedia();
      }
    },
    async isDESCSorting(newValue, oldValue) {
      if (newValue !== oldValue) {
        await this.getMedia();
      }
    },
    folder: {
      async handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.currentPage = 1;
          await this.getMedia();
        }
      },
      deep: true,
    },
  },

  async created() {
    await this.getMedia();
  },

  methods: {
    reset() {
      this.isSelectAll = false;
      this.editMedia = null;
      this.previewMedia = null;
      this.selectedMedia = [];
    },

    async getMedia() {
      if (this.groupFeatureFlags && !this.groupFeatureFlags.groupPhotosEnabled) return;

      this.reset();
      const {
        group,
        numberItemsToDisplay,
        currentPage,
        folder,
        selectedTypeOption,
        selectedOrderOption,
        isDESCSorting,
      } = this;
      if (this.group) {
        let response = null;
        const type = selectedTypeOption.value;
        const order = `${isDESCSorting ? '-' : ''}${selectedOrderOption.value}`;

        response = await getMediaService(
          group.id,
          numberItemsToDisplay,
          currentPage,
          order,
          type,
          folder && folder.id,
        );

        this.totalItems = response.data.count;
        this.mediaList = response.data.results.map((m) => ({ ...m, isSelected: false }));
      }
    },

    goToAllFiles() {
      this.folder = null;
    },

    changeCurrentPage(value) {
      this.currentPage = value;
    },

    changeNumberToDisplay(value) {
      this.numberItemsToDisplay = value;
    },

    changeType(value) {
      this.selectedTypeOption = value;
    },

    changeSortingDirection() {
      this.isDESCSorting = !this.isDESCSorting;
    },

    changeMedia(media) {
      if (this.isShowPreviewModal) {
        this.previewMedia = null;
        this.isShowPreviewModal = false;
      }
      this.editMedia = media;
      this.showEditModal();
    },

    handleSelectAllPhoto(isSelected) {
      this.selectedMedia = [];
      if (isSelected) {
        this.mediaList = this.mediaList.map((m) => {
          if (m.fileType !== FILE_TYPE.FOLDER) {
            this.selectedMedia.push(m);
            return { ...m, isSelected: true };
          }
          return m;
        });
      } else {
        this.mediaList = this.mediaList.map((m) => {
          return { ...m, isSelected: false };
        });
      }
    },

    handleSelectMedia(media) {
      const findMedia = this.selectedMedia.find((m) => m.id === media.id);
      if (findMedia) {
        this.selectedMedia = this.selectedMedia.filter((m) => m.id !== findMedia.id);
      } else {
        this.selectedMedia.push(media);
      }
      this.mediaList = this.mediaList.map((m) => {
        if (m.id === media.id) {
          return { ...m, isSelected: !findMedia };
        }

        return m;
      });
    },

    changeOrderOption(value) {
      this.selectedOrderOption = value;
    },

    async downloadSelectedItem() {
      if (!this.selectedMedia.length) return;
      const isSelectedFolder = !!this.selectedMedia.filter((m) => m.fileType === FILE_TYPE.FOLDER)
        .length;

      if (isSelectedFolder || this.selectedMedia.length > 1) {
        const downloadList = this.selectedMedia.map((m) => m.id);
        await downloadMediaService(downloadList, this.group.id);
      } else {
        const media = this.selectedMedia[0];
        FileUtil.downloadFile(media.file, media.name);
      }
    },

    async moveMedia(destination) {
      const moveList = this.selectedMedia.map((m) => m.id);
      try {
        await moveMediaService(moveList, destination, this.group.id);
        await this.getMedia();
      } finally {
        this.hideMoveMediaModal();
        this.selectedMedia = [];
      }
    },

    moveSelectedItem(media) {
      if (this.isShowPreviewModal) {
        this.isShowPreviewModal = false;
      }
      this.selectedMedia.push(media);
      this.showMoveMediaModal();
    },

    deleteSelectedItem(media) {
      this.selectedMedia = [media];
      if (this.isShowPreviewModal) {
        this.isShowPreviewModal = false;
      }
      this.showDeleteModal();
    },

    async deleteSelectedItems() {
      const deleteList = this.selectedMedia.map((m) => m.id);
      try {
        await deleteMediaService(deleteList, this.group.id);
        await this.getMedia();
      } finally {
        this.hideDeleteModal();
        this.selectedMedia = [];
      }
    },

    showCreateFolderModal() {
      this.isShowCreateFolderModal = true;
    },

    hideCreateFolderModal() {
      this.isShowCreateFolderModal = false;
    },
    showEditModal() {
      this.isShowEditModal = true;
    },

    hideEditModal() {
      this.isShowEditModal = false;
    },
    showAdminUpgradeModal() {
      this.isShowAdminUpgradeModal = true;
    },

    hideAdminUpgradeModal() {
      this.isShowAdminUpgradeModal = false;
    },

    showUploadFormModal() {
      this.isShowUploadModal = true;
    },

    hideUploadFormModal() {
      this.isShowUploadModal = false;
    },

    showDeleteModal() {
      if (this.selectedMedia.length) this.isShowDeleteModal = true;
    },

    hideDeleteModal() {
      this.isShowDeleteModal = false;
    },

    async createFolder(name) {
      const { data } = await createFolderService(name, this.group.id);
      this.folder = data;
      this.hideCreateFolderModal();
    },

    showPreviewModal(media) {
      if (media.fileType === FILE_TYPE.FOLDER) {
        this.folder = media;
      } else {
        this.previewMedia = media;
        this.isShowPreviewModal = true;
      }
    },

    hidePreviewModal() {
      this.isShowPreviewModal = false;
    },

    showMoveMediaModal() {
      if (this.selectedMedia.length) this.isShowMoveMediaForm = true;
    },

    hideMoveMediaModal() {
      this.isShowMoveMediaForm = false;
    },

    uploadMedia(media) {
      this.hideUploadFormModal();
      this.getMedia();
      this.editMedia = media;
      this.isFirstUpload = true;
      this.showEditModal();
    },

    async updateMedia() {
      if (this.isFirstUpload) {
        this.isFirstUpload = false;
      }

      this.editMedia = null;
      this.hideEditModal();
      await this.getMedia();
    },

    nextMedia(media) {
      const { mediaList } = this;

      const currentIndex = mediaList.findIndex((m) => m.id === media.id);
      const nextIndex = currentIndex + 1;
      if (nextIndex === mediaList.length) {
        return null;
      }

      if (mediaList[nextIndex].fileType !== FILE_TYPE.FOLDER) {
        this.previewMedia = mediaList[nextIndex];
      } else {
        this.nextMedia(mediaList[nextIndex]);
      }
    },

    previousMedia(media) {
      const { mediaList } = this;

      const currentIndex = mediaList.findIndex((m) => m.id === media.id);
      const previousIndex = currentIndex - 1;
      if (previousIndex === 0) {
        return null;
      }

      if (mediaList[previousIndex].fileType !== FILE_TYPE.FOLDER) {
        this.previewMedia = mediaList[previousIndex];
      } else {
        this.previousMedia(mediaList[previousIndex]);
      }
    },

    async requestToAdmin() {
      await requestSubscriptionService(this.group.slug, this.adminMessage, 'group_photos');
      this.hideAdminUpgradeModal();
    },

    subscribe() {
      this.$router.push({
        name: 'admin',
        params: {
          group: this.group.slug,
          currentPageViewProp: 'subscription',
        },
      });
    },
  },
};
</script>
<style scoped lang="scss">
.sort-btn:hover {
  @apply bg-white;

  svg.sort-icon path {
    @apply stroke-gray-800;
  }
}

svg.sort-icon {
  path {
    stroke: #808285;
  }
}
</style>
