<template>
  <section
    ref="modal"
    class="flex w-full h-full fixed inset-0 outline-none z-50 justify-center items-center"
    tabindex="0"
    @keydown.esc="hideModal">
    <div class="bg-black opacity-25 w-full h-full fixed z-50">
    </div>
    <div
      class="modal bg-gray-100 my-0 absolute px-10 pt-8 flex flex-col top-app-header sm:top-auto mt-5 mt-0
        rounded z-50 max-w-100vw w-95vw sm:max-w-2xl md:w-102 pb-4">
      <h1 class="font-bold text-3xl font-serif text-center">{{ title }}</h1>

      <div class="flex items-center justify-center my-6 h-32">
        <template v-if="step === 1">
          <LogoLetter/>
          <div class="flex justify-center items-center"
               v-if="user && user.profilePicture">
            <p class="text-3xl text-gray-600 font-sans pl-4 pr-6">+</p>
            <img
              class="h-26 w-26 rounded-full border-4 border-gray-400"
              :src="user.profilePicture"
              alt="Your profile headshot">
          </div>
        </template>
        <template v-else-if="step === 2">
          <div class="flex flex-col">
            <div
              v-for="detail in ['School', 'Major', 'Bio']" :key="detail"
              class="my-1 flex items-center">
              <SuccessIcon/>
              <p class="ml-4 bg-white p-3 rounded w-56 text-gray-700 text-base">{{ detail }}</p>
            </div>
          </div>
        </template>
        <template v-else-if="step === 3 && status === 'created-group'">
          <ColorSelector/>
        </template>
        <template v-else-if="step === 3">
          <div class="flex">
            <EmptyHeadshot class="mr-4"/>
            <EmptyHeadshot class="mr-4"/>
            <EmptyHeadshot/>
          </div>
        </template>
        <template v-else>
          <div class="relative">
            <InviteFriends></InviteFriends>
            <span class="absolute cursor">
              <CursorIcon/>
            </span>
          </div>
        </template>
      </div>

      <p class="text-gray-600 text-lg text-center min-h-12 mb-4 flex justify-center items-end">
        {{ message }}
      </p>
      <div class="h-4 flex justify-center mb-2">
        <span
          v-for="index in 4" :key="index"
          :class="{ 'bg-gray-700': index === step, 'mr-4': index !== 4 }"
          class="w-3 h-3 rounded-full bg-gray-400"></span>
      </div>
      <div class="flex flex-col-reverse sm:flex-row mt-4 mb-5"
           :class="{'justify-center': step === 4}"
      >
        <Button
          class="sm:w-1/2 sm:mr-2 min-h-full h-56px skip-btn"
          v-if="step !== 4"
          type="text" compact
          :primary-full-size="false"
          @onClick="hideModal">
          Let's skip this!
        </Button>

        <Button
          class="mb-4 sm:mb-0 sm:w-1/2 sm:ml-2 h-56px"
          :class="{'lg:w-3/4': step === 4}"
          type="primary"
          :primary-full-size="false"
          @onClick="determineNextStep">
            <span class="text-0_8xl text-white">
              {{ buttonText }}
            </span>
        </Button>
      </div>
    </div>
  </section>
</template>

<script>
import Button from '@/components/Button.vue';
import LogoLetter from '@/assets/logo_letter.svg';
import SuccessIcon from '@/assets/success_icon.svg';
import ColorSelector from '@/assets/color_selector.svg';
import EmptyHeadshot from '@/assets/empty_headshot.svg';
import InviteFriends from '@/assets/invite_friends.svg';
import CursorIcon from '@/assets/cursor.svg';
import { mapGetters } from 'vuex';

export default {
  name: 'WelcomeModal',
  components: {
    Button,
    LogoLetter,
    SuccessIcon,
    ColorSelector,
    EmptyHeadshot,
    InviteFriends,
    CursorIcon,
  },
  computed: {
    ...mapGetters(['user']),
  },
  data() {
    return {
      step: 1,
      buttonText: 'Next',
      status: 'joined-group',
      title: 'Welcome to Reunacy!',
      message: 'Let’s do a quick run-through of your next steps!',
    };
  },
  props: {
    /**
     * function to handle closing modal
     */
    hideModal: {
      type: Function,
      required: true,
    },
  },
  created() {
    if (localStorage.getItem('first-session-type')) {
      this.status = localStorage.getItem('first-session-type'); // joined-group or created-group
    }
  },
  methods: {
    determineNextStep() {
      if (this.step === 4) {
        this.hideModal();
      } else {
        this.increaseStep();
      }
    },
    increaseStep() {
      this.step += 1;
      if (this.step === 2) {
        this.title = 'Complete your Profile';
        this.message = 'The best way of connecting with people is to share a little about yourself.';
      } else if (this.step === 3 && this.status === 'created-group') {
        this.title = 'Update the Group';
        this.message = 'Head over to your group page and let people know what this group is about!';
      } else if (this.step === 3 && this.status === 'joined-group') {
        this.title = 'Explore the Members';
        this.message = 'Explore other group members’ profiles!';
      } else {
        this.title = 'Invite your Friends';
        this.message = 'Everything’s better with more friends! If you know someone who should join, invite them!';
        this.buttonText = 'Let\'s go!';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cursor {
  bottom: -1.5rem;
  right: -2rem;
}

.skip-btn.text {
  padding-left: 10px;
  padding-right: 10px;
}
</style>
