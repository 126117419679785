<template>
  <div v-if="loaded">
    <div class="block sm:hidden cursor-pointer mb-6">
      <div
        @click="goToCreateEvent"
        v-if="showAddNewEvent"
        class="flex items-center animation-focus-default"
      >
        <PlusCircleIcon class="plus-circle-icon plus-icon w-5 h-5 mr-2"></PlusCircleIcon>
        <div class="text-sm font-semibold">Create a New Event</div>
      </div>
    </div>

    <div v-if="!isPreview && !groupEvents.length" class="empty-card-group-admin">
      <CalendarIcon class="empty-card-icon"></CalendarIcon>
      <div class="text-sm text-gray-600">No events at the moment</div>
    </div>

    <div
      v-if="isPreview && !showPastEvents && futureEventsSorted && !futureEventsSorted.length"
      class="empty-card-group-admin"
    >
      <CalendarIcon class="empty-card-icon"></CalendarIcon>
      <div class="text-sm text-gray-600">You have no upcoming events at the moment</div>
    </div>

    <!--               -->
    <!-- FUTURE EVENTS -->
    <!--               -->

    <template>
      <div
        v-if="!isPreview && futureEventsSorted && futureEventsSorted.length"
        class="flex w-full items-center justify-between"
      >
        <h2 class="mb-0 font-serif text-2xl">Upcoming Events</h2>
        <div class="flex items-center">
          <div
            class="hidden sm:block cursor-pointer"
            @click="goToCreateEvent"
            v-if="showAddNewEvent"
          >
            <div class="flex items-center animation-focus-default">
              <PlusCircleIcon class="plus-circle-icon plus-icon w-5 h-5 mr-2"></PlusCircleIcon>
              <div class="text-sm font-semibold">Create a New Event</div>
            </div>
          </div>
        </div>
      </div>

      <template v-if="!isPreview">
        <div v-for="(year, evIndex) in futureEventsSorted" :key="'future-' + year.key">
          <h4 class="pb-6 pt-3 sm:pt-4 text-primary">{{ year.key || 'TBD' }}</h4>
          <div
            :class="{
              'border-b border-gray-300 pb-4 sm:pb-12 mb-2':
                showPastEvents &&
                index === year.values.length - 1 &&
                evIndex !== futureEventsSorted.length - 1,
            }"
            v-for="(event, index) in year.values"
            :key="event.id"
          >
            <EventSummary
              :class="{ 'mb-4 sm:mb-6': index !== year.values.length - 1 }"
              :groupEvent="event"
              :is-preview="isPreview"
            ></EventSummary>
          </div>
        </div>
      </template>

      <template v-else>
        <div v-for="(event, index) in futureEventsSorted" :key="'future-' + event.id">
          <EventSummary
            :class="{ 'mb-4': index !== futureEventsSorted.length - 1 }"
            :groupEvent="event"
            :is-preview="isPreview"
          ></EventSummary>
        </div>
      </template>
    </template>

    <!--             -->
    <!-- PAST EVENTS -->
    <!--             -->

    <template v-if="showPastEvents">
      <div
        v-if="!isPreview && pastEventsSorted && pastEventsSorted.length && showPastEventsLink"
        :class="{
          'mt-8 sm:mt-12 border-t border-gray-300 pt-12':
            !isPreview && futureEventsSorted && futureEventsSorted.length,
        }"
        class="flex w-full items-center justify-between"
      >
        <h2 class="font-serif text-2xl mb-0">Past Events</h2>
        <div class="flex items-center">
          <div
            class="hidden sm:block cursor-pointer"
            @click="goToCreateEvent"
            v-if="showAddNewEvent"
          >
            <div class="flex items-center animation-focus-default">
              <PlusCircleIcon class="plus-circle-icon plus-icon w-5 h-5 mr-2"></PlusCircleIcon>
              <div class="text-sm font-semibold">Create a New Event</div>
            </div>
          </div>
        </div>
      </div>

      <template v-if="!isPreview">
        <div v-for="(year, evIndex) in pastEventsSorted" :key="'past-' + year.key">
          <h4 class="pb-6 pt-3 sm:pt-4 text-primary">{{ year.key }}</h4>
          <div
            :class="{
              'border-b border-gray-300 pb-4 sm:pb-12 mb-2':
                index === year.values.length - 1 && evIndex !== pastEventsSorted.length - 1,
            }"
            v-for="(event, index) in year.values"
            :key="event.id"
          >
            <EventSummary
              :class="{ 'mb-4 sm:mb-6': index !== year.values.length - 1 }"
              :groupEvent="event"
              :is-preview="false"
            ></EventSummary>
          </div>
        </div>
      </template>
      <template v-else>
        <div v-for="(event, index) in pastEventsSorted" :key="'past-' + event.id">
          <EventSummary
            :class="{ 'mb-4': index !== pastEventsSorted.length - 1 }"
            :groupEvent="event"
            :is-preview="true"
          ></EventSummary>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import EventSummary from '@/components/events/EventSummary.vue';
import PlusCircleIcon from '@/assets/icon_plus_circled.svg';
import CalendarIcon from '@/assets/icon_calendar_large.svg';
import { DateTime } from 'luxon';
import { groupByArray } from '../../helpers';

export default {
  name: 'EventList',
  components: {
    EventSummary,
    PlusCircleIcon,
    CalendarIcon,
  },
  data() {
    return {
      loaded: false,
    };
  },
  created() {
    this.fetchEventList({ groupSlug: this.$route.params.group }).then(() => {
      this.loaded = true;
    });
  },
  props: {
    showPastEvents: {
      type: Boolean,
      default: () => false,
    },
    showPastEventsLink: {
      type: Boolean,
      default: () => true,
    },
    showAddNewEvent: {
      type: Boolean,
      default: () => false,
    },
    isPreview: {
      type: Boolean,
      default: () => false,
    },
    showControls: {
      type: Boolean,
      default: () => true,
    },
  },
  methods: {
    ...mapActions(['fetchEventList']),
    goToCreateEvent() {
      // Google Analytics Event
      this.$gtag.event('create_event', {
        event_category: 'events',
        event_label: `${this.$route.params.group} / User clicked create event`,
      });
      this.$router.push({
        name: 'create-event',
      });
    },
    goToEvents() {
      this.$router.push({
        name: 'events',
      });
    },
    groupByYear(newArr) {
      const sorted = newArr.sort((a, b) => {
        if (a.startDatetime == null && b.startDatetime == null) {
          return 0;
        }
        if (a.startDatetime == null) {
          return 1;
        }
        if (b.startDatetime == null) {
          return -1;
        }
        return DateTime.fromISO(a.startDatetime) - DateTime.fromISO(b.startDatetime);
      });

      for (let i = 0; i < sorted.length; i++) {
        if (sorted[i].startDatetime == null) {
          sorted[i].year = 'TBD';
        } else {
          sorted[i].year = DateTime.fromISO(sorted[i].startDatetime).year;
        }
      }

      return this.isPreview ? newArr.slice(0, 3) : groupByArray(sorted, 'year');
    },
  },
  computed: {
    ...mapGetters(['groupEvents']),
    futureEventsSorted() {
      const newArr = [
        ...this.groupEvents.filter((x) => {
          return (
            DateTime.fromISO(x.startDatetime) > DateTime.now().minus({ days: 1 }) ||
            !x.startDatetime
          );
        }),
      ];

      return this.groupByYear(newArr);
    },
    pastEventsSorted() {
      const newArr = [
        ...this.groupEvents.filter((x) => {
          return DateTime.fromISO(x.startDatetime) < DateTime.now().minus({ days: 1 });
        }),
      ];

      return this.groupByYear(newArr);
    },
  },
};
</script>

<style scoped>
.events-header {
  margin-bottom: 0;
}
</style>
