import { api } from '@/store';

export async function getPromptsService() {
  const token = localStorage.getItem('access-token');
  const baseHeader = { Authorization: `Bearer ${token}` };
  try {
    const response = await api.get('api/v1/openai-prompts/', {
      headers: {
        ...baseHeader,
      },
    });
    return response.data.prompts;
  } catch (e) {
    console.error(e);
  }
}

export async function getPromptResponseService(groupSlug, promptId) {
  const token = localStorage.getItem('access-token');
  const baseHeader = { Authorization: `Bearer ${token}` };
  try {
    const response = await api.get(
      `api/v1/groups/${groupSlug}/prompt/${promptId}/openai-response/`,
      {
        headers: {
          ...baseHeader,
        },
      },
    );
    return response.data.openaiResponse;
  } catch (e) {
    console.error(e);
  }
}
