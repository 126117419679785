var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loaded)?_c('div',[_c('div',{staticClass:"block sm:hidden cursor-pointer mb-6"},[(_vm.showAddNewEvent)?_c('div',{staticClass:"flex items-center animation-focus-default",on:{"click":_vm.goToCreateEvent}},[_c('PlusCircleIcon',{staticClass:"plus-circle-icon plus-icon w-5 h-5 mr-2"}),_c('div',{staticClass:"text-sm font-semibold"},[_vm._v("Create a New Event")])],1):_vm._e()]),(!_vm.isPreview && !_vm.groupEvents.length)?_c('div',{staticClass:"empty-card-group-admin"},[_c('CalendarIcon',{staticClass:"empty-card-icon"}),_c('div',{staticClass:"text-sm text-gray-600"},[_vm._v("No events at the moment")])],1):_vm._e(),(_vm.isPreview && !_vm.showPastEvents && _vm.futureEventsSorted && !_vm.futureEventsSorted.length)?_c('div',{staticClass:"empty-card-group-admin"},[_c('CalendarIcon',{staticClass:"empty-card-icon"}),_c('div',{staticClass:"text-sm text-gray-600"},[_vm._v("You have no upcoming events at the moment")])],1):_vm._e(),[(!_vm.isPreview && _vm.futureEventsSorted && _vm.futureEventsSorted.length)?_c('div',{staticClass:"flex w-full items-center justify-between"},[_c('h2',{staticClass:"mb-0 font-serif text-2xl"},[_vm._v("Upcoming Events")]),_c('div',{staticClass:"flex items-center"},[(_vm.showAddNewEvent)?_c('div',{staticClass:"hidden sm:block cursor-pointer",on:{"click":_vm.goToCreateEvent}},[_c('div',{staticClass:"flex items-center animation-focus-default"},[_c('PlusCircleIcon',{staticClass:"plus-circle-icon plus-icon w-5 h-5 mr-2"}),_c('div',{staticClass:"text-sm font-semibold"},[_vm._v("Create a New Event")])],1)]):_vm._e()])]):_vm._e(),(!_vm.isPreview)?_vm._l((_vm.futureEventsSorted),function(year,evIndex){return _c('div',{key:'future-' + year.key},[_c('h4',{staticClass:"pb-6 pt-3 sm:pt-4 text-primary"},[_vm._v(_vm._s(year.key || 'TBD'))]),_vm._l((year.values),function(event,index){return _c('div',{key:event.id,class:{
            'border-b border-gray-300 pb-4 sm:pb-12 mb-2':
              _vm.showPastEvents &&
              index === year.values.length - 1 &&
              evIndex !== _vm.futureEventsSorted.length - 1,
          }},[_c('EventSummary',{class:{ 'mb-4 sm:mb-6': index !== year.values.length - 1 },attrs:{"groupEvent":event,"is-preview":_vm.isPreview}})],1)})],2)}):_vm._l((_vm.futureEventsSorted),function(event,index){return _c('div',{key:'future-' + event.id},[_c('EventSummary',{class:{ 'mb-4': index !== _vm.futureEventsSorted.length - 1 },attrs:{"groupEvent":event,"is-preview":_vm.isPreview}})],1)})],(_vm.showPastEvents)?[(!_vm.isPreview && _vm.pastEventsSorted && _vm.pastEventsSorted.length && _vm.showPastEventsLink)?_c('div',{staticClass:"flex w-full items-center justify-between",class:{
        'mt-8 sm:mt-12 border-t border-gray-300 pt-12':
          !_vm.isPreview && _vm.futureEventsSorted && _vm.futureEventsSorted.length,
      }},[_c('h2',{staticClass:"font-serif text-2xl mb-0"},[_vm._v("Past Events")]),_c('div',{staticClass:"flex items-center"},[(_vm.showAddNewEvent)?_c('div',{staticClass:"hidden sm:block cursor-pointer",on:{"click":_vm.goToCreateEvent}},[_c('div',{staticClass:"flex items-center animation-focus-default"},[_c('PlusCircleIcon',{staticClass:"plus-circle-icon plus-icon w-5 h-5 mr-2"}),_c('div',{staticClass:"text-sm font-semibold"},[_vm._v("Create a New Event")])],1)]):_vm._e()])]):_vm._e(),(!_vm.isPreview)?_vm._l((_vm.pastEventsSorted),function(year,evIndex){return _c('div',{key:'past-' + year.key},[_c('h4',{staticClass:"pb-6 pt-3 sm:pt-4 text-primary"},[_vm._v(_vm._s(year.key))]),_vm._l((year.values),function(event,index){return _c('div',{key:event.id,class:{
            'border-b border-gray-300 pb-4 sm:pb-12 mb-2':
              index === year.values.length - 1 && evIndex !== _vm.pastEventsSorted.length - 1,
          }},[_c('EventSummary',{class:{ 'mb-4 sm:mb-6': index !== year.values.length - 1 },attrs:{"groupEvent":event,"is-preview":false}})],1)})],2)}):_vm._l((_vm.pastEventsSorted),function(event,index){return _c('div',{key:'past-' + event.id},[_c('EventSummary',{class:{ 'mb-4': index !== _vm.pastEventsSorted.length - 1 },attrs:{"groupEvent":event,"is-preview":true}})],1)})]:_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }