<template>
  <div class="not-found flex w-full max-w-screen-xl mx-auto px-4 sm:px-8 py-4
  text-primary font-sans">
    <h1>404 Not Found</h1>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>
