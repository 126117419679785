import { api } from '@/store';
import { pick } from '@/helpers/object.helper';
import { FileUtil } from '@/utils/file.util';

export async function updateMediaService(media, groupId) {
  const token = localStorage.getItem('access-token');
  try {
    const response = await api.patch(
      `api/v1/groups/${groupId}/media/${media.id}/`,
      pick(media, 'name', 'location', 'description'),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return !!response;
  } catch (e) {
    const { name } = e.response.data;
    if (name !== undefined && name.length) {
      // eslint-disable-next-line prefer-destructuring
      throw name[0];
    }

    console.error(e);
  }
}

export async function updateBulkMediaService(mediaList, groupId) {
  const token = localStorage.getItem('access-token');
  try {
    const response = await api.patch(`api/v1/groups/${groupId}/media/bulk_edit/`, mediaList, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return !!response;
  } catch (e) {
    const { errors } = e.response.data;
    throw errors;
  }
}

export async function createFolderService(folderName, groupId) {
  const token = localStorage.getItem('access-token');
  const formData = new FormData();
  formData.append('name', folderName);
  try {
    const response = await api.post(`api/v1/groups/${groupId}/media/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getFoldersService(groupId) {
  const token = localStorage.getItem('access-token');
  try {
    return await api.get(`api/v1/groups/${groupId}/media/?file_type=folder`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (e) {
    console.error(e);
  }
}

export async function getMediaService(groupId, size, page, order, type = null, parentId = null) {
  const token = localStorage.getItem('access-token');
  try {
    const apiUrl = `api/v1/groups/${groupId}/media/?page_size=${size}&page=${page}`;

    const fileTypeParam = !type || type === 'all' ? '' : `&file_type=${type}`;
    const orderingParam = `&ordering=${order}`;
    const parentParam = !parentId ? '' : `&parent=${parentId}`;

    return await api.get(`${apiUrl}${fileTypeParam}${orderingParam}${parentParam}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (e) {
    console.error(e);
  }
}

export async function moveMediaService(moveList, folderId, groupId) {
  const token = localStorage.getItem('access-token');
  try {
    const response = await api.post(
      `api/v1/groups/${groupId}/media/move/`,
      {
        folder_id: folderId,
        file_ids: moveList,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function downloadMediaService(downloadList, groupId) {
  const token = localStorage.getItem('access-token');
  try {
    const response = await api.post(
      `api/v1/groups/${groupId}/media/download/`,
      {
        file_ids: downloadList,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      },
    );

    FileUtil.forceFileDownload(response.request.response, null, true);
  } catch (error) {
    console.error('Error downloading ZIP:', error);
  }
}

export async function deleteMediaService(deleteList, groupId) {
  const token = localStorage.getItem('access-token');
  try {
    const response = await api.post(
      `api/v1/groups/${groupId}/media/bulk_delete/`,
      { file_ids: deleteList },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}
