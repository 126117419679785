<template>
  <div class="relative flex flex-col sm:flex-row justify-between items-center min-h-55px">
    <div class="flex">
      <Button
        v-if="showLeftButton"
        type="text"
        @onClick="buttonLeftAction"
        key="btn_back"
        :loading="isLoading"
        class="semi-transparent hidden sm:flex"
      >
        <div class="text-white text-0_8xl flex items-center">
          <ArrowRightIcon class="arrow-right-icon left w-4 mr-3 rotate-180"></ArrowRightIcon>
          {{ buttonLeftText }}
        </div>
      </Button>
      <Button
        v-if="showRightButtonSecondary"
        class="hidden sm:flex lg:hidden semi-transparent ml-4"
        type="text"
        @onClick="buttonRightAction"
        key="btn_forward_dos"
        :loading="isLoading"
      >
        <div class="text-white text-0_8xl flex items-center">
          {{ buttonRightSecondaryText }}
        </div>
      </Button>
    </div>

    <ArrowRightIcon
      @click="buttonLeftAction"
      v-if="showLeftButton"
      class="fixed block sm:hidden left-6 arrow-right-icon left w-4 mt-2 rotate-180 animation-focus-default"
    ></ArrowRightIcon>

    <div class="bullets-container absolute flex justify-center flex-grow" v-if="stepsCount">
      <div v-for="(bullet, index) in Array(stepsCount)" :key="bullet" class="flex items-center">
        <div
          class="bullet bg-white rounded-full ml-4"
          :class="{ active: index === currentStep - 1, 'ml-0': index === 0 }"
        ></div>
      </div>
    </div>

    <div class="flex fixed bottom-0 right-0 left-0 mx-6 my-4 sm:m-0 sm:relative">
      <Button
        v-if="showRightButtonSecondary"
        class="sm:hidden lg:flex semi-transparent mr-4"
        type="text"
        @onClick="buttonRightAction"
        key="btn_forward_dos"
        :loading="isLoading"
      >
        <div class="text-white text-0_8xl flex items-center">
          {{ buttonRightSecondaryText }}
        </div>
      </Button>
      <Button
        v-if="showRightButtonPrimary"
        type="primary"
        @onClick="buttonRightAction"
        key="btn_forward"
        :loading="isLoading"
      >
        <div class="text-white text-0_8xl py-1 px-2 flex items-center">
          {{ buttonRightText }}
          <ArrowRightIcon class="arrow-right-icon w-4 ml-3"></ArrowRightIcon>
        </div>
      </Button>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue';
import ArrowRightIcon from '@/assets/icon_arrow_right.svg';

export default {
  name: 'ButtonBar',
  components: {
    Button,
    ArrowRightIcon,
  },
  watch: {
    globalErrors(errors) {
      errors.forEach(() => {});
    },
  },
  props: {
    buttonLeftText: {
      default: () => 'Back',
    },
    buttonRightText: {
      default: () => 'Continue',
    },
    buttonRightSecondaryText: {
      default: () => 'Skip',
    },
    buttonRightAction: {
      type: Function,
    },
    buttonLeftAction: {
      type: Function,
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
    showLeftButton: {
      type: Boolean,
      default: () => true,
    },
    showRightButtonSecondary: {
      type: Boolean,
      default: () => false,
    },
    showRightButtonPrimary: {
      type: Boolean,
      default: () => true,
    },
    stepsCount: {
      type: Number,
    },
    currentStep: {
      type: Number,
      default: () => 0,
    },
  },
};
</script>

<style lang="scss" scoped>
.bullets-container {
  left: 50%;
  transform: translate(-50%, 100%);
}
.bullet {
  width: 10px;
  height: 10px;
  opacity: 0.5;

  &.active {
    opacity: 1;
  }
}
.arrow-right-icon {
  path {
    fill: #ffffff;
  }

  &.left {
    transform: rotateY(180deg);
  }
}
@screen sm {
  .bullets-container {
    transform: translate(-50%);
  }
}
</style>
