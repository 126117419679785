<template>
  <div class="mt-8">
    <p class="text-gray-800 mb-2 text-sm font-semibold leading-[140%]">Emoji</p>
    <EmojiButton
      class="w-full mb-5 h-[48px] border rounded border-gray-300 hover:border-gray-800 focus:outline-none transition duration-200 ease"
      :color="this.group.primaryColor"
      @pickedEmoji="updateIcon"
      @watcher="watcherForPicker"
      scheme="full"
    >
      <template v-slot:emoji>
        {{ newField.icon || 'Select Emoji' }}
        <Chevron class="w-3 ml-2 text-gray-600" :class="{ 'rotate-180': isPickerShow }" />
      </template>
    </EmojiButton>
    <TextInput
      class="mb-4"
      label="Question (e.g. What is your favorite food?)"
      id="newFieldQuestion"
      v-model="newField.question"
    />
    <TextInput
      class="mb-4"
      label="Label"
      id="newFieldLabel"
      v-model="newField.label"
      :maxLength="35"
    />
    <Checkbox
      class="mb-4"
      id="display_members"
      label="Visible on Members"
      v-model="newField.showOnYearbook"
      scheme="blue"
    />
    <Checkbox
      class="mb-4"
      id="display_profile"
      label="Visible on Profile"
      v-model="newField.showOnProfile"
      scheme="blue"
    />

    <div class="controls w-full flex flex-col-reverse sm:flex-row justify-center pt-1 sm:pt-6">
      <Button
        class="cancel-btn min-w-[130px] w-full h-10 mr-0 sm:mr-2 mt-2 sm:mt-0"
        type="text"
        @onClick="$emit('cancel')"
      >
        <span class="text-sm text-gray-600 font-semibold">Cancel</span>
      </Button>
      <Button
        :disabled="isSaveDisabled"
        class="save-btn min-w-[130px] w-full h-10 ml-0 sm:ml-2"
        type="primary"
        :compact="true"
        @onClick="submit"
      >
        <span class="text-sm text-white font-semibold">Save</span>
      </Button>
    </div>
  </div>
</template>
<script>
import TextInput from '@/components/TextInput.vue';
import Chevron from '@/assets/chevron.svg';
import Checkbox from '@/components/Checkbox.vue';
import EmojiButton from '@/components/EmojiButton.vue';
import { mapGetters } from 'vuex';
import Button from '@/components/Button.vue';
import { pick } from '@/helpers/object.helper';

export default {
  name: 'FormAddEditField',

  components: { Button, EmojiButton, Checkbox, Chevron, TextInput },

  props: {
    field: {
      type: [Object, null],
      default: null,
    },
  },

  data() {
    return {
      isPickerShow: false,
      newField: {
        icon: '',
        label: '',
        question: '',
        showOnProfile: true,
        showOnYearbook: true,
      },
    };
  },

  computed: {
    ...mapGetters(['group']),
    isSaveDisabled() {
      return !this.newField.icon || !this.newField.question;
    },
  },

  created() {
    if (this.field) {
      this.newField = pick(
        this.field,
        'icon',
        'label',
        'question',
        'showOnProfile',
        'showOnYearbook',
      );
    }
  },

  methods: {
    watcherForPicker(status) {
      this.isPickerShow = status;
    },

    updateIcon(emoji) {
      this.newField.icon = emoji;
    },

    submit() {
      this.$emit('submit', this.newField);
    },
  },
};
</script>
