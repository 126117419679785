<template>
  <form class="max-h-[80vh] w-[65vw] lg:w-[850px] relative">
    <div class="relative rounded-md">
      <FileField
        :value="mediaList"
        @input="addFiles"
        is-multiple
        :wrapper-class="mediaList.length ? 'min-h-[200px]' : 'min-h-[340px] lg:min-h-[540px]'"
      />
    </div>
    <div v-if="mediaList.length" class="pb-3">
      <p class="text-gray-900 text-sm my-4">Your Files</p>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
        <ImageItem
          v-for="(file, idx) in mediaList"
          :key="idx"
          :file="file"
          :index="idx"
          @delete="deleteImage"
          scheme="secondary"
        />
      </div>
    </div>

    <p v-if="errorMessage" class="text-small text-red-600 font-light">
      {{ errorMessage }}
    </p>

    <div class="controls w-full flex justify-between pt-12">
      <Button class="cancel-btn w-[200px] h-10 mr-6px" type="text" @onClick="$emit('cancel')">
        <span class="text-sm text-gray-600">Cancel</span>
      </Button>
      <Button
        class="save-btn max-w-[200px] min-w-[130px] h-10 ml-0 sm:ml-2 mb-2 sm:mb-0"
        type="primary"
        :compact="true"
        @onClick="submit"
        :disabled="!mediaList.length"
      >
        <span class="text-sm text-white">Continue</span>
      </Button>
    </div>

    <div
      v-if="isLoading"
      class="w-full h-full absolute top-0 left-0 bg-white opacity-70 flex justify-center items-center"
    >
      <Preloader class="w-32 h-32" />
    </div>
  </form>
</template>

<script>
import Button from '@/components/Button.vue';
import ImageItem from '@/components/ui/ImageItem.vue';
import FileField from '@/components/forms/fields/FileField.vue';
import { api } from '@/store';
import { FileUtil } from '@/utils/file.util';
import Preloader from '@/assets/icon_circle_animation.svg';

export default {
  name: 'CreateEditMediaForm',
  components: {
    Preloader,
    ImageItem,
    FileField,
    Button,
  },
  props: {
    groupId: {
      type: Number,
      require: true,
    },
    parentId: {
      type: Number,
    },
  },
  data() {
    return {
      isLoading: false,
      mediaList: [],
      errorMessage: '',
    };
  },

  methods: {
    deleteImage(idx) {
      if (this.errorMessage.length) this.errorMessage = '';
      this.mediaList = this.mediaList.filter((file, file_idx) => file_idx !== idx);
    },

    addFiles(files) {
      if (this.errorMessage.length) this.errorMessage = '';
      this.mediaList = [...this.mediaList, ...files];
    },

    async submit() {
      try {
        this.isLoading = true;
        this.errorMessage = '';
        const formData = new FormData();
        const token = localStorage.getItem('access-token');

        // Fetch blobs for each file in mediaList
        await Promise.all(
          this.mediaList.map(async (file) => {
            const blob = await FileUtil.getBlob(file);
            formData.append('files', blob, file.name);
            return blob;
          }),
        );

        if (this.parentId) {
          formData.append('parent', String(this.parentId));
        }

        const response = await api.post(
          `api/v1/groups/${this.groupId}/media/bulk_create/`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${token}`,
            },
          },
        );

        this.$emit('upload', response.data.results);
      } catch (error) {
        console.error('Error uploading file:', error);
        const result = error.response.data;
        const { errorMsg } = result;
        this.errorMessage = errorMsg;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.error-message {
  @apply absolute -bottom-6 left-0 text-small text-red-600 font-light;
}
</style>
