<template>
  <div class="flex">
    <!--Binding css variables to use as height/width of :before -> the slider -->
    <div class="toggle-slider" :class="{ 'sm:mr-3': !isLabelHide }" :style="getStyleObject">
      <label class="switch" :class="{ 'default-color': isColorDefault() }">
        <input v-model="isActive" @input="setNewToggleState" type="checkbox" />
        <span class="track">
          <span class="handle"></span>
        </span>
      </label>
    </div>
    <div v-if="!isLabelHide" class="hidden sm:flex flex-col justify-center">
      <div class="text-sm">{{ isActive ? 'On' : 'Off' }}</div>
    </div>
  </div>
</template>

<script>
import { DEFAULT_COLOR } from '@/components/constants/groupColors';
import { mapGetters } from 'vuex';

const PROP_KEYS = {
  DIAMETER: 'diameter',
  COLOR: 'color',
  BORDER_RADIUS: 'borderRadius',
  BORDER_WIDTH: 'borderWidth',
  WIDTH: 'width',
  HEIGHT: 'height',
  ACTIVE_COLOR: 'activeColor',
};
export default {
  name: 'ToggleButton',
  props: {
    options: {
      type: Object,
    },
    isLabelHide: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      handle: {
        diameter: 25, // optional
        distance: 19, // optional
        color: '#fff', // optional
        borderRadius: '50%', // optional
      },
      track: {
        color: '#AAACAF', // optional
        width: 50, // optional
        height: 24, // optional
        activeColor: '#2196F3', // optional
        borderWidth: 0, // optional
        borderRadius: '34px', // optional
      },
      isActive: true,
      defaultColor: DEFAULT_COLOR,
    };
  },
  computed: {
    ...mapGetters(['group']),
    getHandleDistance() {
      let handleDistance = 0;
      if (this.options && this.options.handle && this.options.track) {
        handleDistance = this.options.track.width - this.options.handle.diameter;
      } else {
        handleDistance = this.handle.distance;
      }
      return handleDistance;
    },
    getStyleObject() {
      const styleObj = {
        '--handle-diameter': `${this.handle.diameter}px`,
        '--handle-color': this.handle.color,
        '--handle-border-radius': this.handle.borderRadius,
        '--handle-distance': `${this.getHandleDistance}px`,
        '--track-color': this.track.color,
        '--track-width': `${this.track.width}px`,
        '--track-height': `${this.track.height}px`,
        '--track-active-color': this.track.activeColor,
        '--track-border-width': `${this.track.borderWidth}px`,
        '--track-border-radius': this.track.borderRadius,
      };
      return styleObj;
    },
  },
  methods: {
    isColorDefault() {
      return this.group.primaryColor === this.defaultColor;
    },
    setConfigData() {
      if (!this.options) {
        return;
      }
      if (this.options.handle) {
        [PROP_KEYS.COLOR, PROP_KEYS.DIAMETER, PROP_KEYS.BORDER_RADIUS].forEach((element) => {
          this.setBindedProp('handle', element);
        });
      }
      if (this.options.track) {
        // TODO - track border width, track border radius - 24.12.18
        [
          PROP_KEYS.COLOR,
          PROP_KEYS.WIDTH,
          PROP_KEYS.HEIGHT,
          PROP_KEYS.ACTIVE_COLOR,
          PROP_KEYS.BORDER_WIDTH,
          PROP_KEYS.BORDER_RADIUS,
        ].forEach((element) => {
          this.setBindedProp('track', element);
        });
      }
      if (this.options.isActive) {
        this.isActive = this.options.isActive;
      }
    },
    setBindedProp(key, propToBind) {
      if (this.option[key][propToBind]) {
        this[key][propToBind] = this.option[key][propToBind];
      }
    },
    setNewToggleState() {
      this.isActive = !this.isActive;
      this.$emit('input', this.isActive);
    },
  },
  created() {
    this.setConfigData();
  },
  watch: {
    // eslint-disable-next-line func-names
    'options.isActive': function (value) {
      this.isActive = value;
    },
  },
};
</script>

<style scoped lang="scss">
.switch {
  position: relative;
  display: inline-block;
  width: var(--track-width);
  height: var(--track-height);

  input {
    display: none;
  }

  .track {
    display: flex;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 31px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--track-color);
    cursor: pointer;
    border: var(--track-border-width) solid var(--track-color);
    border-radius: var(--track-border-radius);
    transition: 0.4s;

    .handle {
      display: flex;
      width: var(--handle-diameter);
      height: var(--handle-diameter);
      background-color: var(--handle-color);
      border-radius: var(--handle-border-radius);
      transition: 0.4s;
      margin-left: 3px;
    }
  }

  &.default-color {
    input:checked + .track {
      @apply bg-defaultInteractive;
    }
  }

  input:checked + .track {
    @apply bg-primary;
    border: var(--track-border-width) solid var(--track-active-color);
  }

  input:focus + .track {
    box-shadow: 0 0 1px var(--track-active-color);
  }

  input:checked + .track > .handle {
    transform: translateX(var(--handle-distance));
  }
}
</style>
