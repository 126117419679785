<template>
  <div class="py-8 sm:py-12 lg:py-16">
    <div class="mx-auto px-6 sm:px-12 lg:px-8 max-w-screen-xl">
      <Breadcrumbs :crumbs="breadcrumbs"></Breadcrumbs>

      <div class="flex flex-col my-3">
        <h1 class="mb-0 md:mb-8">Good {{ timeOfDay }}, {{ user.firstName }}</h1>

        <div class="flex flex-col lg:flex-row justify-between mt-4 lg:mt-0">
          <div class="groups-container w-full lg:w-3/4">
            <h6 class="mb-4 lg:mb-8 flex justify-between items-center">
              <template v-if="user.groups">
                <span
                  ><span class="hidden lg:inline-block mr-1">You're a member of </span
                  >{{ user.groups.length }} {{ 'group' | pluralize(user.groups.length) }}</span
                >
              </template>

              <DropdownCounter
                label="Sort by tag"
                :options="sortOptions"
                @selection="filterGroups"
              ></DropdownCounter>
            </h6>

            <GroupItem
              class="group mb-4 lg:mb-6"
              :key="index"
              :group="group"
              :groupIndex="index"
              @chooseGroup="handleRouteToGroup"
              v-for="(group, index) in filteredGroups"
            ></GroupItem>
          </div>

          <div class="new-group-container w-auto min-w-276px lg:ml-8">
            <div
              class="new-group-card"
              :class="{ stick: isNewGroupCardStick }"
              :style="{
                top: isNewGroupCardStick ? stickTopOffset + 'px' : 'auto',
                left: isNewGroupCardStick ? stickLeftOffset + 'px' : 'auto',
              }"
            >
              <div
                class="flex items-center flex-col sm:flex-row lg:flex-col justify-center sm:justify-start lg:justify-center w-full"
              >
                <CTAIcon class="hidden sm:block mr-6 lg:mb-6 lg:mx-auto"></CTAIcon>
                <div class="header-container">
                  <h2 class="font-normal sm:font-medium text-center sm:text-left lg:text-center">
                    Start a new group
                  </h2>
                  <p
                    class="text-sm lg:text-md text-center sm:text-left lg:text-center font-normal mt-4 sm:mt-2 mb-4 sm:mb-0 lg:mb-6"
                  >
                    Create a new Reunacy group and invite your crew.
                  </p>
                </div>
              </div>
              <Button
                class="min-w-[155px] lg:w-full sm:ml-3 lg:ml-0 h-10"
                :compact="true"
                :primary-full-size="false"
                type="primary"
                @onClick="handleCreateGroup()"
              >
                Create a Group
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cssVars from 'css-vars-ponyfill';
import Button from '@/components/Button.vue';
import { mapActions, mapGetters } from 'vuex';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import DropdownCounter from '@/components/DropdownCounter.vue';
import AcademicIcon from '@/assets/icon_academic.svg';
import HomeIcon from '@/assets/icon_home.svg';
import WorkIcon from '@/assets/icon_work.svg';
import UsersIcon from '@/assets/icon_users.svg';
import CTAIcon from '@/assets/icon_CTA.svg';
import GroupItem from '@/components/GroupItem.vue';

export default {
  name: 'GroupSelect',
  components: {
    GroupItem,
    DropdownCounter,
    Breadcrumbs,
    Button,
    // eslint-disable-next-line
    AcademicIcon,
    // eslint-disable-next-line
    HomeIcon,
    // eslint-disable-next-line
    UsersIcon,
    // eslint-disable-next-line
    WorkIcon,
    CTAIcon,
  },
  created() {
    if (this.user && this.user.groups && this.user.groups.length) {
      this.setGroupColorVars();
      this.filteredGroups = this.user.groups;
      this.setSortOptionsCount(this.user.groups);
    }
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  },
  mounted() {
    this.stickLeftOffset = this.$el.querySelector('.new-group-card').getBoundingClientRect().x;
    const elemRect = this.$el.querySelector('.new-group-card').getBoundingClientRect();
    const headerRect = document.getElementsByClassName('h-app-header')[0]?.getBoundingClientRect();
    this.newGroupCardYOffset = elemRect.top - headerRect.height - this.additionalYOffsetFromHeader;
    this.stickTopOffset = headerRect.height + this.additionalYOffsetFromHeader;
  },
  computed: {
    ...mapGetters(['authenticationError', 'isLoading', 'isAuthenticated', 'user', 'profiles']),
    timeOfDay() {
      const currentHour = new Date().getHours();
      let timeOfDay;

      if (currentHour < 12) {
        timeOfDay = 'morning';
      } else if (currentHour < 18) {
        timeOfDay = 'afternoon';
      } else {
        timeOfDay = 'evening';
      }

      return timeOfDay;
    },
  },
  data() {
    return {
      groupMembers: [],
      breadcrumbs: [
        {
          label: 'My Groups',
          link: 'groups',
        },
      ],
      sortOptions: [
        {
          label: 'Academic',
          value: 1,
          iconImport: AcademicIcon,
          count: 0,
        },
        {
          label: 'Work',
          value: 4,
          iconImport: WorkIcon,
          count: 0,
        },
        {
          label: 'Family',
          value: 2,
          iconImport: HomeIcon,
          count: 0,
        },
        {
          label: 'Club',
          value: 5,
          iconImport: UsersIcon,
          count: 0,
        },
      ],
      filteredGroups: [],
      isNewGroupCardStick: false,
      stickTopOffset: 0,
      stickLeftOffset: 0,
      newGroupCardYOffset: 0,
      additionalYOffsetFromHeader: 30,
    };
  },
  methods: {
    ...mapActions(['fetchProfiles']),
    setGroupColorVars() {
      const { groups } = this.user;

      groups.forEach((group, index) => {
        cssVars({
          variables: {
            [`--group${index}`]: `rgba(${group.primaryColor})`,
          },
        });
      });
    },
    handleCreateGroup() {
      this.$router.push({
        name: 'create-group',
      });
    },
    handleRouteToGroup(groupSlug) {
      // Google Analytics Event
      this.$gtag.event('clicked_group', {
        event_category: 'groups_page',
        event_label: `${groupSlug} / Clicked group`,
      });

      this.$router.push({
        name: 'members',
        params: {
          group: groupSlug,
        },
      });
    },
    filterGroups(options) {
      if (this.user && this.user.groups) {
        this.filteredGroups = [];
        this.user.groups.forEach((group) => {
          if (options.length) {
            options.forEach((option) => {
              if (group.groupClassification) {
                if (group.groupClassification.id === option.value) {
                  this.filteredGroups.push(group);
                }
              }
            });
          } else {
            this.filteredGroups = this.user.groups;
          }
        });
      }
    },
    setSortOptionsCount(groups) {
      this.sortOptions.forEach((el) => {
        el.count = 0;
      });
      this.sortOptions.forEach((option) => {
        groups.forEach((group) => {
          if (group.groupClassification) {
            if (group.groupClassification.id === option.value) {
              option.count += 1;
            }
          }
        });
      });
    },
    handleScroll() {
      this.isNewGroupCardStick = document.documentElement.scrollTop > this.newGroupCardYOffset;
    },
    handleResize() {
      this.stickLeftOffset = this.$el.querySelector('.new-group-card').getBoundingClientRect().x;
    },
  },
  watch: {
    user(currentUser, prevUser) {
      if (!prevUser.groups) {
        this.setGroupColorVars();
      }

      if (currentUser.groups) {
        this.filteredGroups = this.user.groups;
        this.setSortOptionsCount(this.user.groups);
      }
    },
  },
};
</script>

<style lang="scss"></style>
