<template>
  <div>
    <h4 class="font-serif text-2_25xl sm:text-2_5xl lg:text-2_8xl font-normal mb-7 sm:mb-8 lg:mb-7">
      Where?
    </h4>
    <div>
      <div v-if="eventObject.eventTypeId === 1" class="flex flex-col">
        <div class="text-gray-800 font-semibold text-sm mb-2">Address or Place</div>
        <MapsAutocompleteInput
          id="address"
          :search-types="'establishment'"
          label="Start typing..."
          placeholder="Start typing..."
          class="w-80 sm:w-116"
          :value="eventObject.location"
          name="address"
          @onKeyEnter="$emit('pressEnter')"
          @updatePlaceAndAddress="handleUpdateFullAddress"
          allowCustomEntries
        >
          <template v-slot:icon>
            <LocationIcon class="event-where-icon w-5 h-5"></LocationIcon>
          </template>
        </MapsAutocompleteInput>
      </div>
      <div v-else class="flex flex-col">
        <div class="text-gray-800 font-semibold text-sm mb-2">URL</div>
        <TextInput
          label="Link"
          class="w-80 sm:w-116"
          v-model="eventObject.url"
          customWidth
          @onKeyEnter="$emit('pressEnter')"
        >
          <template v-slot:icon>
            <LinkIcon class="event-where-icon w-5 h-5"></LinkIcon>
          </template>
        </TextInput>
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from '@/components/TextInput.vue';
import MapsAutocompleteInput from '@/components/MapsAutocompleteInput.vue';
import LocationIcon from '@/assets/icon_location.svg';
import LinkIcon from '@/assets/icon_link.svg';
import { mapGetters } from 'vuex';

export default {
  name: 'EventWhere',
  components: {
    TextInput,
    MapsAutocompleteInput,
    LocationIcon,
    LinkIcon,
  },
  computed: {
    ...mapGetters(['globalErrors', 'isLoading']),
  },
  props: {
    eventObject: {
      type: Object,
    },
    pressEnter: {
      type: Function,
    },
  },
  methods: {
    handleUpdateFullAddress(address) {
      this.eventObject.location = address;
    },
  },
};
</script>

<style scoped lang="scss">
.event-where-icon circle,
.event-where-icon path {
  stroke: #808285;
}
</style>
