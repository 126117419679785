<template>
  <div>
    <svg viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.2463 4.29272H2.84215C2.05485 4.29272 1.41663 4.90056 1.41663 5.65036V12.4386C1.41663 13.1884 2.05485 13.7962 2.84215 13.7962H14.2463C15.0336 13.7962 15.6718 13.1884 15.6718 12.4386V5.65036C15.6718 4.90056 15.0336 4.29272 14.2463 4.29272Z"
        stroke="currentColor"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M1.41663 8.25269H15.6718" stroke="currentColor" stroke-width="1.2" />
      <path
        d="M11.7121 4.29282V2.70891C11.7121 2.28883 11.5452 1.88596 11.2482 1.58892C10.9512 1.29188 10.5483 1.125 10.1282 1.125H6.96038C6.5403 1.125 6.13742 1.29188 5.84038 1.58892C5.54334 1.88596 5.37646 2.28883 5.37646 2.70891V4.29282"
        stroke="currentColor"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>
<script>
export default {
  name: 'IconWork',
};
</script>
