<template>
  <div>
    <Toast v-if="showToast">Group is updated!</Toast>

    <div
      class="max-w-screen-xl mx-6 sm:mx-auto py-9 lg:py-12 sm:w-full border-t border-gray-400 sm:border-0"
    >
      <div class="flex space-between flex-col lg:flex-row">
        <div class="block lg:hidden w-full sm:w-320px">
          <Dropdown
            placeholder="Admin"
            :fullWidth="true"
            :options="adminList"
            class="admin-dropdown"
          >
            <div class="p-3">
              <router-link
                class="w-full animation-focus-default"
                :key="profile.id"
                v-for="(profile, index) in adminList"
                :to="{
                  name: 'profile',
                  params: {
                    group: groupSlug,
                    user: profile.slug,
                  },
                }"
              >
                <div
                  class="flex px-1 py-5 border-gray-300 group"
                  :class="{
                    'border-b': index !== adminList.length - 1,
                    'pt-1': index === 0,
                    'pb-2': index === adminList.length - 1,
                  }"
                >
                  <img
                    class="w-17 h-17 rounded mr-7"
                    :src="profile.profilePicture"
                    alt="profile_picture"
                  />
                  <div class="flex flex-col justify-center">
                    <div class="text-base font-semibold mb-1 group-hover:text-primary">
                      {{ profile.firstName }} {{ profile.lastName }}
                    </div>
                    <div class="flex items-center text-gray-700 text-small">
                      <LocationIcon class="location-icon w-3 h-3 mr-1" />
                      {{ getAdminLocation(profile) }}
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </Dropdown>
        </div>

        <!--            -->
        <!-- LEFT BLOCK -->
        <!--            -->

        <div class="flex flex-col w-full lg:w-2/3 mt-9 lg:mt-0 lg:mr-8">
          <!--             -->
          <!-- REVIEW MODE -->
          <!--             -->

          <div class="w-full" v-if="!inEditMode">
            <div class="flex flex-col bg-white rounded shadow p-6 lg:px-12 lg:py-8">
              <h1
                v-if="!viewingPastEvents"
                class="font-normal text-2xl w-full flex items-center justify-between"
              >
                {{ currentGroup.name }}

                <button
                  type="button"
                  @click="setEditMode"
                  v-if="!inEditMode"
                  class="edit-btn flex gap-1 text-sm group"
                >
                  <EditIcon
                    :class="{ 'default-color': isColorDefault }"
                    class="w-5 h-5 cursor-pointer animation-focus-default"
                  />
                  <span class="group-hover:text-orange-400">Edit</span>
                </button>
              </h1>
              <h6
                v-if="!viewingPastEvents && currentGroup.subtitle"
                class="text-gray-700 font-semibold pt-2 text-base"
              >
                {{ currentGroup.subtitle }}
              </h6>

              <VueEditor
                v-if="currentGroup.description && !viewingPastEvents"
                :editorOptions="{ modules: { toolbar: false } }"
                :disabled="true"
                class="disabled-text-editor text-base text-sans pt-5"
                v-model="currentGroup.description"
              />

              <div v-if="currentGroup.donationLink" class="website-link py-4">
                <a class="underline text-orange-400" :href="currentGroup.donationLink">
                  {{ currentGroup.donationLink }}
                </a>
              </div>
            </div>

            <div
              v-if="showFutureEvents(groupEvents) || group.fundraisers.length"
              class="w-full mt-8 sm:mt-9 mb-8 lg:my-12"
            >
              <div v-if="showFutureEvents(groupEvents)" class="preview-header-container">
                <h3>Events</h3>
                <button
                  @click="showCreateEventModal()"
                  class="hidden sm:flex items-center animation-focus-default"
                >
                  <PlusCircleIcon class="plus-circle-icon w-5 h-5 mr-2"></PlusCircleIcon>
                  <div class="text-sm font-semibold">Create a New Event</div>
                </button>
              </div>

              <div class="list-container w-full">
                <EventList
                  class="sm:pt-2"
                  :class="[{ 'sm:w-1/2 w-full': viewingPastEvents }]"
                  :pastEventsClick="pastEventsClick"
                  :showPastEvents="viewingPastEvents"
                  :backClick="pastEventsBackClick"
                  :showAddNewEvent="isAdmin"
                  :isPreview="true"
                />

                <!--                <div v-if="!showFutureEvents(groupEvents) && isAdmin" class="empty-card-group-admin">-->
                <!--                  <CalendarIcon class="empty-card-icon"></CalendarIcon>-->
                <!--                  <div class="text-sm text-gray-600">You have no events at the moment</div>-->
                <!--                </div>-->
              </div>

              <div
                class="list-container w-full mt-8 lg:mt-12"
                v-if="!viewingPastEvents && group.fundraisers.length"
              >
                <div class="preview-header-container">
                  <h3>Fundraisers</h3>
                  <button
                    type="button"
                    @click="inEditMode = true"
                    class="hidden sm:flex items-center animation-focus-default"
                  >
                    <PlusCircleIcon class="plus-circle-icon w-5 h-5 mr-2"></PlusCircleIcon>
                    <div class="text-sm font-semibold">Create Fundraiser</div>
                  </button>
                </div>

                <button
                  type="button"
                  @click="inEditMode = true"
                  class="sm:hidden flex items-center animation-focus-default mb-7"
                >
                  <PlusCircleIcon class="plus-circle-icon w-5 h-5 mr-2"></PlusCircleIcon>
                  <div class="text-sm font-semibold">Create Fundraiser</div>
                </button>

                <div v-for="(fundraiser, index) in group.fundraisers" :key="fundraiser.id">
                  <GoFundMeEmbed
                    :last="index === group.fundraisers.length - 1"
                    :url="fundraiser.url"
                    :is-preview="false"
                    @removeFundraiser="deleteFundraiserDialog(index)"
                  />
                </div>

                <!--                <div v-if="!group.fundraisers.length && isAdmin" class="empty-card-group-admin">-->
                <!--                  <HeartIcon class="empty-card-icon"></HeartIcon>-->
                <!--                  <div class="text-sm text-gray-600">You have no Fundraisers at the moment</div>-->
                <!--                </div>-->

                <!--                          -->
                <!-- DIALOG DELETE FUNDRAISER -->
                <!--                          -->

                <transition name="component-fade" mode="out-in">
                  <DialogDefault
                    v-if="showRemoveFundraiserDialog"
                    :icon="'delete'"
                    :header="getFundraiserDialogName()"
                    :text="getFundraiserDialogText()"
                    :applyBtnText="'Delete'"
                    @cancel="cancelRemovingFundraiser()"
                    @apply="removeFundraiser()"
                  />
                </transition>
              </div>
            </div>

            <div
              :class="{
                'border-t border-gray-400 pt-4':
                  showFutureEvents(groupEvents) || group.fundraisers.length,
              }"
              v-if="isAdmin && !viewingPastEvents"
            >
              <template v-if="showHint">
                <div class="flex flex-col sm:flex-row my-8 rounded bg-white">
                  <div
                    class="flex text-primary font-sans uppercase text-base px-6 pt-6 sm:py-6 sm:px-8 items-center tracking-widest"
                  >
                    Hint:
                  </div>
                  <h3
                    class="text-gray-800 font-sans text-base px-6 sm:pl-0 sm:pr-8 py-6 leading-relaxed"
                  >
                    Help other group members learn more about this group by filling out more
                    information. To add more details, click the <span class="font-bold">Edit</span>
                    button below.
                  </h3>
                </div>
              </template>
            </div>
          </div>

          <!--           -->
          <!-- EDIT MODE -->
          <!--           -->

          <div class="w-full" v-else-if="inEditMode">
            <EditGroupForm
              :currentGroup="currentGroup"
              :onSave="saveGroupChanges"
              :onCancel="exitEditMode"
              :editErrors="editErrors"
            />
          </div>
        </div>

        <!--             -->
        <!-- RIGHT BLOCK -->
        <!--             -->

        <div v-if="!viewingPastEvents" class="hidden lg:flex flex-col w-1/3">
          <div class="admins-container bg-white rounded shadow h-auto px-8 pt-6 pb-3">
            <h1 class="text-2xl mb-1">Admins</h1>

            <div class="flex flex-wrap">
              <router-link
                class="w-full animation-focus-default"
                :key="profile.id"
                v-for="(profile, index) in adminList"
                :to="{
                  name: 'profile',
                  params: {
                    group: groupSlug,
                    user: profile.slug,
                  },
                }"
              >
                <div
                  class="flex px-1 py-5 border-gray-300 group"
                  :class="{ 'border-b': index !== adminList.length - 1 }"
                >
                  <img
                    class="w-17 h-17 rounded mr-7"
                    :src="profile.profilePicture"
                    alt="profile_picture"
                  />
                  <div class="flex flex-col justify-center">
                    <div class="text-base font-semibold mb-1 group-hover:text-primary">
                      {{ profile.firstName }} {{ profile.lastName }}
                    </div>
                    <div class="flex items-center text-gray-700 text-small">
                      <LocationIcon class="location-icon w-3 h-3 mr-1"></LocationIcon>
                      {{ getAdminLocation(profile) }}
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor';
import { DateTime } from 'luxon';
import { mapActions, mapGetters } from 'vuex';
import Toast from '@/components/Toast.vue';
import EditGroupForm from '@/components/EditGroupForm.vue';
import EventList from '@/components/events/EventList.vue';
import Dropdown from '@/components/Dropdown.vue';
import GoFundMeEmbed from '@/components/fundraisers/GoFundMeEmbed.vue';
import LocationIcon from '@/assets/icon_location.svg';
import PlusCircleIcon from '@/assets/icon_plus_circled.svg';
import DialogDefault from '@/components/DialogDefault.vue';

import { DEFAULT_COLOR } from '@/components/constants/groupColors';
import EditIcon from '@/assets/icon_edit.svg';
import { getISODateToFormatted, getFormattedDateToISO } from '../../filters';

export default {
  name: 'GroupDescription',
  components: {
    EditIcon,
    DialogDefault,
    EditGroupForm,
    Toast,
    VueEditor,
    EventList,
    Dropdown,
    GoFundMeEmbed,
    LocationIcon,
    PlusCircleIcon,
  },
  computed: {
    ...mapGetters(['isLoading', 'group', 'profiles', 'user', 'isLoading', 'groupEvents']),
    isAdmin() {
      const currentUser = this.profiles.slice().filter((member) => member.id === this.user.id)[0];
      return (
        (currentUser && currentUser.isGroupAdmin) ||
        (this.user && this.user.user && this.user.user.isSuperuser)
      );
    },
    isColorDefault() {
      return this.group.primaryColor === this.defaultColor;
    },
    showHint() {
      return (
        !this.isLoading && Object.keys(this.currentGroup).length && !this.currentGroup.description
      );
    },
  },
  data() {
    return {
      showToast: false,
      currentGroup: {},
      defaultColor: DEFAULT_COLOR,
      adminList: [],
      groupSlug: '',
      inEditMode: false,
      editErrors: [],
      days: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
        26, 27, 28, 29, 30, 31,
      ],
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      viewingPastEvents: false,
      selectedAdmin: {},
      showRemoveFundraiserDialog: false,
      currentFundraiser: {},
    };
  },
  created() {
    this.groupSlug = this.$route.params.group;
    this.setCurrentGroup();
    this.getAdminsFromList();
    this.fetchEventList({ groupSlug: this.$route.params.group });
  },
  methods: {
    ...mapActions(['fetchGroup', 'updateGroup', 'fetchProfiles', 'fetchCurrentUser', 'fetchEventList']),
    getEventText() {
      return 'Next event: ';
    },
    showCreateEventModal() {
      // Google Analytics Event
      this.$gtag.event('create_event', {
        event_category: 'events',
        event_label: `${this.$route.params.group} / User clicked create event`,
      });
      this.$router.push({
        name: 'create-event',
      });
    },
    setCurrentGroup(group = null) {
      const groupToSet = group || this.group;
      if (groupToSet.eventDate) {
        this.currentGroup = {
          ...groupToSet,
          eventDate: getISODateToFormatted(groupToSet.eventDate),
        };
      } else {
        this.currentGroup = { ...groupToSet };
      }
    },
    getEventDate() {
      const eventName =
        this.currentGroup && this.currentGroup.eventName ? this.currentGroup.eventName : 'Event';
      const eventDate =
        this.currentGroup && this.currentGroup.eventDate ? this.currentGroup.eventDate : 'Date TBD';
      return `${eventName}  -  ${this.readableDate(eventDate)}`;
    },
    readableDate(date) {
      const segments = date.split('-');
      const year = segments[2];
      const monthNumber = Number(segments[0]);
      const dayNumber = Number(segments[1]);
      const month = this.months[monthNumber - 1];
      const day = this.days[dayNumber - 1];
      return `${month} ${day}, ${year}`;
    },
    getAdminsFromList() {
      if (this.profiles.length) {
        this.adminList = this.profiles.slice().filter((member) => member.isGroupAdmin === true);
      }
    },
    getAdminLocation(profile) {
      return profile.city ? `${profile.city}, ${profile.state}` : 'City N/A';
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    saveGroupChanges(group, groupSlug) {
      if (group && groupSlug) {
        const formattedGroup = {
          ...group,
        };
        if (group.eventDate) {
          formattedGroup.eventDate = getFormattedDateToISO(group.eventDate);
        }
        if (group.groupClassification && typeof group.groupClassification === 'object') {
          formattedGroup.groupClassification = group.groupClassification.id;
        }
        this.showToast = false; // reset
        this.updateGroup({
          groupSlug,
          group: formattedGroup,
        }).then((response) => {
          if (response.success) {
            this.fetchGroup({ groupSlug });
            this.showToast = true;
            this.inEditMode = false;
            this.setCurrentGroup(response.group);
            this.scrollToTop();
          } else if (response.errors) {
            this.editErrors = response.errors;
          }
        });
      }
    },
    setEditMode() {
      this.setCurrentGroup();
      this.scrollToTop();
      this.inEditMode = true;
    },
    exitEditMode() {
      this.scrollToTop();
      this.inEditMode = false;
    },
    pastEventsClick() {
      this.viewingPastEvents = true;
    },
    pastEventsBackClick() {
      this.viewingPastEvents = false;
    },
    selectAdmin(admin) {
      this.selectedAdmin = admin;
    },
    deleteFundraiserDialog(index) {
      this.currentFundraiser = this.group.fundraisers[index];
      this.showRemoveFundraiserDialog = true;
    },
    cancelRemovingFundraiser() {
      this.currentFundraiser = {};
      this.closeRemovingFundraiserDialog();
    },
    removeFundraiser() {
      const index = this.group.fundraisers.indexOf(this.currentFundraiser);
      this.group.fundraisers[index].isDeleted = true;
      this.saveGroupChanges(this.group, this.group.slug);
      this.closeRemovingFundraiserDialog();
    },
    closeRemovingFundraiserDialog() {
      this.showRemoveFundraiserDialog = false;
    },
    getFundraiserDialogName() {
      return 'Delete fundraiser';
    },
    getFundraiserDialogText() {
      return 'Are you sure you want to delete this fundraiser?';
    },
    showFutureEvents(events) {
      const futureEvents = events.filter((x) => {
        return DateTime.fromISO(x.startDatetime) > DateTime.now().minus({ days: 1 });
      });

      return !!(futureEvents && futureEvents.length);
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-2 {
  flex: 2;
}

.shadow {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
}
</style>

<style lang="scss">
.edit-btn:hover {
  &.default-color path {
    @apply stroke-defaultInteractive;
  }

  path {
    @apply stroke-primary;
  }
}

.admin-dropdown {
  height: 60px;

  .trigger .placeholder {
    @apply font-serif font-normal text-0_8xl;
  }

  .menu-container {
    margin-top: 4px;
    max-height: 364px;

    .menu {
    }
  }
}

.location-icon path {
  stroke: #4c4e50;
}

#quill-container.ql-container.ql-snow {
  @apply border-none font-sans;
}

.quillWrapper .ql-toolbar.ql-snow {
  @apply border-none;
}

.disabled-text-editor #quill-container .ql-editor {
  @apply p-0;
  min-height: auto;

  a {
    @apply text-primary;
  }
}
</style>
