<template>
  <div class="relative max-h-[75vh] w-full">
    <div
      class="flex gap-1 lg:gap-0.5 pb-4 lg:pb-0 static justify-stretch lg:justify-start lg:absolute lg:top-[-45px] lg:right-8"
    >
      <button
        type="button"
        @click.stop="downloadFile"
        class="flex w-1/4 justify-center lg:justify-start lg:w-auto items-center pl-2 pr-4 py-2 text-gray-800 text-sm font-semibold rounded transition hover:bg-gray-100 bg-gray-100 lg:bg-white"
      >
        <span class="p-2 rounded-[40px]">
          <Download />
        </span>
        <span class="hidden sm:block">Download</span>
      </button>

      <button
        type="button"
        class="flex w-1/4 justify-center lg:justify-start lg:w-auto items-center pl-2 pr-4 py-2 text-gray-800 text-sm font-semibold rounded transition hover:bg-gray-100 bg-gray-100 lg:bg-white"
        @click.stop="move"
      >
        <span class="p-2 rounded-[40px]">
          <MoveTo />
        </span>
        <span class="hidden sm:block">Move to</span>
      </button>

      <button
        type="button"
        class="flex w-1/4 justify-center lg:justify-start lg:w-auto items-center pl-2 pr-4 py-2 text-gray-800 text-sm font-semibold rounded transition hover:bg-gray-100 bg-gray-100 lg:bg-white"
        @click.stop="deleteFile"
      >
        <span class="p-2 rounded-[40px]">
          <Delete />
        </span>
        <span class="hidden sm:block">Delete</span>
      </button>
      <button
        type="button"
        @click.stop="edit"
        class="flex w-1/4 justify-center lg:justify-start lg:w-auto items-center pl-2 pr-4 py-2 text-gray-800 text-sm font-semibold rounded transition hover:bg-gray-100 bg-gray-100 lg:bg-white"
      >
        <span class="p-2 rounded-[40px]">
          <Edit />
        </span>
        <span class="hidden sm:block">Edit</span>
      </button>
    </div>
    <v-zoomer
      ref="zoomer"
      class="rounded w-full max-h-[520px]"
      :aspect-ratio="imageAspectRatio"
      :max-scale="10"
      :zooming-elastic="false"
      :zoomed.sync="zoomed"
    >
      <img :alt="media.name" :src="media.file" class="mx-auto max-h-[520px]" />
    </v-zoomer>
    <p class="pt-4 text-gray-700 text-base">
      {{ media.description }}
    </p>
    <div>
      <div class="controls w-full flex justify-between items-center gap-2 pt-6">
        <Button
          :primary-full-size="false"
          compact
          class="cancel-btn w-[130px] md:w-[160px] h-10 mr-[6px]"
          type="text"
          @onClick="$emit('previous', media)"
        >
          <span class="flex items-center text-sm text-gray-800 font-semibold">
            <Chevron class="w-4 h-4 px-0.5 rotate-90" />
            Previous
          </span>
        </Button>

        <div class="hidden sm:block">
          <button
            v-tooltip="'Zoom Out'"
            type="button"
            class="rounded-sm p-3 bg-gray-100 hover:bg-white border border-gray-100"
            @click="$refs.zoomer.zoomOut()"
          >
            <Minus />
          </button>
          <button
            v-tooltip="'Zoom Reset'"
            type="button"
            class="rounded-sm p-3 bg-gray-100 hover:bg-white border border-gray-100"
            @click="$refs.zoomer.reset()"
          >
            <ZoomReset />
          </button>
          <button
            v-tooltip="'Zoom In'"
            type="button"
            class="rounded-sm p-3 bg-gray-100 hover:bg-white border border-gray-100"
            @click="$refs.zoomer.zoomIn()"
          >
            <Plus />
          </button>
        </div>

        <Button
          class="save-btn w-[160px] h-10 ml-[6px]"
          type="text"
          @onClick="$emit('next', media)"
          :primary-full-size="false"
          compact
        >
          <span class="flex items-center text-sm text-gray-800 font-semibold">
            Next
            <Chevron class="w-4 h-4 px-0.5 -rotate-90" />
          </span>
        </Button>
      </div>
    </div>
  </div>
</template>
<script>
import Download from '@/assets/download.svg';
import MoveTo from '@/assets/move-to.svg';
import Delete from '@/assets/delete.svg';
import Button from '@/components/Button.vue';
import Edit from '@/assets/edit.svg';
import Chevron from '@/assets/chevron.svg';
import Minus from '@/assets/icon_minus_simple.svg';
import ZoomReset from '@/assets/icon_zoom_out.svg';
import Plus from '@/assets/icon_plus_simple.svg';
import { FileUtil } from '@/utils/file.util';

export default {
  name: 'PreviewMedia',
  components: {
    Chevron,
    ZoomReset,
    Minus,
    Plus,
    Edit,
    Button,
    Delete,
    MoveTo,
    Download,
  },
  props: {
    media: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      zoomed: false,
      imageAspectRatio: 1,
    };
  },

  methods: {
    edit() {
      this.$emit('edit', this.media);
    },
    downloadFile() {
      FileUtil.downloadFile(this.media.file, this.media.name);
    },
    deleteFile() {
      this.$emit('delete', this.media);
    },
    move() {
      this.$emit('move', this.media);
    },
  },
};
</script>
