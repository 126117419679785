<template>
  <div class="w-full px-6 sm:p-0">
    <h4 class="font-serif text-center sm:text-left text-2_25xl sm:text-2_5xl lg:text-2_8xl font-normal mb-6 sm:mb-16 lg:mb-12">
      What Type of event will this be?
    </h4>
    <div class="flex flex-col sm:flex-row justify-center">
      <Button type="primary"
              class="mb-4 sm:mb-0 mx-2 sm:mx-4 lg:mx-3 sm:min-w-225"
              :primary-full-size="false"
              @onClick="chooseEventType(1)"
      >
        <div class="text-0_8xl text-white my-2 font-normal sm:font-semibold">
          In-Person
        </div>
      </Button>
      <Button type="primary"
              class="mx-2 sm:mx-4 lg:mx-3 min-w-225"
              :primary-full-size="false"
              @onClick="chooseEventType(2)"
      >
        <div class="text-0_8xl text-white my-2 font-normal sm:font-semibold">
          Virtual
        </div>
      </Button>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue';

export default {
  name: 'EventType',
  components: {
    Button,
  },
  props: {
    eventObject: {
      type: Object,
    },
  },
  methods: {
    chooseEventType(type) {
      this.eventObject.eventTypeId = type;
      this.$emit('chooseEvent');
    },
  },
};
</script>

<style scoped lang="scss">
label {
  font-weight: 900;
}
</style>
