<template>
  <div class="w-full mb-6">
    <h4 class="font-serif text-2_25xl sm:text-2_5xl lg:text-2_8xl font-normal mb-6 sm:mb-8 lg:mb-7">
      Name the event
    </h4>
    <div class="flex justify-center">
      <TextInput
        placeholder="ex. Bloom High 25th Reunion"
        class="w-80 sm:w-116"
        v-model="eventObject.name"
        customWidth
        :invalid="invalidInput('Event Name')"
        :error-message="getErrorMessage()"
        @input="updateName"
        @onKeyEnter="$emit('pressEnter')"
      />
    </div>
  </div>
</template>

<script>
import TextInput from '@/components/TextInput.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'EventName',
  components: {
    TextInput,
  },
  computed: {
    ...mapGetters(['globalErrors', 'isLoading']),
  },
  props: {
    eventObject: {
      type: Object,
    },
    pressEnter: {
      type: Function,
    },
  },
  methods: {
    updateName(name) {
      this.eventObject.name = name;

      if (this.invalidInput('Event Name')) {
        const nameIndex = this.globalErrors.findIndex((el) => el.fieldName === 'Event Name');
        this.globalErrors.splice(nameIndex, 1);
      }
    },
    getErrorMessage() {
      const currentError = this.globalErrors.find((el) => el.fieldName === 'Event Name');
      return currentError ? currentError.message : '';
    },
    invalidInput(name) {
      return this.globalErrors.some((el) => el.fieldName === name);
    },
  },
};
</script>

<style scoped lang="scss"></style>
