<template>
  <div
    class="bg-white shadow hover:shadow-md rounded cursor-pointer animation-focus-default"
    @click="goToEvent"
  >
    <div
      v-if="!isPreview"
      class="flex flex-col-reverse sm:flex-row justify-between overflow-hidden"
    >
      <div class="p-6 sm:p-8">

        <h2 class="pb-5 truncate">{{ groupEvent.name }}</h2>

        <div class="flex flex-col sm:flex-row text-sm">
          <div class="text-base w-max flex px-4 py-2 rounded-2xl bg-primary-transparent bg-opacity-10">
          {{ eventDateFormatted }}
        </div>
        <div class="w-max flex px-4 py-2 rounded-2xl bg-primary-transparent bg-opacity-10 sm:ml-3">
            <UsersIcon class="w-4 h-4 mr-2 text-gray-800 mt-1"></UsersIcon>
            <span class="text-gray-800 text-sm font-semibold leading-6"
              >{{ groupEvent.amountAttending }} Attending</span
            >
          </div>
          <!-- <div
            class="w-max flex mb-4 sm:mb-0 px-4 py-2 mr-4 rounded-2xl bg-primary-transparent bg-opacity-10"
          >
            <ClockIcon class="w-4 h-4 mr-2 text-gray-800"></ClockIcon>
            <span class="text-gray-800 text-sm font-semibold">{{ eventTime }}</span>
          </div> -->

        </div>
      </div>

      <div
        v-if="groupEvent.photo"
        class="event-image-container rounded-t sm:rounded-t-none sm:rounded-r overflow-hidden"
      >
        <img
          v-if="groupEvent.photo"
          class="event-image max-h-full max-w-none w-full"
          :src="groupEvent.photo"
          :alt="groupEvent.photo"
        />
      </div>
    </div>

    <div v-if="isPreview" class="preview-container flex flex-col lg:flex-row text-primary">


      <div class="flex flex-col justify-center items-start flex-grow px-6 pb-6 p-6">
        <h5 class="pb-2 sm:pb-1 font-semibold">{{ groupEvent.name }}</h5>
        <div class="flex flex-col sm:flex-row text-sm">
          <div class="flex items-center mr-4">
            <span class="text-gray-800">{{ eventDateFormatted }}</span>
          </div>
          <div class="flex items-center mt-2 sm:mt-0">
            <UsersIcon class="w-4 h-4 mr-2 text-gray-800"></UsersIcon>
            <span class="text-gray-800">{{ groupEvent.amountAttending }} Attending</span>
          </div>
        </div>
      </div>

      <div class="hidden lg:block pr-7 m-auto">
        <ArrowRightIcon class="arrow-icon w-5"></ArrowRightIcon>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { DateTime } from 'luxon';
import ArrowRightIcon from '@/assets/icon_arrow_right.svg';
import UsersIcon from '@/assets/icon_users.svg';

export default {
  name: 'EventSummary',
  components: {
    ArrowRightIcon,
    UsersIcon,
  },
  methods: {
    goToEvent() {
      this.$router.replace({
        name: 'event',
        params: { eventId: this.groupEvent.id },
      });
    },
  },
  computed: {
    ...mapGetters(['group']),
    startEventMonth() {
      if (!this.groupEvent.startDatetime) {
        return 'TBD';
      }
      const date = DateTime.fromISO(this.groupEvent.startDatetime);

      return date.toFormat('LLLL');
    },
    startEventDay() {
      const date = DateTime.fromISO(this.groupEvent.startDatetime);

      if (!this.groupEvent.startDatetime) {
        return 'TBD';
      }

      return date.day;
    },
    startEventYear() {
      if (!this.groupEvent.startDatetime) {
        return 'TBD';
      }

      const date = DateTime.fromISO(this.groupEvent.startDatetime);
      return date.year;
    },
    endEventYear() {
      if (!this.groupEvent.endDatetime) {
        return 'TBD';
      }

      const date = DateTime.fromISO(this.groupEvent.endDatetime);
      return date.year;
    },
    startEventTime() {
      let startDate = 'TBD';
      if (this.groupEvent.startDatetime) {
        startDate = DateTime.fromISO(this.groupEvent.startDatetime).toFormat('t');
      }

      return `${startDate}`;
    },
    endEventMonth() {
      if (!this.groupEvent.endDatetime) {
        return 'TBD';
      }
      const date = DateTime.fromISO(this.groupEvent.endDatetime);

      return date.toFormat('LLLL');
    },
    endEventDay() {
      const date = DateTime.fromISO(this.groupEvent.endDatetime);

      if (!this.groupEvent.endDatetime) {
        return 'TBD';
      }

      return date.day;
    },
    endEventTime() {
      let endDate = 'TBD';
      if (this.groupEvent.endDatetime) {
        endDate = DateTime.fromISO(this.groupEvent.endDatetime).toFormat('t');
      }

      return `${endDate}`;
    },
    eventDateFormatted() {
      if (this.startEventMonth === this.endEventMonth && this.startEventDay === this.endEventDay) {
        return `${this.startEventMonth} ${this.startEventDay}, ${this.startEventYear}, ${this.startEventTime} - ${this.endEventTime}`;
      }

      return `${this.startEventMonth} ${this.startEventDay}, ${this.startEventYear}, ${this.startEventTime} - ${this.endEventMonth} ${this.endEventDay}, ${this.endEventYear}, ${this.endEventTime}`;
    },
  },
  props: {
    groupEvent: {
      type: Object,
      default: () => {},
    },
    isPreview: {
      type: Boolean,
      default: () => true,
    },
  },
};
</script>

<style lang="scss">
.preview-container:hover {
  svg.arrow-icon path {
    @apply fill-primary;
  }
}

svg.arrow-icon {
  path {
    fill: #e9ebef;
  }
}

.event-image-container {
  width: 250px;
  min-width: 250px;
  height: 192px;
  position: relative;

  .event-image {
    width: 520px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media all and (max-width: 576px) {
  .event-image-container {
    width: 100%;
    min-width: 100%;
    height: 140px;
    position: relative;

    .event-image {
      position: absolute;
      width: 400px;
      top: 50%;
      transform: translate(-50%, -50%);
      left: 50%;
    }
  }
}
</style>
