var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative",class:[{ 'w-full': !_vm.customWidth }]},[_c('span',{staticClass:"symbol-prefix absolute text-sm"},[_vm._v(_vm._s(_vm.symbolPrefix))]),_c('input',{ref:"input",staticClass:"input bg-white rounded shadow text-sm placeholder-gray-500 border border-gray-300 focus:outline-none transition-colors duration-200 ease py-2 z-50 w-full",class:[
      { 'input--filled': _vm.value && _vm.value.length > 0 },
      { 'border-red-600 border-2': _vm.invalid },
      { disabled: _vm.disabled },
      { 'pl-10 pr-2': _vm.hasIcon && !_vm.hasEndIcon },
      { 'px-4 py-3': !_vm.hasIcon && !_vm.hasEndIcon },
      { 'pl-7 pr-4': !_vm.hasIcon && !_vm.hasEndIcon && _vm.symbolPrefix },
      { 'pl-10 pr-10': _vm.hasIcon && _vm.hasEndIcon },
      { 'pl-2 pr-10': !_vm.hasIcon && _vm.hasEndIcon },
    ],attrs:{"autocomplete":"disabled","autocorrect":"false","spellcheck":"false","id":_vm.id,"name":_vm.name,"type":_vm.type,"placeholder":_vm.placeholder || (_vm.focused && _vm.focusedPlaceholder),"maxlength":_vm.maxLength,"disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.updateValue()},"focus":function($event){_vm.focused = true},"blur":function($event){_vm.focused = false},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleEnterKey()}}}),(_vm.hasIcon)?_c('span',{staticClass:"icon-wrapper absolute top-0 left-0 h-full flex items-center ml-2 text-gray-400 transition-colors duration-200 ease-out pointer-events-none px-1"},[_vm._t("icon")],2):_vm._e(),(_vm.hasEndIcon)?_c('span',{staticClass:"icon-wrapper absolute top-0 right-0 h-full flex items-center justify-end pr-2 text-gray-400 transition-colors duration-200 ease-out px-1"},[_vm._t("endIcon")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }