import { ProfileRecord } from '@/records/profile.record';

export class FundraiserRecord {
  constructor(record = {}) {
    // eslint-disable-next-line no-underscore-dangle
    this._record = record;
    this.id = record.id || '';
    this.isDeleted = record.isDeleted || false;
    this.url = record.url || '';
    this.createdBy = record.createdBy ? new ProfileRecord(record.createdBy) : '';
    this.group = record.group || null;
  }
}
