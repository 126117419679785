import { api } from '@/store';
import { generateUUID } from '@/helpers';

export async function createMessageService(groupSlug, content, photo, postId) {
  const token = localStorage.getItem('access-token');
  const baseHeader = { Authorization: `Bearer ${token}` };
  const formData = new FormData();
  formData.append('content', content);
  if (photo) {
    formData.append('photo', photo, `feedPhoto_${generateUUID()}.jpg`);
  }
  if (postId) {
    formData.append('parent_post', postId);
  }
  try {
    const response = await api.post(`api/v1/groups/${groupSlug}/feed/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        ...baseHeader,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getMessagesService(groupSlug) {
  const token = localStorage.getItem('access-token');
  const baseHeader = { Authorization: `Bearer ${token}` };
  try {
    const response = await api.get(`api/v1/groups/${groupSlug}/feed/`, {
      headers: {
        ...baseHeader,
      },
    });
    return response.data.results;
  } catch (e) {
    console.error(e);
  }
}

export async function deletePostService(groupSlug, postId) {
  const token = localStorage.getItem('access-token');
  const baseHeader = { Authorization: `Bearer ${token}` };
  await api.delete(`api/v1/groups/${groupSlug}/feed/${postId}/`, {
    headers: {
      ...baseHeader,
    },
  });
}

export async function likePostService(groupSlug, postId) {
  const token = localStorage.getItem('access-token');
  const baseHeader = { Authorization: `Bearer ${token}` };
  await api.post(
    `api/v1/groups/${groupSlug}/group-discussion/${postId}/like`,
    {},
    {
      headers: {
        ...baseHeader,
      },
    },
  );
}

export async function dislikePostService(groupSlug, postId) {
  const token = localStorage.getItem('access-token');
  const baseHeader = { Authorization: `Bearer ${token}` };
  await api.delete(`api/v1/groups/${groupSlug}/group-discussion/${postId}/like`, {
    headers: {
      ...baseHeader,
    },
  });
}

export async function configPostService(config, groupSlug, postId) {
  const token = localStorage.getItem('access-token');
  const baseHeader = { Authorization: `Bearer ${token}` };
  await api.post(`api/v1/groups/${groupSlug}/group-discussion/${postId}/config`, config, {
    headers: {
      ...baseHeader,
    },
  });
}
