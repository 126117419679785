<template>
  <router-link
    class="w-full animation-focus-default"
    :to="{
      name: 'profile',
      params: {
        group: groupSlug,
        user: profile.slug,
      },
    }"
  >
    <div
      class="flex items-center justify-between p-2 mb-1 rounded border-gray-300 group hover:bg-gray-100"
    >
      <div class="flex">
        <img
          v-if="profile.profilePicture"
          class="w-12 md:w-16 h-12 md:h-16 rounded mr-4"
          :src="profile.profilePicture"
          alt="profile_picture"
        />
        <img
          v-else
          src="@/assets/profile_photo_outline.png"
          class="w-12 md:w-16 h-12 md:h-16 rounded mr-4"
          alt="default_picture"
        />
        <div class="flex flex-col justify-center">
          <div class="text-base font-semibold text-gray-900 mb-1">
            {{ profile.firstName }} {{ profile.lastName }}
          </div>
          <div class="flex items-center text-gray-700 text-small">
            <LocationIcon class="location-icon w-3 h-3 mr-1" />
            {{ location }}
          </div>
        </div>
      </div>
      <div
        v-if="profile.isGroupAdmin"
        class="flex justify-center items-center rounded px-2 py-0.5 text-gray-800 text-xs bg-gray-200 max-h-[21px] group-hover:bg-white group-hover:text-gray-900"
      >
        Admin
      </div>
    </div>
  </router-link>
</template>
<script>
import LocationIcon from '@/assets/icon_location.svg';
import { mapGetters } from 'vuex';

export default {
  name: 'Member',
  components: { LocationIcon },
  props: {
    profile: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      adminList: [],
      groupSlug: '',
    };
  },
  computed: {
    ...mapGetters(['group', 'profiles', 'user']),
    location() {
      return this.profile.city ? `${this.profile.city}, ${this.profile.state}` : 'City N/A';
    },
  },
  created() {
    this.groupSlug = this.$route.params.group;
  },
};
</script>
