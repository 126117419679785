<template>
  <div class="mx-auto px-0 max-w-screen-xl flex sm:mt-8 flex-col">
    <Toast v-if="isShowToast">Custom field saved!</Toast>
    <div class="w-full">
      <div>
        <div class="flex flex-col md:flex-row mb-4 md:mb-0 justify-between">
          <div>
            <h3 class="font-serif text-gray-900 text-lg font-normal">Profile Questions</h3>

            <p class="text-gray-700 mt-2 mb-6 text-sm">
              Choose the questions group members will see on their profile and/or Yearbook.
            </p>
          </div>

          <Button
            :primary-full-size="false"
            type="secondary"
            @onClick="showFormAddEditFieldModal"
            custom-class="h-[40px] w-full sm:max-w-[220px]"
          >
            <template v-slot:icon>
              <IconCircle class="w-[16px]" />
            </template>
            <span class="text-orange-400 text-center text-sm font-semibold leading-[140%]">
              Add Profile Question
            </span>
          </Button>
        </div>

        <CustomFieldItem v-for="field in profileFields" :profile-field="field" :key="field.slug" />

        <hr class="my-6" />

        <StandardProfileFields />
      </div>
    </div>
    <transition name="component-fade" mode="out-in">
      <DialogDefault
        v-if="isShowFormAddEditFieldModal"
        header="New Profile Question"
        is-show-close-btn
        @cancel="hideFormAddEditFieldModal"
        :is-show-control-buttons="false"
        scheme="secondary"
        container-class="sm:w-[600px]"
      >
        <FormAddEditField @submit="addNewField" @cancel="hideFormAddEditFieldModal" />
      </DialogDefault>
    </transition>
  </div>
</template>

<script>
import StandardProfileFields from '@/components/StandardProfileFields.vue';
import DialogDefault from '@/components/DialogDefault.vue';
import FormAddEditField from '@/components/forms/FormAddEditField.vue';
import Toast from '@/components/Toast.vue';
import { mapActions, mapGetters } from 'vuex';
import Button from '@/components/Button.vue';
import CustomFieldItem from '@/components/CustomFieldItem.vue';
import IconCircle from '@/assets/icon_plus_circled.svg';

export default {
  name: 'ProfileFields',
  components: {
    CustomFieldItem,
    IconCircle,
    Button,
    Toast,
    FormAddEditField,
    DialogDefault,
    StandardProfileFields,
  },
  data() {
    return {
      isShowToast: false,
      isShowFormAddEditFieldModal: false,
    };
  },

  computed: {
    ...mapGetters(['group', 'profileFields']),
  },

  created() {
    this.fetchProfileFields({ groupSlug: this.group.slug });
  },

  methods: {
    ...mapActions(['createProfileField', 'fetchProfileFields']),

    async addNewField(value) {
      try {
        await this.createProfileField({
          groupSlug: this.group.slug,
          body: value,
        });
        this.hideFormAddEditFieldModal();
        await this.fetchProfileFields({ groupSlug: this.group.slug });
      } catch (e) {
        console.log(e);
      }
    },

    showFormAddEditFieldModal() {
      this.isShowFormAddEditFieldModal = true;
    },

    hideFormAddEditFieldModal() {
      this.isShowFormAddEditFieldModal = false;
    },
  },
};
</script>
