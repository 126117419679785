<template>
  <div v-if="!this.memberOfGroup"
        class="w-full px-8 my-8 md:px-0 md:w-1/2 md:mx-auto lg:w-2/5">
    <h1 class="font-serif text-primary text-3xl mb-4">Join this group?</h1>
    <p class="text-lg text-gray-600 mb-4">
      Your profile will be visible to group members. If you update your profile, it will
      be updated for all groups your a member of.
    </p>
    <div class="flex flex-col sm:flex-row">
      <Button type="primary"
              :loading="isLoading"
              @onClick="handleJoin()"
              class="mb-4 sm:mb-0 sm:mr-4">Confirm & Join Group</Button>
      <Button type="text" @onClick="handleCancel()">Cancel</Button>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Button from '@/components/Button.vue';

export default {
  name: 'JoinGroup',
  components: {
    Button,
  },
  created() {
    const token = localStorage.getItem('access-token');
    if (token) {
      this.fetchCurrentUser().then(() => {
        // if there's no profile picture, App.vue handles the redirect
        if (this.user && this.memberOfGroup) {
          this.$router.push({
            name: 'members',
            params: {
              group: this.$route.params.group,
            },
          });
        }
      });
    }
  },
  computed: {
    ...mapGetters(['user', 'group', 'isLoading']),
    memberOfGroup() {
      return this.user &&
        this.user.groups &&
        this.user.groups.some((group) => group.slug === this.$route.params.group);
    },
  },
  data() {
    return {
      newUser: {
        isAttendingNextEvent: 'false',
      },
    };
  },
  methods: {
    ...mapActions(['fetchCurrentUser', 'joinGroup']),
    handleCancel() {
      this.$router.push({
        name: 'members',
        params: {
          group: this.$route.params.group,
        },
      });
    },
    handleJoin() {
      this.joinGroup({
        groupSlug: this.$route.params.group,
        profileSlug: this.user.slug,
        body: {
          isAttendingNextEvent: this.isAttendingNextEvent === 'true',
        },
      })
        .then((response) => {
          if (response.success) {
            this.fetchCurrentUser()
              .then(() => {
                this.$router.push({
                  name: 'members',
                  params: {
                    group: this.$route.params.group,
                  },
                });
              });
          }
        });
    },
  },
};
</script>
<style scoped>
</style>
