<template>
  <div>
    <div class="mb-8">
      <h1 class="font-serif font-bold text-3xl text-gray-900 mb-2">
        Create your Group
      </h1>
      <p class="mb-3 text-sm sm:text-base">
        Choose a group name that will help others know to join (e.g. Georgetown
        University - Class of 1990).
        <br /> You can add more information once you've created the group.
      </p>
    </div>
    <div class="flex-col mb-8">
      <p class="font-bold text-xl text-gray-900 mb-3">Name your group:</p>
      <TextInput
        label="Avon High School - Class of 2001"
        v-model="group.name"
        name="link"
        :invalid="invalidFields.includes('name')"
      />
    </div>
    <div class="flex-col mb-8">
      <p class="font-bold text-xl text-gray-900 mb-3">How would you classify this group?</p>
      <div class="flex-col">
        <RadioButtonStyled
          short
          id="radio1"
          label="Academic"
          value="1"
          v-model="group.groupClassification"
          @change="chooseGroupType"
          class="mr-2 mb-2 sm:mb-6"
        />
        <RadioButtonStyled
          short
          id="radio2"
          label="Family"
          @change="chooseGroupType"
          value="2"
          v-model="group.groupClassification"
          class="mr-2 mb-2 sm:mb-6"
        />
        <RadioButtonStyled
          short
          id="radio4"
          label="Work"
          @change="chooseGroupType"
          value="4"
          v-model="group.groupClassification"
          class="mr-2 mb-2 sm:mb-6"
        />
        <RadioButtonStyled
          short
          id="radio5"
          label="Club"
          @change="chooseGroupType"
          value="5"
          class="mb-2 sm:mb-6"
          v-model="group.groupClassification"
        />
        <RadioButtonStyled
          short
          id="radio6"
          label="Faith"
          @change="chooseGroupType"
          value="6"
          class="mb-2 sm:mb-6"
          v-model="group.groupClassification"
        />
      </div>
    </div>
    <div class="flex-col mb-8">
      <p class="font-bold text-xl text-gray-900 mb-3">
        What is this group about? Provide a short description
      </p>
      <VueEditor
        ref="description"
        class="bg-white shadow-drop text-lg
              text-gray-900 font-sans rounded
              mb-4 md:w-full w-full lg:w-full"
        v-model="group.description"
        placeholder="Filler text"
        :editorToolbar="customToolbar"
      ></VueEditor>
    </div>
    <div class="mb-8">
      <p class="font-bold text-xl mb-3">Group Cover Image</p>
      <GroupPhotoUpload
        @submitted="handlePhotoUpload"
        :invalid="invalidFields.includes('groupPicture')"
      ></GroupPhotoUpload>
    </div>
  </div>
</template>

<script>
import TextInput from '@/components/TextInput.vue';
import GroupPhotoUpload from '@/components/groups/GroupPhotoUpload.vue';
import RadioButtonStyled from '@/components/baseComponents/RadioButtonStyled.vue';
import { VueEditor } from 'vue2-editor';

export default {
  name: 'SignupFormStepTwo',
  components: {
    TextInput,
    RadioButtonStyled,
    // TextArea,
    VueEditor,
    GroupPhotoUpload,
    // MapsAutocompleteInput,
  },
  props: {
    /**
     * fields that have errors
     */
    invalidFields: {
      default: () => [],
    },
    /**
     * update city on user object
     */
    handleGroupSubmit: {
      type: Function,
    },
    handlePhotoUpload: {
      type: Function,
    },
    group: {
      default: () => {
      },
    },
  },
  data() {
    return {
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['clean'],
        ['link'],
      ],
    };
  },
  computed: {
    // ...mapGetters(['group']),
    getFormattedCity() {
      if (this.user.city) {
        return `${this.user.city}, ${this.user.state}, ${this.user.country}`;
      }
      return '';
    },
  },
  methods: {
    updateBio(bio) {
      // TODO set group bio
      this.bio = bio;
    },
    chooseGroupType(type) {
      // TODO set group type
      this.groupClassification = type;
    },
  },
  watch: {
    globalErrors(errors) {
      errors.forEach((error) => {
        if (error.message && error.message.indexOf('name') >= 0) {
          this.currentStep = 1;
          this.invalidFields.push('name');
        }
      });
    },
  },
};
</script>

<style lang="scss">
#quill-container .ql-editor.ql-blank::before {
  font-style: normal;
}

#quill-container.ql-container.ql-snow {
  @apply border-none;
}

.quillWrapper .ql-toolbar.ql-snow {
  @apply border-none;
  border-bottom: 1px solid #eae5e1;
}
</style>
