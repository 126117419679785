<template>
  <div class="no-data">
    <Nodata />
    No media at the moment
  </div>
</template>
<script>
import Nodata from '@/assets/no_data.svg';

export default {
  name: 'NoData',
  components: { Nodata },
};
</script>
<style lang="scss" scoped>
.no-data {
  @apply flex w-full flex-col justify-center items-center gap-2.5 rounded bg-white border border-gray-300 shadow-md px-8 py-12 border-solid;
}

.no-data__text {
  @apply text-gray-600 text-sm mt-4;
}
</style>
