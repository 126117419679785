<template>
  <div>
    <slot name="sectionName"></slot>
    <div class="flex flex-wrap mt-6">
      <div v-for="profile in sectionMembers" :key="profile.id" class="w-1/2 mb-8 profile pr-6 flex">
        <div class="flex flex-col w-1/3">
          <ProfilePhoto
            :isPrintView="true"
            :isCard="false"
            :url="profile.profilePicture"
            class="w-100"
          ></ProfilePhoto>
          <div class="flex-grow-1"></div>
        </div>

        <div class="pl-6 w-2/3">
          <div class="mb-1">
            <span class="text-sm font-bold">{{ profile.firstName }} {{ profile.lastName }}</span>
            <span class="text-sm" v-if="profile.previousLastName"
              >({{ profile.previousLastName }})</span
            >
          </div>
          <div class="text-xs" v-if="profile.streetAddress">
            <span>{{ profile.streetAddress }}</span>
          </div>
          <div class="text-xs" v-if="!profile.streetAddress && profile.city && profile.state">
            <span>{{ profile.city }}, {{ profile.state }}</span>
          </div>
          <div class="text-xs" v-if="profile.phoneNumber">
            <span>{{ formatPhoneLocal(profile.phoneNumber) }}</span>
          </div>
          <div class="text-xs break-words">{{ profile.email }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfilePhoto from '@/components/ProfilePhoto.vue';
import { formatPhone } from '../helpers';

export default {
  name: 'PrintViewSection',
  components: {
    ProfilePhoto,
  },
  data() {
    return {};
  },
  props: {
    /**
     * Existing profile picture source
     */
    sectionMembers: {
      default: () => [],
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    isSuperuser() {
      return !!this.user && !!this.user.user && !!this.user.user.isSuperuser;
    },
  },
  methods: {
    formatPhoneLocal(p) {
      return formatPhone(p);
    },
  },
};
</script>

<style lang="scss">
.profile {
  page-break-inside: avoid;
  page-break-after: auto;
}
</style>
