<template>
  <span>
    <svg viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.0303 12.0757V10.8636C11.0303 10.2206 10.7749 9.60401 10.3203 9.14938C9.86567 8.69474 9.24905 8.43933 8.6061 8.43933H3.75762C3.11467 8.43933 2.49805 8.69474 2.04342 9.14938C1.58878 9.60401 1.33337 10.2206 1.33337 10.8636V12.0757"
        stroke="#2A2B2E"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.18181 6.01511C7.52068 6.01511 8.60605 4.92974 8.60605 3.59087C8.60605 2.252 7.52068 1.16663 6.18181 1.16663C4.84294 1.16663 3.75757 2.252 3.75757 3.59087C3.75757 4.92974 4.84294 6.01511 6.18181 6.01511Z"
        stroke="#2A2B2E"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.6667 12.0758V10.8636C14.6663 10.3265 14.4875 9.80472 14.1584 9.3802C13.8293 8.95568 13.3686 8.65247 12.8485 8.51819"
        stroke="#2A2B2E"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.4243 1.24548C10.9458 1.379 11.408 1.68227 11.738 2.10749C12.0681 2.53271 12.2473 3.05568 12.2473 3.59397C12.2473 4.13225 12.0681 4.65523 11.738 5.08045C11.408 5.50566 10.9458 5.80894 10.4243 5.94245"
        stroke="#2A2B2E"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </span>
</template>
<script>
export default {
  name: 'IconUsers',
};
</script>
