<template>
  <div class="pb-2 lg:pb-12">
    <Dropdown
      class="w-full min-w-[260px] md:w-[400px]"
      label="Current place"
      :options="currentPlaceOptions"
      :selectedOption="selectedCurrentPlaceOption"
      disabled
    />
    <Dropdown
      class="mt-4 w-full min-w-[260px] md:w-[400px]"
      label="Move to"
      placeholder="Select folder"
      :options="moveToOptions"
      :selectedOption="selectedMoveToOption"
      @selection="changeMoveTo"
    />

    <div class="controls w-full flex flex-col-reverse sm:flex-row justify-center pt-12">
      <Button
        class="cancel-btn min-w-[130px] w-full h-10 mr-0 sm:mr-2 mt-2 sm:mt-0"
        type="text"
        @onClick="$emit('cancel')"
      >
        <span class="text-sm text-gray-600 font-semibold">Cancel</span>
      </Button>
      <Button
        class="save-btn min-w-[130px] w-full h-10 ml-0 sm:ml-2"
        type="primary"
        :compact="true"
        @onClick="submit"
        :disabled="!selectedMoveToOption"
      >
        <span class="text-sm text-white font-semibold">Move</span>
      </Button>
    </div>
  </div>
</template>
<script>
import Dropdown from '@/components/Dropdown.vue';
import Button from '@/components/Button.vue';
import { mapGetters } from 'vuex';
import { getFoldersService } from '@/services/media.service';

const PHOTOS_FOLDER = { label: 'Photos', value: 'all' };

export default {
  name: 'MoveMediaForm',
  components: { Button, Dropdown },
  props: {
    currentFolder: {
      type: [Object, null],
    },
  },
  data() {
    return {
      allFolders: [],
      selectedMoveToOption: null,
    };
  },
  computed: {
    ...mapGetters(['group']),

    currentPlaceOptions() {
      const list = [];

      if (this.currentFolder) {
        list.push({ label: this.currentFolder.name, value: String(this.currentFolder.id) });
      } else {
        list.push(PHOTOS_FOLDER);
      }

      return list;
    },

    selectedCurrentPlaceOption() {
      return this.currentPlaceOptions[0];
    },

    moveToOptions() {
      let list = [];

      if (!this.currentFolder) {
        list = this.allFolders.map((f) => {
          return { label: f.name, value: String(f.id) };
        });
      } else {
        list = this.allFolders
          .filter((f) => f.id !== this.currentFolder.id)
          .map((f) => {
            return { label: f.name, value: String(f.id) };
          });
        list.push(PHOTOS_FOLDER);
      }

      return list;
    },
  },

  async created() {
    const { data } = await getFoldersService(this.group.id);
    this.allFolders = data.results;
  },

  methods: {
    submit() {
      this.$emit(
        'submit',
        this.selectedMoveToOption.value !== PHOTOS_FOLDER.value
          ? this.selectedMoveToOption.value
          : null,
      );
    },
    changeMoveTo(value) {
      this.selectedMoveToOption = value;
    },
  },
};
</script>
