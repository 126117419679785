<template>
  <Button
    :compact="compact"
    :primary-full-size="!short"
    @onClick="updateValue"
    :color-custom="primaryColor"
    :type="isChecked ? 'primary' : 'text'"
    :disabled="disabled"
    :class="{ 'semi-transparent': !isChecked }"
  >
    <div
      class="font-medium text-0_8xl"
      :class="[labelClass, { 'text-white py-1': isChecked, 'text-gray-600': !isChecked }]"
    >
      {{ label }}
    </div>
  </Button>
</template>

<script>
import Button from '@/components/Button.vue';

export default {
  name: 'RadioButtonStyled',
  components: { Button },
  computed: {
    isChecked() {
      return this.checked === this.value;
    },
  },
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    /**
     * id of the input
     */
    id: {
      type: String,
    },
    /**
     * The value for the checkbox input
     */
    value: {
      type: String,
    },
    /**
     * This is used from v-model in place of using value
     */
    checked: {},
    /**
     * The text that will be displayed next to the checkbox
     */
    label: {
      type: String,
      required: true,
    },
    labelClass: {
      type: String,
      default: '',
    },
    /**
     * Determines whether the checkbox is disabled or not
     */
    disabled: {
      type: Boolean,
    },
    /**
     * The group name for the radio button
     */
    name: {
      type: String,
    },
    compact: {
      type: Boolean,
      default: () => false,
    },
    short: {
      type: Boolean,
      default: () => false,
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    primaryColor: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    updateValue() {
      this.$emit('change', this.value);
    },
  },
};
</script>
