<template>
  <div class="mt-3">
    <template v-if="message.isHidden">
      <div
        class="flex justify-between items-center rounded border border-gray-300 shadow-drop px-8 py-6 border-solid bg-white"
      >
        <div>
          <p class="flex items-center gap-2 text-gray-900"><IconHidden /> Hidden</p>
          <p class="text-gray-600 text-sm mt-2">
            Your complaints help us fight against unwanted content.
          </p>
        </div>

        <button
          type="button"
          class="flex justify-center items-center gap-2.5 rounded px-4 py-[9px] bg-gray-100 hover:bg-gray200 text-gray-800 hover:text-gray-900 focus:outline-none animation-focus-default"
          @click="unHidePost"
        >
          Unhide
        </button>
      </div>
    </template>
    <template v-else>
      <div
        class="flex w-full flex-col rounded shadow-drop"
        :class="{
          'gap-3 pt-4 pb-5 px-4 bg-white': message.content,
          flaggedContent: isFlaggedContent,
        }"
      >
        <template v-if="message.content">
          <div class="flex justify-between">
            <div class="flex items-center gap-2">
              <img
                class="block w-[28px] md:w-[44px] h-[28px] md:h-[44px] shadow-md rounded-full"
                :src="message.profile.profilePicture"
                alt="user-name"
              />
              <p class="flex items-center gap-2 text-gray-900 font-semibold">
                {{ message.profile.fullName }}
                <span class="w-[4px] h-[4px] block rounded-full bg-gray-400 relative font-normal" />
                <span class="text-gray-700 text-xs opacity-80 font-normal">
                  {{ message.postedDate }}
                </span>
              </p>
            </div>
            <div class="flex">
              <button
                v-if="!isOwner && isOffensiveFlagEnabled"
                type="button"
                v-tooltip="'Flag as offensive content'"
                class="action-btn"
                @click="showFlagModal"
              >
                <IconFlag />
              </button>
              <button
                v-if="isGroupAdmin || isOwner"
                type="button"
                v-tooltip="'Delete'"
                class="action-btn"
                @click="showDeleteModal"
              >
                <Delete />
              </button>
            </div>
          </div>
          <p class="py-2 text-gray-900 text-sm whitespace-pre-line">
            {{message.content}}
          </p>
          <img
            v-if="message.photo"
            :src="message.photo"
            alt="post-image"
            class="max-w-full h-auto max-h-[400px] rounded object-contain"
          />
        </template>
        <template v-else-if="message.event">
          <div class="bg-blue-400 px-3 md:pl-2.5 md:pr-6 py-1 flex justify-between items-center">
            <div class="flex items-center">
              <IconEvent class="ml-2.5" />
              <span class="ml-2 text-white text-sm md:text-lg">Event Reminder</span>
              <span
                class="w-[4px] h-[4px] block rounded-full bg-white relative opacity-60 mx-2 mt-0 md:mt-1"
              />
              <span class="text-white text-xs opacity-60 mt-0 md:mt-1">
                {{ message.postedDate }}
              </span>
            </div>
            <button
              type="button"
              class="flex justify-center items-center gap-2.5 px-0 py-2.5 animation-focus-default"
              @click="routeToEvent"
            >
              <span class="text-white text-sm hidden md:inline">More Details</span>
              <ArrowRight class="more-details__icon w-[16px]" />
            </button>
          </div>
          <div class="flex justify-between items-start md:items-center bg-white px-4 py-3">
            <div>
              <p class="text-gray-900 font-semibold text-lg pb-2">{{message.event.name}}</p>
              <div class="flex flex-col md:flex-row text-gray-800 text-sm gap-3">
                <p class="flex">
                  <IconCalendar class="event-icon w-[12px] mr-1.5" />
                  <span>{{ message.event.formatMonthDay }}</span>
                </p>
                <p class="flex" v-if="message.event.amountAttending">
                  <IconUsers class="event-icon w-[13px] mr-1.5" />
                  <span>{{ message.event.amountAttending }} Attending</span>
                </p>
              </div>
            </div>
            <div class="flex gap-2 items-center">
              <Button
                class="h-[36px]"
                :compact="true"
                :primary-full-size="false"
                type="primary"
                @onClick="handleEvent"
                :disabled="message.event.isEventHasEnded"
              >
                {{ message.event.isAttending ? "I'm Going!" : 'Unable to Attend' }}
              </Button>
              <button
                v-if="isGroupAdmin"
                type="button"
                v-tooltip="'Delete'"
                class="action-btn"
                @click="showDeleteModal"
              >
                <Delete />
              </button>
            </div>
          </div>
        </template>
        <template v-else-if="message.fundraiser">
          <div class="bg-green-400 px-3 md:pl-2.5 md:pr-6 py-1 flex justify-between items-center">
            <div class="flex items-center">
              <IconFundraiser />
              <span class="text-white text-sm md:text-lg">Fundraiser Reminder</span>
              <span
                class="w-[4px] h-[4px] block rounded-full bg-white relative opacity-60 mx-2 mt-0 md:mt-1"
              />
              <span class="text-white text-xs opacity-60 mt-0 md:mt-1">
                {{ message.postedDate }}
              </span>
            </div>
            <a
              :href="message.fundraiser.url"
              class="flex justify-center items-center gap-2.5 px-0 py-2.5 text-white text-sm animation-focus-default"
            >
              <span class="text-white text-sm hidden md:inline">More Details</span>
              <ArrowRight class="more-details__icon w-[16px]" />
            </a>
          </div>
          <div class="flex justify-between items-center bg-white px-4 py-3">
            <p class="text-gray-800 text-sm py-2">
              <span class="text-gray-900 font-semibold">
                {{ message.fundraiser.createdBy.fullName }}
              </span>
              created a new fundraiser
            </p>
            <button
              v-if="isGroupAdmin"
              type="button"
              v-tooltip="'Delete'"
              class="action-btn"
              @click="showDeleteModal"
            >
              <Delete />
            </button>
          </div>
        </template>

        <template v-else-if="message.newGroupMember">
          <div class="bg-orange-400 px-3 md:pl-2.5 md:pr-6 py-1 flex justify-between items-center">
            <div class="flex items-center">
              <IconUser />
              <span class="text-white text-sm md:text-lg ml-2">Say Hi!</span>
              <span
                class="w-[4px] h-[4px] block rounded-full bg-white relative opacity-60 mx-2 mt-0 md:mt-1"
              />
              <span class="text-white text-xs opacity-60 mt-0 md:mt-1">
                {{ message.postedDate }}
              </span>
            </div>
            <button
              type="button"
              class="flex justify-center items-center gap-2.5 px-0 py-2.5 text-white text-sm animation-focus-default"
              @click="routeToProfile"
            >
              <span class="text-white text-sm hidden md:inline">Go to Profile</span>
              <ArrowRight class="more-details__icon w-[16px]" />
            </button>
          </div>
          <div class="flex justify-between items-center bg-white px-4 py-3">
            <p class="text-gray-800 text-sm py-2">
              <span class="text-gray-900 font-semibold">{{ message.newGroupMember.name }}</span>
              joined the group
            </p>
            <button
              v-if="isGroupAdmin"
              type="button"
              v-tooltip="'Delete'"
              class="action-btn"
              @click="showDeleteModal"
            >
              <Delete />
            </button>
          </div>
        </template>

        <!--   FOR ALL   -->
        <div
          :class="{
            'px-4 pb-5 bg-white': message.event || message.fundraiser || message.newGroupMember,
          }"
        >
          <div class="flex mb-1.5">
            <div class="flex items-center gap-4">
              <div class="flex items-center">
                <button type="button" v-tooltip="'Like'" class="action-btn" @click="handleLike">
                  <HeartEmptyIcon
                    class="post_icon w-5 h-5 opacity-80"
                    :class="{ liked: isPostLiked }"
                  />
                </button>
                <span v-if="message.likes.length" class="text-gray-700 text-sm opacity-80 pl-1">
                  {{ message.likes.length }}
                </span>
              </div>
              <div class="flex items-center">
                <button type="button" class="action-btn" @click="toggleMessageForm">
                  <CommentIcon class="post_icon w-5 h-5 opacity-80" />
                </button>
                <span
                  v-if="message.subDiscussions.length"
                  class="text-gray-700 text-sm opacity-80 pl-1"
                >
                  {{ message.subDiscussions.length }}
                </span>
              </div>
            </div>
          </div>

          <MessageForm
            v-if="isShowMessageForm"
            class="mt-[12px]"
            placeholder="Start typing a comment"
            @input="sendComment"
          />

          <div v-if="message.subDiscussions.length">
            <Comment
              v-for="comment in isShowAllComments ? message.subDiscussions : firstThreeComments"
              :key="comment.id"
              :comment="comment"
              @refetch="$emit('refetch')"
            />
            <button
              v-if="message.subDiscussions.length > LIMIT_COMMENTS"
              type="button"
              @click.stop="toggleAllComments"
              class="more_btn"
            >
              <Chevron class="more_icon" :class="{ 'rotate-180': isShowAllComments }" />
              {{ isShowAllComments ? 'Less comments' : 'More comments' }}
            </button>
          </div>
        </div>
      </div>
    </template>

    <!--  MODALS  -->

    <transition-group name="component-fade" mode="out-in">
      <DialogDefault
        v-if="isShowDeleteModal"
        header="Delete Post"
        text="Are you sure you want to delete this post?"
        @cancel="hideDeleteModal"
        @apply="deletePost"
        is-show-close-btn
        scheme="secondary"
        apply-btn-text="Delete"
        container-class="sm:w-[512px]"
        key="DeleteModal"
      />

      <DialogDefault
        v-if="isShowAttendModal"
        header="Unable to Attend?"
        text="Are you sure that you will no longer be able to attend the event?"
        @cancel="hideAttendModal"
        @apply="handleEvent"
        is-show-close-btn
        scheme="secondary"
        apply-btn-text="Confirm"
        container-class="sm:w-[512px]"
        key="AttendModal"
      />

      <DialogDefault
        v-if="isShowFlagModal"
        header="Flag as offensive content?"
        @cancel="hideFlagModal"
        @apply="updatePostConfig"
        is-show-close-btn
        scheme="secondary"
        apply-btn-text="Confirm"
        container-class="sm:w-[512px]"
        key="FlagModal"
      >
        <p class="text-gray-700 text-sm">Are you sure this content is offensive?</p>
        <div class="py-6">
          <Checkbox label="Hide this Message for Me" disabled-label-hover v-model="isHiddenPost" />
        </div>
      </DialogDefault>
    </transition-group>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import IconFlag from '@/assets/icon_flag.svg';
import Delete from '@/assets/delete.svg';
import IconEvent from '@/assets/icon_event.svg';
import IconUser from '@/assets/icon_user_fill.svg';
import IconFundraiser from '@/assets/icon_fundraiser.svg';
import IconCalendar from '@/assets/icon_calendar.svg';
import IconUsers from '@/assets/icon_users.svg';
import ArrowRight from '@/assets/arrow_right.svg';
import HeartEmptyIcon from '@/assets/icon_heart.svg';
import IconHidden from '@/assets/icon_hidden.svg';
import CommentIcon from '@/assets/icon_comment.svg';
import MessageForm from '@/components/forms/MessageForm.vue';
import Comment from '@/views/Group/components/feed/Comment.vue';
import { MessageRecord } from '@/records/message.record';
import DialogDefault from '@/components/DialogDefault.vue';
import {
  configPostService,
  createMessageService,
  deletePostService,
  dislikePostService,
  likePostService,
} from '@/services/feed.service';
import Chevron from '@/assets/chevron.svg';
import Checkbox from '@/components/Checkbox.vue';
import Button from '@/components/Button.vue';

const LIMIT_COMMENTS = 3;

export default {
  name: 'Post',
  components: {
    Button,
    Checkbox,
    Chevron,
    IconUser,
    IconUsers,
    IconFundraiser,
    IconEvent,
    IconCalendar,
    ArrowRight,
    DialogDefault,
    Comment,
    MessageForm,
    HeartEmptyIcon,
    IconHidden,
    CommentIcon,
    IconFlag,
    Delete,
  },
  props: {
    message: {
      type: MessageRecord,
      required: true,
    },
    isOffensiveFlagEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      LIMIT_COMMENTS,
      isHiddenPost: false,
      isShowFlagModal: false,
      isShowAttendModal: false,
      isShowMessageForm: false,
      isShowDeleteModal: false,
      isShowAllComments: false,
    };
  },

  computed: {
    ...mapGetters(['user', 'profiles', 'group']),

    isOwner() {
      return this.message.profile ? this.message.profile.id === this.user.id : null;
    },
    isGroupAdmin() {
      const currentProfile = this.profiles.find((p) => p.profile === this.user.id);
      return !!currentProfile && currentProfile.isGroupAdmin;
    },
    isPostLiked() {
      return !!this.message.likes.find((p) => p.id === this.user.id);
    },
    firstThreeComments() {
      return this.message.subDiscussions.slice(0, LIMIT_COMMENTS);
    },
    isFlaggedContent() {
      const offensivePost = this.$route.query['offensive-post'];
      return offensivePost === String(this.message.id);
    },
  },
  created() {
    this.isHiddenPost = !!this.message.isHidden;
  },
  methods: {
    ...mapActions(['postRsvp']),
    toggleMessageForm() {
      this.isShowMessageForm = !this.isShowMessageForm;
    },

    hideDeleteModal() {
      this.isShowDeleteModal = false;
    },

    hideFlagModal() {
      this.isShowFlagModal = false;
    },

    showFlagModal() {
      this.isShowFlagModal = true;
    },

    hideAttendModal() {
      this.isShowAttendModal = false;
    },

    showAttendModal() {
      this.isShowAttendModal = true;
    },

    showDeleteModal() {
      this.isShowDeleteModal = true;
    },

    toggleAllComments() {
      this.isShowAllComments = !this.isShowAllComments;
    },

    handleEvent() {
      if (this.message.event.isAttending && !this.isShowAttendModal) {
        this.showAttendModal();
      } else {
        const approvedEventStatus = this.message.event.isAttending ? '2' : '1';
        this.postRsvp({
          groupSlug: this.group.slug,
          eventId: this.message.event.id,
          rsvp: approvedEventStatus,
          userId: this.user.id,
        });

        if (this.isShowAttendModal) {
          this.isShowAttendModal = false;
        }

        this.$emit('refetch');
      }
    },

    routeToEvent() {
      this.$router.push({
        name: 'event',
        params: { eventId: this.message.event.id },
      });
    },

    routeToProfile() {
      this.$router.push({
        name: 'profile',
        params: {
          group: this.group.slug,
          user: this.message.newGroupMember.slug,
        },
      });
    },

    async updatePostConfig() {
      const config = {
        is_hidden: this.isHiddenPost,
        is_flagged_as_offensive: true,
      };
      await configPostService(config, this.group.slug, this.message.id);
      this.hideFlagModal();
      this.$emit('refetch');
    },

    async unHidePost() {
      const config = {
        is_hidden: false,
      };
      await configPostService(config, this.group.slug, this.message.id);
      this.$emit('refetch');
    },

    async handleLike() {
      if (this.message.content && this.message.profile.id === this.user.id) return;

      if (this.isPostLiked) {
        await dislikePostService(this.group.slug, this.message.id);
      } else {
        await likePostService(this.group.slug, this.message.id);
      }

      this.$emit('refetch');
    },

    async deletePost() {
      if (this.isGroupAdmin || this.isOwner) {
        try {
          await deletePostService(this.group.slug, this.message.id);
          this.$emit('refetch');
        } finally {
          this.hideDeleteModal();
        }
      }
    },

    async sendComment(comment, imageBlob) {
      await createMessageService(this.group.slug, comment, imageBlob, this.message.id);
      this.$emit('refetch');
    },
  },
};
</script>
<style lang="scss" scoped>
.action-btn {
  @apply flex items-center justify-center hover:bg-gray-200 w-[30px] h-[30px] p-1.5 rounded-sm;

  path {
    @apply stroke-gray-600;
  }
  &:hover {
    path {
      @apply stroke-gray-900;
    }
  }
}
.flaggedContent {
  box-shadow: 0 10px 20px 0 rgba(249, 110, 71, 0.4);
}
.post_icon {
  path {
    @apply stroke-gray-600;
  }
  &.liked path {
    @apply stroke-orange fill-orange;
  }
}

.more-details__icon {
  path {
    @apply fill-white;
  }
}

.event-icon {
  path {
    @apply stroke-gray-800;
  }
}

.more_btn {
  @apply flex items-center ml-10 justify-start hover:text-orange-400 py-2 rounded-sm text-gray-600 text-center text-sm;

  .more_icon {
    @apply w-4 h-4 mr-1.5 fill-gray-600;
  }
  &:hover {
    .more_icon {
      path {
        @apply fill-orange;
      }
    }
  }
}
</style>
