<template>
  <div
    class="flex border h-[350px] w-full sm:w-[285px] bg-white flex-col items-start gap-2 rounded px-2 py-3 hover:shadow-card"
    :class="{ 'border-primary': media.isSelected }"
  >
    <div class="flex justify-between items-center w-full">
      <div class="flex items-center">
        <Checkbox
          :label="media.name"
          is-truncate-label
          @change="selectMedia"
          v-model="media.isSelected"
        />
      </div>
      <div class="relative" v-click-outside="closeMenu">
        <button
          v-tooltip="'More actions'"
          @click.stop="toggleMenu"
          type="button"
          class="flex items-start gap-2.5 p-[7px] rounded-sm hover:bg-gray-100"
        >
          <MediaMenu />
        </button>
        <ul
          v-show="isMenuOpen"
          class="flex absolute top-8 right-0 w-[200px] flex-col items-start gap-1.5 rounded bg-white border border-gray-300 shadow-[0px_2px_4px_0px_rgba(17,17,17,0.08)] px-0 py-1 border-solid"
        >
          <li
            v-if="!isFolder"
            class="menu-item group px-4 py-2 w-full hover:bg-gray-100 hover:cursor-pointer"
          >
            <button
              type="button"
              @click.stop="editMedia"
              class="flex w-full items-center gap-2.5 group-hover:text-primary"
            >
              <Edit class="menu-icon" />
              Edit
            </button>
          </li>
          <li class="menu-item group px-4 py-2 w-full hover:bg-gray-100 hover:cursor-pointer">
            <button
              type="button"
              class="flex w-full items-center gap-2.5 group-hover:text-primary"
              @click.stop="downloadFile"
            >
              <Download class="menu-icon" />
              Download
            </button>
          </li>
          <li
            v-if="!isFolder"
            class="menu-item group px-4 py-2 w-full hover:bg-gray-100 hover:cursor-pointer"
            @click.stop="moveMedia"
          >
            <button type="button" class="flex w-full items-center gap-2.5 group-hover:text-primary">
              <MoveToMenu class="menu-icon" />
              Move to
            </button>
          </li>
          <li class="menu-item group px-4 py-2 w-full hover:bg-gray-100 hover:cursor-pointer">
            <button
              type="button"
              @click.stop="isShowDeleteModal = true"
              class="flex w-full items-center gap-2.5 group-hover:text-primary"
            >
              <Delete class="menu-icon" />
              Delete
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div
      class="h-[240px] w-full flex justify-center items-center cursor-pointer"
      @click.stop="action"
    >
      <template v-if="isFolder">
        <div class="text-center">
          <!-- Folder view -->
          <Folder />
          {{ `${media.childrenCount} ${media.childrenCount === 1 ? 'item' : 'items'}` }}
        </div>
      </template>
      <template v-else>
        <img class="rounded max-h-[100%]" :src="media.file" :alt="media.name" />
      </template>
    </div>
    <div v-if="!isFolder" class="truncate-2-lines text-sm max-w-[285px] min-h-[40px]">
      {{ media.description }}
    </div>

    <transition name="component-fade" mode="out-in">
      <DialogDefault
        v-if="isShowDeleteModal"
        header="Delete file?"
        :text="`Are you sure to delete ${media.name} from ${folderName}?`"
        @cancel="isShowDeleteModal = false"
        @apply="deleteMedia"
        is-show-close-btn
        scheme="secondary"
        apply-btn-text="Delete"
      />
    </transition>
  </div>
</template>
<script>
import Checkbox from '@/components/Checkbox.vue';

import Edit from '@/assets/edit.svg';
import Folder from '@/assets/folder.svg';
import Delete from '@/assets/delete.svg';
import Download from '@/assets/download.svg';
import MediaMenu from '@/assets/media_menu.svg';
import MoveToMenu from '@/assets/move-to-menu.svg';
import { FileUtil } from '@/utils/file.util';
import DialogDefault from '@/components/DialogDefault.vue';
import { mapGetters } from 'vuex';
import { deleteMediaService } from '@/services/media.service';
import { FILE_TYPE } from '@/symbols/file-type';

export default {
  name: 'MediaItem',
  components: {
    DialogDefault,
    MediaMenu,
    Download,
    MoveToMenu,
    Delete,
    Edit,
    Folder,
    Checkbox,
  },
  props: {
    media: {
      type: Object,
      required: true,
    },
    folderName: {
      type: String,
    },
  },
  data() {
    return {
      isMenuOpen: false,
      isShowDeleteModal: false,
    };
  },
  computed: {
    ...mapGetters(['group']),
    isFolder() {
      return this.media.fileType === FILE_TYPE.FOLDER;
    },
  },
  methods: {
    action() {
      this.$emit('action', this.media);
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    downloadFile() {
      FileUtil.downloadFile(this.media.file, this.media.name);
      this.isMenuOpen = false;
    },
    selectMedia() {
      this.$emit('select', this.media);
    },

    editMedia() {
      this.$emit('edit', this.media);
    },

    moveMedia() {
      this.$emit('move', this.media);
      this.isMenuOpen = false;
    },

    async deleteMedia() {
      try {
        await deleteMediaService([this.media.id], this.group.id);
        this.$emit('refetch');
      } finally {
        this.isShowDeleteModal = false;
        this.isMenuOpen = false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.menu-item:hover {
  svg.menu-icon path {
    @apply stroke-primary;
  }
}

svg.menu-icon {
  path {
    stroke: #6b6d70;
  }
}
</style>
