<template>
  <div
    class="flex flex-col justify-between fixed bottom-4 right-3 w-[90vw] md:w-[450px] h-[80vh] md:h-[620px] shadow-drop pt-5 rounded-lg bg-white z-30"
  >
    <Toast v-if="copyMessage">{{ copyMessage }}</Toast>
    <div
      class="flex justify-between items-center border-b-gray-200 shadow-drop pt-0 pb-5 px-4 border-b border-solid; background: var(--Gray-Off-white, #FFF); } Show 3 more colors Close Component instance"
    >
      <p class="text-gray-900 text-lg font-normal leading-[140%]">Fun Insights</p>
      <button type="button" @click.stop="$emit('close')">
        <CloseIcon class="w-6 h-6 close-icon" />
      </button>
    </div>

    <div
      ref="scrollContainer"
      class="flex flex-col justify-between h-full max-h-[495px] overflow-y-auto"
    >
      <div v-if="isWelcomeBlockShow" class="open-ai__welcome-block relative">
        <IconStars
          class="absolute top-4 right-4 rotate-[25deg] scale-150 opacity-40 open-ai__welcome-icon"
        />
        <p class="text-[20px] font-bold">Hi, {{ user.firstName }} 👋</p>
        <p class="text-[20px] font-bold pb-2">Welcome to Reunacy's Fun Insights</p>
        <p v-if="group.isSubscribed" class="text-gray-900 text-sm font-semibold leading-[160%]">
          Tap the prompts below to automagically learn more about the people in your group!
        </p>
        <p v-else class="text-gray-900 text-sm font-semibold leading-[160%]">
          Your group is currently on the Free Plan. Upgrade to an Exclusive Plan subscription to
          give everyone in the group access to the Fun Insights feature!
        </p>
      </div>
      <div v-if="isLoading" class="h-full flex justify-center items-center">
        <Preloader class="w-16 h-16" />
      </div>

      <div v-else class="flex flex-col py-[24px] px-[16px] bg-white h-full gap-2">
        <div v-for="(message, index) in feed" :key="index" class="pb-4">
          <template v-if="message.question">
            <div class="flex justify-end">
              <p
                class="px-3 py-[11px] rounded-xl text-white text-right text-sm font-normal leading-[140%] bg-blue-200"
              >
                {{ message.question }}
              </p>
            </div>
          </template>
          <template v-else>
            <div class="open-ai__answer">
              <div class="open-ai__answer-icon"><IconStars class="scale-75" /></div>
              <p
                class="text-gray-800 text-sm font-normal leading-[160%] bg-gray-100 px-4 pt-5 pb-3 rounded-lg whitespace-pre-line relative"
              >
                {{ message.answer }}
                <button class="copy-btn" type="button" @click="copyAnswer(message.answer)">
                  <IconCopy class="w-[12px] mr-1" />
                  Copy
                </button>
              </p>
            </div>
          </template>
        </div>
      </div>
    </div>

    <div class="open-ai__control">
      <button
        v-for="prompt in prompts"
        :key="prompt.id"
        type="button"
        class="open-ai__question animation-focus-default"
        @click.stop="getAnswer(prompt.id)"
        :disabled="!group.isSubscribed"
      >
        {{ prompt.label }}
      </button>
    </div>
  </div>
</template>
<script>
import IconStars from '@/assets/icon_stars.svg';
import CloseIcon from '@/assets/icon_close.svg';
import IconCopy from '@/assets/icon_copy.svg';
import Preloader from '@/assets/icon_circle_animation.svg';
import { getPromptResponseService, getPromptsService } from '@/services/ai.service';
import { mapGetters } from 'vuex';
import Toast from '@/components/Toast.vue';

export default {
  name: 'OpenAIForm',

  components: {
    Toast,
    IconStars,
    CloseIcon,
    IconCopy,
    Preloader,
  },

  data() {
    return {
      prompts: [],
      copyMessage: '',
      feed: [],
      isFormShow: false,
      isLoading: false,
      isWelcomeBlockShow: true,
    };
  },

  computed: {
    ...mapGetters(['group', 'user']),
  },

  async created() {
    this.prompts = await getPromptsService();
  },

  methods: {
    scrollToBottom() {
      // eslint-disable-next-line prefer-destructuring
      const scrollContainer = this.$refs.scrollContainer;
      scrollContainer.scrollTop = scrollContainer.scrollHeight;
    },

    async copyAnswer(answer) {
      try {
        await navigator.clipboard.writeText(answer);
        this.copyMessage = 'Text copied to clipboard!';
      } catch (e) {
        console.log('Cannot copy');
      }
    },

    async getAnswer(promptId) {
      this.isLoading = true;
      this.isWelcomeBlockShow = false;

      const question = this.prompts.find((p) => p.id === promptId);
      this.feed.push({ question: question.label });

      const answer = await getPromptResponseService(this.group.slug, promptId);
      this.feed.push({ answer });
      this.isLoading = false;

      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.open-ai__welcome-block {
  @apply flex flex-col px-8 py-6 text-gray-900;
  background: linear-gradient(180deg, rgba(128, 205, 255, 0.19) 0%, rgba(124, 101, 255, 0.19) 100%);
}

.close-icon path {
  stroke: #c8cacd;
}

.open-ai__control {
  @apply flex justify-end items-center content-center gap-2 flex-wrap border-t-gray-200 h-full px-4 py-3 border-t border-solid max-h-[130px] overflow-y-auto;
  box-shadow: 0px -10px 60px 0px rgba(42, 43, 46, 0.06);
}

.open-ai__question {
  @apply flex flex-col justify-center items-start gap-1.5 px-2 py-1 rounded-lg self-stretch text-orange-500 text-sm font-normal leading-[140%] hover:bg-orange-400  hover:text-white disabled:pointer-events-none disabled:opacity-60;
  background: rgba(249, 110, 71, 0.06);
}

.open-ai__answer-icon {
  @apply flex justify-center items-center gap-2 min-w-[32px] h-[32px] rounded-full;
  background: linear-gradient(180deg, #54b8f9 0%, #8a77f7 100%);
}

.open-ai__answer {
  @apply flex gap-2 items-start;
}

.open-ai__welcome-icon path {
  fill: #8084f8;
}

.copy-btn {
  @apply flex absolute top-1 right-1 items-center justify-center bg-gray-200 hover:bg-gray-300 px-1 rounded font-semibold text-small;
  &:hover {
    path,
    circle,
    ellipse {
      @apply stroke-gray-800;
    }
  }
}
</style>
