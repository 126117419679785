export class ProfileRecord {
  constructor(record = {}) {
    // eslint-disable-next-line no-underscore-dangle
    this._record = record;
    this.id = record.id || '';
    this.name = record.name || '';
    this.profilePicture = record.profilePicture || '';
    this.slug = record.slug || '';
    this.firstName = record.firstName || '';
    this.lastName = record.lastName || '';
  }

  get fullName() {
    return this.name || `${this.firstName} ${this.lastName}`;
  }
}
