var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.options)?_c('div',{staticClass:"dropdown overflow-visible"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchFilter),expression:"searchFilter"}],staticClass:"focus:outline-none input border border-gray-300 w-full h-full text-gray-900 bg-white rounded shadow text-sm placeholder-gray-500",class:[
        {'input--filled': _vm.selected && _vm.selected !== ''},
        {'border-red-600 border-1': _vm.invalid},
        {'disabled': _vm.disabled},
        {'py-5 pl-12 pr-4': (_vm.hasIcon && !_vm.showLabel) && _vm.label && !_vm.compact},
        {'py-3 pl-14 pr-4': (_vm.hasIcon && _vm.showLabel) && _vm.label && !_vm.compact},
        {'pt-7 pb-3 px-4': !_vm.hasIcon && _vm.label && !_vm.compact},
        {'py-5 px-4': !_vm.label && !_vm.compact},
        {'py-2 pl-10 pr-2': _vm.hasIcon && _vm.compact},
        {'py-2 px-4': !_vm.hasIcon && _vm.compact},
        {'h-full': _vm.customHeight},
        {'cursor-pointer': _vm.readonly}
      ],attrs:{"name":_vm.name,"disabled":_vm.disabled,"autocomplete":"off","autocorrect":"false","readonly":_vm.readonly,"placeholder":_vm.placeholder || (_vm.focused && _vm.focusedPlaceholder)},domProps:{"value":(_vm.searchFilter)},on:{"focus":function($event){return _vm.showOptions()},"blur":function($event){return _vm.exit()},"keyup":_vm.keyMonitor,"input":function($event){if($event.target.composing)return;_vm.searchFilter=$event.target.value}}}),(_vm.label)?[(!_vm.hasIcon)?_c('label',{staticClass:"input__label absolute left-0 text-gray-600 mx-4 pointer-events-none mt-2 origin-top-left transition duration-200 ease-out",class:[
        {'text-red-600': _vm.invalid},
      ],attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),(_vm.hasIcon && _vm.showLabel)?_c('label',{staticClass:"input__label absolute left-0 text-gray-600 mt-2px mr-4 ml-14 pointer-events-none origin-top-left transition duration-200 ease-out",class:[
          {'text-red-600': _vm.invalid},
        ],attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),(_vm.hasIcon)?_c('span',{staticClass:"icon-wrapper absolute top-0 left-0 h-full flex items-center ml-4 text-gray-400 transition-colors duration-200 ease-out pointer-events-none px-1"},[_c('label',{staticClass:"pointer-events-none opacity-0 w-0 h-0",attrs:{"for":_vm.id}},[_vm._v(_vm._s(_vm.label))]),_vm._t("icon")],2):_vm._e()]:_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.optionsShown),expression:"optionsShown"}],staticClass:"dropdown-content"},_vm._l((_vm.filteredOptions),function(option,index){return _c('div',{key:index,staticClass:"dropdown-item",class:{
      'bg-gray-400': _vm.disableOption(),
      'hover:bg-gray-300 hover:cursor-pointer': !_vm.disableOption(),
      },on:{"mousedown":function($event){return _vm.selectOption(option)}}},[_vm._v(" "+_vm._s(option)+" ")])}),0)],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }