<template>
  <div class="py-8 mx-6 sm:mx-auto max-w-screen-xl border-t border-gray-300 sm:border-none">
    <div
      v-if="!isLoading"
      class="header flex flex-col sm:flex-row mb-4 lg:px-30 p-8 pb-4 mt-3 sm:mt-0 bg-white rounded shadow-md align-center"
    >
      <div
        class="group-photo-wrapper small sm:hidden items-center justify-center self-center"
        v-if="group.groupPhoto"
      >
        <img :src="group.groupPhoto" class="picture block mx-auto" />
      </div>
      <div class="flex flex-col sm:flex-row">
        <div class="group-photo-wrapper hidden sm:block" v-if="group.groupPhoto">
          <img :src="group.groupPhoto" class="picture block mx-auto" />
        </div>
        <div class="flex flex-col sm:pl-6">
          <h1 class="font-serif text-base text-gray-900 text-center sm:text-left">
            You've been invited to join
          </h1>
          <h1
            class="group-name font-serif text-2xl sm:text-4xl text-gray-900 text-center sm:text-left line-clamp !leading-normal"
          >
            {{ group.name }}
          </h1>
          <p
            v-if="isGroupFreeAndOverLimit"
            class="text-gray-600 text-center sm:text-left mb-3 sm:mt-1 overflow-ellipsis line-clamp"
          >
            Unfortunately, this group has reached the 10-member limit
          </p>
          <p
            v-else
            class="text-gray-600 text-center sm:text-left mb-3 sm:mt-1 overflow-ellipsis line-clamp"
            v-html="group.description"
          />

          <div v-if="!isGroupFreeAndOverLimit" class="flex flex-col items-center sm:flex-row">
            <Button
              type="primary"
              :primaryFullSize="false"
              class="w-182px h-40px py-8px min-w-182px"
              @onClick="handleJoinGroup"
            >
              {{ isAuthenticated ? 'Join Group' : 'Create your profile' }}
            </Button>
            <div
              v-if="!isAuthenticated"
              class="flex mt-5 sm:mt-0 justify-around text-center sm:text-left md:w-276px"
            >
              <div class="sm:ml-6 mr-4 md:mr-2">Already a member?</div>
              <router-link
                :to="{ name: 'login', query: { redirectUrl: '/group/' + group.slug + '/preview' } }"
                class="text-sm pt-3px text-orange-400 font-bold cursor-pointer"
                >Login</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="!isLoading"
      class="about-reunacy flex sm:flex-row flex-col mb-4 px-6 xl:px-40 rounded sm:justify-around justify-center text-center sm:text-left"
    >
      <div class="flex flex-col justify-center">
        <div class="text-white font-serif text-xl md:text-3xl">Reunacy makes it easy to:</div>
      </div>
      <div class="flex flex-col justify-center">
        <div class="flex flex-col text-base easy-list">
          <div class="text-white sm:my-1">&bull; Catch up with people in your group</div>
          <div class="text-white sm:my-1">&bull; Plan your next get-together</div>
          <div class="text-white sm:my-1">&bull; Avoid the noise of social media</div>
        </div>
      </div>
    </div>

    <div :class="{ 'min-h-170px': !isLoading }">
      <div
        v-if="profiles.length"
        class="grid grid-cols-1 sm:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4 sm:gap-7 lg:gap-5 2xl:gap-4 3xl:gap-9"
      >
        <ProfileCard
          v-for="profile in profiles"
          :key="profile.id"
          :profile="profile"
          :groupSlug="group.slug"
          :disabled="notAuthenticatedOrNotMember"
          :showSchool="group.schoolShowYearbook"
          :showEmployer="group.employerShowYearbook"
          :showMajor="group.majorShowYearbook"
          :showDataOverflow="false"
        />
      </div>
      <div
        v-else-if="basicProfiles.length"
        class="grid grid-cols-1 sm:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4 sm:gap-7 lg:gap-5 2xl:gap-4 3xl:gap-9"
      >
        <ProfileCard
          v-for="profile in basicProfiles"
          :key="profile.id"
          :profile="profile"
          :groupSlug="group.slug"
          :disabled="notAuthenticatedOrNotMember"
          :showSchool="group.schoolShowYearbook"
          :showEmployer="group.employerShowYearbook"
          :showMajor="group.majorShowYearbook"
          :showDataOverflow="false"
          class="card-small"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Button from '@/components/Button.vue';
import ProfileCard from '@/components/ProfileCard.vue';

const MEMBERS_LIMIT = 10;
export default {
  name: 'GroupPreview',
  components: {
    Button,
    ProfileCard,
  },
  computed: {
    ...mapGetters([
      'currentProfile',
      'group',
      'isAuthenticated',
      'isLoading',
      'profiles',
      'basicProfiles',
      'user',
    ]),
    isAdmin() {
      const currentUser = this.profiles.slice().filter((member) => member.id === this.user.id);
      const isAdmin = currentUser.length ? currentUser[0].isGroupAdmin : false;
      return isAdmin || this.isSuperuser;
    },
    isGroupFreeAndOverLimit() {
      return !this.group.isSubscribed && this.profiles.length >= MEMBERS_LIMIT;
    },
    memberOfGroup() {
      return (
        this.user &&
        this.user.groups &&
        this.user.groups.some((group) => group.slug === this.$route.params.group)
      );
    },
    isSuperuser() {
      return !!this.user && !!this.user.user && !!this.user.user.isSuperuser;
    },
    notAuthenticatedOrNotMember() {
      if (this.isSuperuser) return false;
      return !this.isAuthenticated || (this.isAuthenticated && !this.memberOfGroup);
    },
    visibleCustomFieldSlugs() {
      return this.profileFields.filter((field) => field.showOnYearbook).map((field) => field.slug);
    },
  },
  created() {
    this.checkAuthentication();
    if (this.group) {
      this.profileInfo.groupSlug = this.group.slug;
      if (this.group.isSubscribed) {
        this.fetchBasicProfiles(this.profileInfo);
      } else {
        this.fetchProfiles(this.profileInfo);
      }
    }
  },
  data() {
    return {
      profileInfo: {
        groupSlug: '',
        sort: '+last_name',
        filters: {},
      },
    };
  },
  methods: {
    ...mapActions([
      'createGroupVideoPrompt',
      'fetchProfile',
      'fetchProfileFields',
      'fetchProfiles',
      'fetchBasicProfiles',
      'updateModalOpen',
      'fetchEventList',
      'addProfileToList',
      'removeProfileFromList',
    ]),
    checkAuthentication() {
      if (this.memberOfGroup) {
        this.$router.push({
          name: 'group',
          params: {
            group: this.$route.params.group,
          },
        });
      }
    },
    handleJoinGroup() {
      if (!this.isAuthenticated) {
        this.$router.push({
          name: 'signup',
          params: {
            group: this.$route.params.group,
            isFromMembers: true,
          },
        });
      } else if (this.isAuthenticated && !this.memberOfGroup) {
        this.$router.push({
          name: 'join-group',
          params: {
            group: this.$route.params.group,
          },
        });
      }
    },
  },
  watch: {
    user() {
      this.checkAuthentication();
    },
    group() {
      this.profileInfo.groupSlug = this.group.slug;
      if (this.group) {
        if (this.group.isSubscribed) {
          this.fetchBasicProfiles(this.profileInfo);
        } else {
          this.fetchProfiles(this.profileInfo);
        }
      }
    },
  },
};
</script>

<style lang="scss">
@media all and (min-width: 576px) {
  .group-name {
    line-height: 2.75rem;
  }

  .header {
    background: url('../../assets/octagon-big.png') no-repeat bottom right 4%, #ffffff;
  }

  .about-reunacy {
    height: 144px;
  }
}
.member-card {
  box-shadow: 0 2px 4px rgba(17, 17, 17, 0.08);
  transition: max-height 0.1s cubic-bezier(0, 1, 0, 1);
  @apply w-full sm:w-227px h-150px max-h-150px sm:h-364px sm:max-h-364px flex sm:flex-col relative bg-white rounded;

  &.card-small {
    height: 275px;
  }
  &:hover {
    box-shadow: 0 15px 24px rgba(17, 17, 17, 0.05);
  }

  .picture {
    left: 50%;
    transform: translateX(-50%);
    @apply w-auto h-full sm:h-227px min-w-150px absolute sm:relative;
  }
}

.circle {
  @apply rounded rounded-full bg-gray-100 w-16 h-16 items-center justify-center;
  background-color: #f6f6f6;
}

.matched-text {
  @apply text-gray-900;
}

.search-dropdown-container {
  @apply z-10 absolute rounded ring-1 ring-black ring-opacity-5 shadow-lg bg-white pr-2 mt-2 w-full;

  .search-dropdown {
    @apply overflow-y-auto max-h-240px p-6 w-full;
  }
}

.group-photo-wrapper {
  @apply relative  w-26 border-transparent flex-shrink-0 overflow-hidden rounded-full;

  &.color-default {
    @apply hover:border-defaultInteractive;
  }

  img {
    @apply rounded-full border-3 border-white;
  }

  &.small {
    margin-top: -43px;
  }
}

.about-reunacy {
  background: url('../../assets/octagon.png') no-repeat 3% 3%,
    linear-gradient(268.73deg, #03818f 5.42%, #077a87 97.51%);
  height: 125px;
}

.easy-list {
  div {
    padding-left: 10px;
    text-indent: -10px;
    display: inline-block;
  }
}
.line-clamp {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
