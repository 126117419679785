var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{staticClass:"w-full animation-focus-default",attrs:{"to":{
    name: 'profile',
    params: {
      group: _vm.groupSlug,
      user: _vm.profile.slug,
    },
  }}},[_c('div',{staticClass:"flex items-center justify-between p-2 mb-1 rounded border-gray-300 group hover:bg-gray-100"},[_c('div',{staticClass:"flex"},[(_vm.profile.profilePicture)?_c('img',{staticClass:"w-12 md:w-16 h-12 md:h-16 rounded mr-4",attrs:{"src":_vm.profile.profilePicture,"alt":"profile_picture"}}):_c('img',{staticClass:"w-12 md:w-16 h-12 md:h-16 rounded mr-4",attrs:{"src":require("@/assets/profile_photo_outline.png"),"alt":"default_picture"}}),_c('div',{staticClass:"flex flex-col justify-center"},[_c('div',{staticClass:"text-base font-semibold text-gray-900 mb-1"},[_vm._v(" "+_vm._s(_vm.profile.firstName)+" "+_vm._s(_vm.profile.lastName)+" ")]),_c('div',{staticClass:"flex items-center text-gray-700 text-small"},[_c('LocationIcon',{staticClass:"location-icon w-3 h-3 mr-1"}),_vm._v(" "+_vm._s(_vm.location)+" ")],1)])]),(_vm.profile.isGroupAdmin)?_c('div',{staticClass:"flex justify-center items-center rounded px-2 py-0.5 text-gray-800 text-xs bg-gray-200 max-h-[21px] group-hover:bg-white group-hover:text-gray-900"},[_vm._v(" Admin ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }