<template>
  <form>
    <div class="relative">
      <label :for="folderName" class="text-sm text-gray-800">Folder Name</label>
      <input
        :id="folderName"
        type="text"
        v-model="folderName"
        @blur="$v.folderName.$touch()"
        placeholder="Enter name"
        class="input h-[56px] py-4 px-5 w-full text-gray-900 bg-white rounded shadow border border-gray-300 text-base placeholder-gray-500 focus:outline-none transition-colors duration-200 ease"
      />
      <span v-if="$v.folderName.$dirty && !$v.folderName.required" class="error-message">
        This field is required.
      </span>
      <span v-else-if="$v.folderName.$dirty && !$v.folderName.maxLength" class="error-message">
        Max length is 50 characters.
      </span>
      <span v-else-if="$v.folderName.$dirty && !$v.folderName.customAlphaNum" class="error-message">
        Only alphanumeric characters are allowed.
      </span>
    </div>

    <div class="controls w-full flex flex-col-reverse sm:flex-row justify-center pt-12">
      <Button
        class="cancel-btn min-w-[130px] w-full h-10 mr-0 sm:mr-2"
        type="text"
        @onClick="$emit('cancel')"
      >
        <span class="text-sm text-gray-600 font-semibold">Cancel</span>
      </Button>
      <Button
        class="save-btn min-w-[130px] w-full h-10 ml-0 sm:ml-2 mb-2 sm:mb-0"
        type="primary"
        :compact="true"
        @onClick="submit"
      >
        <span class="text-sm text-white font-semibold">Create</span>
      </Button>
    </div>
  </form>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators';
import Button from '@/components/Button.vue';

export default {
  name: 'CreateFolderForm',
  components: { Button },
  data() {
    return {
      folderName: '',
    };
  },
  validations: {
    folderName: {
      required,
      customAlphaNum(value) {
        return /^[a-zA-Z0-9\s]*$/.test(value);
      },
      maxLength: maxLength(50),
    },
  },

  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit('submit', this.folderName);
      }
    },
  },
};
</script>
