<template>
  <div
    class="create-event-container bg-group-color-primaryBlue fixed top-0 bottom-0 right-0 left-0 flex justify-center items-center"
  >
    <BackgroundImage class="absolute top-0 bottom-0 right-0 left-0"></BackgroundImage>

    <div class="absolute flex justify-between top-0 right-0 left-0 p-6 sm:p-8">
      <LogoImage class="logo-img sm:ml-4"></LogoImage>
      <button
        @click="goToEvents()"
        class="h-max rounded bg-gray-900 bg-opacity-15 p-2 sm:p-4 animation-focus-default"
      >
        <CloseIcon class="close-icon w-6 h-6 sm:w-5 sm:h-5"></CloseIcon>
      </button>
    </div>

    <div class="mt-8 sm:mt-app-header block z-10">
      <transition name="fade-left" mode="out-in">
        <div class="steps-container relative rounded shadow bg-white flex justify-center">
          <div class="m-auto">
            <template v-if="currentStep === stepsEnum.type">
              <EventType @chooseEvent="buttonNextClick" :eventObject="eventObject" />
            </template>
            <template v-if="currentStep === stepsEnum.name">
              <EventName @pressEnter="buttonNextClick" :eventObject="eventObject" />
            </template>
            <template v-if="currentStep === stepsEnum.when">
              <EventTime :eventObject="eventObject" />
            </template>
            <template v-if="currentStep === stepsEnum.where">
              <EventWhere @pressEnter="buttonNextClick" :eventObject="eventObject" />
            </template>
            <template v-if="currentStep === stepsEnum.tickets">
              <EventTickets :eventObject="eventObject" :providers="providers" />
            </template>
          </div>
        </div>
      </transition>

      <ButtonBar
        class="mt-3 sm:mt-8"
        :buttonLeftAction="buttonBackClick"
        :buttonRightAction="buttonNextClick"
        :buttonRightSecondaryAction="buttonNextClick"
        :isLoading="isLoading"
        :showLeftButton="currentStep !== stepsEnum.type"
        :steps-count="5"
        :current-step="currentStep"
        :show-right-button-primary="currentStep !== 1"
        :showRightButtonSecondary="currentStep === stepsEnum.tickets"
        :buttonRightText="currentStep == stepsEnum.tickets ? 'Finish' : 'Continue'"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { DateTime } from 'luxon';
import ButtonBar from '@/components/baseComponents/ButtonBar.vue';
import EventType from '@/components/events/EventType.vue';
import EventName from '@/components/events/EventName.vue';
import EventTime from '@/components/events/EventTime.vue';
import EventWhere from '@/components/events/EventWhere.vue';
import EventTickets from '@/components/events/EventTickets.vue';
import LogoImage from '@/assets/create_event_logo.svg';
import CloseIcon from '@/assets/icon_close.svg';
import BackgroundImage from '@/assets/image_create_event_bgr.svg';
import { fetchPaymentProvidersService } from '@/services/global.service';
import { formatUrl } from '@/helpers';

export default {
  name: 'CreateEventFlow',
  components: {
    ButtonBar,
    LogoImage,
    CloseIcon,
    BackgroundImage,
    EventType,
    EventName,
    EventTime,
    EventWhere,
    EventTickets,
  },
  data() {
    return {
      providers: [],
      currentStep: 1,
      stepsEnum: Object.freeze({
        type: 1,
        name: 2,
        when: 3,
        where: 4,
        tickets: 5,
      }),
      eventObject: {
        eventTypeId: null,
        name: null,
        startDate: null,
        endDate: null,
        startTime: null,
        endTime: null,
        address: null,
        location: null,
        ticketPrice: null,
        paymentLinks: {},
        city: null,
        state: null,
        country: null,
      },
    };
  },
  computed: {
    ...mapGetters(['globalErrors', 'isLoading', 'group']),
  },
  watch: {
    globalErrors(errors) {
      errors.forEach(() => {});
    },
  },
  async created() {
    const providerLinks = await fetchPaymentProvidersService();
    const paymentProviderObject = {};

    providerLinks.forEach((p) => {
      paymentProviderObject[p.name] = {
        provider: p.id,
        url: '',
      };
    });
    this.eventObject.paymentLinks = paymentProviderObject;
    this.providers = providerLinks;
  },
  methods: {
    ...mapActions(['clearGlobalErrors', 'createGroupEvent', 'postRsvp']),
    buttonNextClick() {
      if (this.currentStep === this.stepsEnum.type) {
        if (this.validateType()) {
          this.goToStep(this.stepsEnum.name);
        }
      } else if (this.currentStep === this.stepsEnum.name) {
        if (this.validateName()) {
          this.goToStep(this.stepsEnum.when);
        }
      } else if (this.currentStep === this.stepsEnum.when) {
        this.goToStep(this.stepsEnum.where);
      } else if (this.currentStep === this.stepsEnum.where) {
        this.goToStep(this.stepsEnum.tickets);
      } else if (this.currentStep === this.stepsEnum.tickets) {
        if (this.validateTicket()) {
          this.submitEvent();
        }
      }
    },
    buttonBackClick() {
      if (this.currentStep === this.stepsEnum.name) {
        this.goToStep(this.stepsEnum.type);
      }
      if (this.currentStep === this.stepsEnum.when) {
        this.goToStep(this.stepsEnum.name);
      }
      if (this.currentStep === this.stepsEnum.where) {
        this.goToStep(this.stepsEnum.when);
      }
      if (this.currentStep === this.stepsEnum.tickets) {
        this.goToStep(this.stepsEnum.where);
      }
    },
    validateType() {
      if (!this.eventObject.eventTypeId) {
        this.$store.commit('UPDATE_GLOBAL_ERRORS', [
          {
            fieldName: 'Event Type',
            message: 'You must select an event type',
          },
        ]);
        return false;
      }
      return true;
    },
    validateName() {
      if (!this.eventObject.name) {
        this.$store.commit('UPDATE_GLOBAL_ERRORS', [
          {
            fieldName: 'Event Name',
            message: 'You must specify an event name',
          },
        ]);
        return false;
      }
      return true;
    },
    validateTicket() {
      let isValid = true;
      const urlPattern = /(https?:\/\/)?([\da-z\.-]+)\.([a-z]{2,6})([\/\w\.-]*)*\/??(?:&?[^=&]*=[^=&]*)*/;
      const providersKeys = [];

      // eslint-disable-next-line no-restricted-globals
      if (this.eventObject.ticketPrice && isNaN(this.eventObject.ticketPrice)) {
        this.$store.commit('UPDATE_GLOBAL_ERRORS', [
          {
            fieldName: 'Ticket Price',
            message: 'Ticket price must be a number',
          },
        ]);
        isValid = false;
      }

      for (const [key, value] of Object.entries(this.eventObject.paymentLinks)) {
        if (value.url && !urlPattern.test(value.url)) {
          providersKeys.push(key);
        } else if (value.url) {
          value.url = formatUrl(value.url);
        }
      }

      if (providersKeys.length) {
        this.$store.commit('UPDATE_GLOBAL_ERRORS', [
          {
            fieldName: 'Payment Links',
            providers: providersKeys,
            message: 'Payment link should be a valid URL',
          },
        ]);
        isValid = false;
      }

      return isValid;
    },
    goToStep(step, clearErrors = true) {
      if (clearErrors) {
        this.clearGlobalErrors();
      }
      this.currentStep = step;
    },
    goToEvents() {
      this.$router.push({
        name: 'events',
        params: {
          group: this.$route.params.group,
        },
      });
    },
    submitEvent() {
      let startTime;
      let endTime = null;

      if (this.eventObject.startDate && this.eventObject.startTime) {
        const date = DateTime.fromFormat(this.eventObject.startDate, 'D');

        const time = DateTime.fromFormat(this.eventObject.startTime, 'h:m a');
        startTime = date
          .plus({
            hours: time.hour,
            minutes: time.minute,
          })
          .toISO();
      }
      if (this.eventObject.endDate && this.eventObject.endTime) {
        const date = DateTime.fromFormat(this.eventObject.endDate, 'D');

        const time = DateTime.fromFormat(this.eventObject.endTime, 'h:m a');
        endTime = date
          .plus({
            hours: time.hour,
            minutes: time.minute,
          })
          .toISO();
      }
      const paymentLinks = [];

      this.providers.forEach((p) => {
        if (this.eventObject.paymentLinks[p.name].url) {
          paymentLinks.push(this.eventObject.paymentLinks[p.name]);
        }
      });

      const eventToSubmit = {
        name: this.eventObject.name,
        startDatetime: startTime,
        endDatetime: endTime,
        ticketPrice: this.eventObject.ticketPrice,
        isPublished: true,
        discussionEnabled: true,
        eventType: this.eventObject.eventTypeId,
        paymentLinks,
        location: this.eventObject.location,
        url: this.eventObject.url,
      };
      const objectToSubmit = {
        groupEvent: eventToSubmit,
        groupSlug: this.group.slug,
      };

      this.createGroupEvent(objectToSubmit).then((evnt) => {
        if (evnt.success) {
          // creator going by default
          this.postRsvp({
            eventId: evnt.groupEvent.id,
            groupSlug: this.group.slug,
            rsvp: 1,
          }).then(() => {
            this.$router.push({
              name: 'events',
              params: {
                event: evnt.groupEvent.id,
              },
            });
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.create-event-container {
  z-index: 999;
}

.close-icon path {
  stroke: #ffffff;
}

.fade-left-enter-active,
.fade-left-leave-active {
  transition: opacity 0.2s ease, transform 0.2s ease;
}

.fade-left-enter {
  opacity: 0;
  transform: translateX(100px);
}

.fade-left-leave-to {
  opacity: 0;
  transform: translateX(-100px);
}

.dot-list {
  align-items: center;
  display: flex;
  justify-content: center;

  & li {
    margin: 0 4px;
    border-radius: 9999px;
    height: 12px;
    width: 12px;
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.3);

    &.active {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
}

.logo-img {
  width: 128px;
  height: 48px;
}

.steps-container {
  width: calc(100vw - 48px);
  height: 440px;
}

@screen sm {
  .logo-img {
    width: 172px;
    height: 64px;
  }

  .steps-container {
    width: 700px;
    height: 440px;
  }
}

@screen lg {
  .steps-container {
    width: 854px;
    height: 486px;
  }
}
</style>
