<template>
  <div
    class="flex justify-between mt-4 relative sm:pb-0"
    :class="{ 'pb-[50px]': isShowPagination }"
  >
    <div class="inline-flex gap-1 items-center text-gray-600 text-sm font-normal leading-[140%]">
      Show
      <Dropdown
        class="w-[64px]"
        custom-class="h-[40px] !p-3"
        :options="entryOptions"
        :selectedOption="selectedEntry"
        @selection="changeEntryOption"
      />
      entries
    </div>
    <Paginate
      v-if="isShowPagination"
      :page-count="pageCount"
      :page-range="3"
      :margin-pages="2"
      :click-handler="changePage"
      hide-prev-next
      prev-text="Previous"
      next-text="Next"
      container-class="flex gap-2.5"
      active-class="border-orange-400"
      page-class="flex h-10 bg-white flex-col justify-center items-center gap-2.5 rounded border hover:border-orange-400 border-solid cursor-pointer"
      page-link-class="px-2.5 py-2.5 w-[40px] h-[40px] text-center text-sm font-medium leading-[150%]"
      prev-class="h-[40px] absolute bottom-0 left-0 w-1/2 sm:static sm:w-fit bg-white rounded border border-solid cursor-pointer hover:border-orange-400 group"
      prev-link-class="flex px-3.5 py-2 text-center justify-center sm:justify-normal text-sm font-medium leading-[150%] group-hover:text-primary"
      next-class="h-[40px] absolute bottom-0 right-0 w-1/2 sm:static sm:w-fit absolute bg-white rounded border border-solid cursor-pointer hover:border-orange-400 group"
      next-link-class="flex px-3.5 py-2 text-center justify-center sm:justify-normal text-sm font-medium leading-[150%] group-hover:text-primary"
    >
    </Paginate>
  </div>
</template>
<script>
import Paginate from 'vuejs-paginate';
import Dropdown from '@/components/Dropdown.vue';

export default {
  name: 'Pagination',

  components: {
    Dropdown,
    Paginate,
  },

  props: {
    count: {
      type: Number,
      default: 0,
    },
    entries: {
      type: String,
      default: '10',
    },
    isShowAllOption: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      entryOptions: [
        { label: '10', value: '10' },
        { label: '25', value: '25' },
        { label: '50', value: '50' },
        { label: '100', value: '100' },
      ],
    };
  },

  computed: {
    pageCount() {
      return this.count / this.entries;
    },
    selectedEntry() {
      return this.entryOptions.find((option) => option.value === this.entries);
    },
    isShowPagination() {
      return this.pageCount > 1;
    },
  },

  created() {
    if (this.isShowAllOption) {
      this.entryOptions.push({ label: 'All', value: 'all' });
    }
  },

  methods: {
    changePage(page) {
      this.$emit('changePage', page);
    },

    changeEntryOption(entry) {
      this.$emit('changeEntries', entry.value);
    },
  },
};
</script>
