import axios from 'axios';

export class FileUtil {
  static getFileFromEvent(event) {
    const file = event.target.files || event.dataTransfer.files;

    if (!file.length) {
      return;
    }

    // eslint-disable-next-line consistent-return
    return file[0];
  }

  static getFilesFromEvent(event) {
    const files = event.target.files || event.dataTransfer.files;

    if (!files.length) {
      return;
    }

    return files;
  }

  static getDataUrl(file) {
    return this.readFile(file, 'readAsDataURL');
  }

  static getFileExtensionFromUrl(url) {
    const lastDotIndex = url.lastIndexOf('.');

    if (lastDotIndex === -1) {
      return '';
    }

    return url.slice(lastDotIndex + 1);
  }

  static readFile(file, readFnName) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      // eslint-disable-next-line func-names
      reader.onload = function (event) {
        resolve(event.target.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader[readFnName](file);
    });
  }

  static getBlob(file) {
    if (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = function (event) {
          const arrayBuffer = event.target.result;
          const blob = new Blob([arrayBuffer], { type: file.type });
          resolve(blob);
        };

        reader.onerror = function (error) {
          reject(error);
        };

        reader.readAsArrayBuffer(file);
      });
    }
    console.error('No file selected');
    return null;
  }

  static forceFileDownload(response, title, isZip = false) {
    const blob = isZip ? response : new Blob([response.data]);

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', isZip ? 'file.zip' : title);

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  }

  static async downloadFile(url, fileName) {
    const fileExt = this.getFileExtensionFromUrl(url);
    const resolvedFilename = fileName.endsWith(fileExt) ? fileName : `${fileName}.${fileExt}`;

    try {
      const response = await axios({
        method: 'get',
        url: `${url}?x-request=xhr`,
        responseType: 'arraybuffer',
      });
      if (response) {
        this.forceFileDownload(response, resolvedFilename);
      }
    } catch (e) {
      console.error(e);
    }
  }
}
