var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex relative"},[_c('button',{staticClass:"relative border-solid appearance-none rounded cursor-pointer bg-white z-0 focus:outline-none",class:{
      disabled: _vm.disabled,
      'primary border-2 inline-block border-white px-6 py-3 transition duration-200 ease transform active:scale-95':
        _vm.scheme === _vm.SCHEMES.primary,
      'block w-full border border-gray-300 shadow-drop px-4 py-3.5': _vm.scheme === _vm.SCHEMES.full,
    },attrs:{"disabled":_vm.disabled},on:{"click":function($event){$event.stopPropagation();return _vm.click.apply(null, arguments)}}},[_c('div',{staticClass:"flex items-center pointer-events-none",class:{
        'justify-center text-3xl leading-8': _vm.scheme === _vm.SCHEMES.primary,
        'justify-between text-sm text-gray-900': _vm.scheme === _vm.SCHEMES.full,
      }},[_vm._t("emoji")],2)]),(_vm.showPicker)?_c('picker',{staticClass:"absolute top-100 left-0 z-10",class:{
      '!w-full': _vm.scheme === _vm.SCHEMES.full,
    },attrs:{"color":`rgb(${_vm.color})`,"data":_vm.emojiIndex,"set":"apple","title":"Pick your emoji...","emoji":"point_up","native":true},on:{"select":_vm.selectEmoji}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }