<template>
  <div class="flex relative">
    <button
      :disabled="disabled"
      @click.stop="click"
      class="relative border-solid appearance-none rounded cursor-pointer bg-white z-0 focus:outline-none"
      :class="{
        disabled,
        'primary border-2 inline-block border-white px-6 py-3 transition duration-200 ease transform active:scale-95':
          scheme === SCHEMES.primary,
        'block w-full border border-gray-300 shadow-drop px-4 py-3.5': scheme === SCHEMES.full,
      }"
    >
      <div
        class="flex items-center pointer-events-none"
        :class="{
          'justify-center text-3xl leading-8': scheme === SCHEMES.primary,
          'justify-between text-sm text-gray-900': scheme === SCHEMES.full,
        }"
      >
        <slot name="emoji"></slot>
      </div>
    </button>
    <picker
      class="absolute top-100 left-0 z-10"
      :class="{
        '!w-full': scheme === SCHEMES.full,
      }"
      :color="`rgb(${color})`"
      v-if="showPicker"
      :data="emojiIndex"
      set="apple"
      title="Pick your emoji..."
      emoji="point_up"
      :native="true"
      @select="selectEmoji"
    />
  </div>
</template>

<script>
import data from 'emoji-mart-vue-fast/data/apple.json';
import { Picker, EmojiIndex } from 'emoji-mart-vue-fast';
import 'emoji-mart-vue-fast/css/emoji-mart.css';

const emojiIndex = new EmojiIndex(data);
const SCHEMES = {
  primary: 'primary',
  full: 'full',
};

export default {
  name: 'EmojiButton',
  components: {
    Picker,
  },
  props: {
    scheme: {
      type: String,
      default: SCHEMES.primary,
    },
    /**
     * Whether or not the button should be disabled
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * The color to be used on the top bar of the emoji picker
     */
    color: {
      type: String,
      default: '86, 82, 77',
    },
  },

  data() {
    return {
      SCHEMES,
      showPicker: false,
      emojiIndex,
    };
  },

  watch: {
    showPicker(newValue) {
      if (this.scheme === SCHEMES.full) this.$emit('watcher', newValue);
    },
  },

  methods: {
    click() {
      this.showPicker = !this.showPicker;
      this.$emit('onClick');
    },
    selectEmoji(emoji) {
      this.$emit('pickedEmoji', emoji.native);
      this.showPicker = false;
    },
  },
};
</script>
<style scoped>
.primary {
  &::after {
    @apply absolute -z-1 rounded shadow-md opacity-100 transition-opacity ease-out duration-200;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    content: '';
  }

  &:hover,
  &:focus {
    @apply border-primary;
  }

  &:active {
    &::after {
      @apply opacity-0;
    }
  }
}

.disabled {
  @apply cursor-default bg-gray-300;
}
</style>
