import { DateTime } from 'luxon';

export class EventRecord {
  constructor(record = {}) {
    // eslint-disable-next-line no-underscore-dangle
    this._record = record;
    this.id = record.id || '';
    this.amountAttending = record.amountAttending || '';
    this.createdDatetime = record.createdDatetime || null;
    this.description = record.description || null;
    this.endDatetime = record.endDatetime || null;
    this.isAttending = record.isAttending || false;
    this.name = record.name || '';
    this.startDatetime = record.startDatetime || null;
    this.url = record.url || '';
  }

  get formatMonthDay() {
    if (this.startDatetime) {
      if (DateTime.fromISO(this.startDatetime).toFormat('MMMM, d') !== DateTime.fromISO(this.endDatetime).toFormat('MMMM, d')) {
        return `${DateTime.fromISO(this.startDatetime).toLocaleString(DateTime.DATE_FULL)}, ${DateTime.fromISO(this.startDatetime).toFormat('h:mm a')} - ${DateTime.fromISO(this.endDatetime).toLocaleString(DateTime.DATE_FULL)}, ${DateTime.fromISO(this.endDatetime).toFormat('h:mm a')}`;
      }

      return `${DateTime.fromISO(this.startDatetime).toLocaleString(DateTime.DATE_FULL)}, ${DateTime.fromISO(this.startDatetime).toFormat('h:mm a')} - ${DateTime.fromISO(this.endDatetime).toFormat('h:mm a')}`;
    }

    return 'N/A';
  }

  get formatStartEnd() {
    if (this.startDatetime && this.endDatetime) {
      const startTime = DateTime.fromISO(this.startDatetime).toFormat('h:mm a');
      const endTime = DateTime.fromISO(this.endDatetime).toFormat('h:mm a');

      return `${startTime} - ${endTime}`;
    }

    return 'N/A';
  }

  get isEventHasEnded() {
    const eventDateTime = DateTime.fromISO(this.endDatetime);
    const currentDateTime = DateTime.now();

    return eventDateTime < currentDateTime;
  }
}
