<template>
  <div class="flex relative mt-3">
    <CloseIcon
      v-if="isGroupAdmin || isOwner"
      @click="deleteComment"
      class="close-dialog-icon cursor-pointer animation-focus-default w-4 h-4 absolute top-3 right-3"
    />
    <img
      class="block w-[28px] md:w-11 h-[28px] md:h-11 bg-white shadow-md rounded-full"
      :src="comment.profile.profilePicture"
      :alt="comment.profile.name"
    />
    <div class="w-full ml-4">
      <div class="flex flex-col mb-2 bg-gray-100 p-3 rounded">
        <p
          class="flex items-center gap-2 text-gray-900 mb-0.5 md:mb-2 font-semibold text-sm md:text-base"
        >
          {{ comment.profile.fullName }}
          <span class="w-[4px] h-[4px] block rounded-full bg-gray-400 relative" />
          <span class="text-gray-700 text-xs opacity-80 font-normal">{{ comment.postedDate }}</span>
        </p>
        <p class="self-stretch text-gray-900 text-sm whitespace-pre-line">{{ comment.content }}</p>
      </div>
      <div class="flex">
        <div class="flex items-center mr-1">
          <button
            type="button"
            @click="handleLike"
            class="hover:bg-gray-200 px-2 py-1.5 text-center text-xs rounded-sm"
            :class="{
              'hover:text-orange-600 text-orange-400': isCommentLiked,
              'hover:text-gray-900 text-gray-600': !isCommentLiked,
            }"
          >
            {{ isCommentLiked ? 'Liked' : 'Like' }}
          </button>
          <span
            v-if="comment.likes.length"
            class="flex items-center gap-1 text-gray-700 text-xs opacity-80 pl-0.5"
          >
            <HeartEmptyIcon class="liked_icon h-5 opacity-80 w-[12px]" />
            {{ comment.likes.length }}
          </span>
        </div>
      </div>
    </div>

    <transition name="component-fade" mode="out-in">
      <DialogDefault
        v-if="isShowDeleteModal"
        header="Delete Post"
        text="Are you sure you want to delete this post?"
        @cancel="hideDeleteModal"
        @apply="deleteComment"
        is-show-close-btn
        scheme="secondary"
        apply-btn-text="Delete"
        container-class="sm:w-[512px]"
      />
    </transition>
  </div>
</template>
<script>
import CloseIcon from '@/assets/icon_close.svg';
import { MessageRecord } from '@/records/message.record';
import { deletePostService, dislikePostService, likePostService } from '@/services/feed.service';
import { mapGetters } from 'vuex';
import DialogDefault from '@/components/DialogDefault.vue';
import HeartEmptyIcon from '@/assets/icon_heart.svg';

export default {
  name: 'Comment',
  components: { HeartEmptyIcon, DialogDefault, CloseIcon },
  props: {
    comment: {
      type: MessageRecord,
      required: true,
    },
  },
  data() {
    return {
      isShowDeleteModal: false,
    };
  },
  computed: {
    ...mapGetters(['user', 'profiles', 'group']),
    isOwner() {
      return this.comment.profile.id === this.user.id;
    },
    isGroupAdmin() {
      const currentProfile = this.profiles.find((p) => p.profile === this.user.id);
      return !!currentProfile && currentProfile.isGroupAdmin;
    },
    isCommentLiked() {
      return !!this.comment.likes.find((p) => p.id === this.user.id);
    },
  },
  methods: {
    hideDeleteModal() {
      this.isShowDeleteModal = false;
    },

    async handleLike() {
      if (this.comment.profile.id !== this.user.id) {
        if (this.isCommentLiked) {
          await dislikePostService(this.group.slug, this.comment.id);
        } else {
          await likePostService(this.group.slug, this.comment.id);
        }

        this.$emit('refetch');
      }
    },

    showDeleteModal() {
      this.isShowDeleteModal = true;
    },

    async deleteComment() {
      try {
        await deletePostService(this.group.slug, this.comment.id);
        this.$emit('refetch');
      } catch (e) {
        console.log(e);
      } finally {
        this.hideDeleteModal();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.liked_icon path {
  @apply stroke-orange fill-orange;
}
</style>
