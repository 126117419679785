<template>
  <div>
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="4" fill="#EC856E" fill-opacity="0.06" />
      <g clip-path="url(#clip0_4442_41337)">
        <path
          d="M24.0689 32.1922L14 21.0221L17.498 15.8076H30.4847L33.9759 21.0121L24.0689 32.1922Z"
          fill="#F96E47"
        />
        <path
          d="M24.0714 32.1894L33.9759 21.0121L30.4847 15.8076H24.0714V32.1894Z"
          fill="#F96E47"
        />
        <path d="M14 21.0225L24.0689 32.1926L33.9479 21.0443L14 21.0225Z" fill="#F96E47" />
        <path d="M24.0714 21.0332V32.1895L33.9479 21.044L24.0714 21.0332Z" fill="#F96E47" />
        <path d="M24.0689 32.1922L19.927 21.0439H28.0729L24.0689 32.1922Z" fill="#F85429" />
        <path d="M28.0729 21.0439H19.927L24.0716 15.8076L28.0729 21.0439Z" fill="#F85429" />
        <path d="M14 21.0221L19.927 21.0439L17.498 15.8076L14 21.0221Z" fill="#EE4E25" />
        <path d="M34 21.0221L28.073 21.0439L30.5021 15.8076L34 21.0221Z" fill="#FF5CA8" />
        <path d="M24.0714 21.0439V32.1853L28.073 21.0439H24.0714Z" fill="#EE4E25" />
        <path d="M24.0714 15.8076V21.0435H28.073L24.0714 15.8076Z" fill="#EE4E25" />
        <path d="M34 21.0221L28.073 21.0439L30.5021 15.8076L34 21.0221Z" fill="#EE4E25" />
      </g>
      <defs>
        <clipPath id="clip0_4442_41337">
          <rect width="20" height="20" fill="white" transform="translate(14 14)" />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>
<script>
export default {
  name: 'IconExclusive',
};
</script>
