<template>
  <div class="w-full">
    <div class="mb-8 sm:mb-12">
      <h1 class="text-2xl mb-9">Edit Group Info</h1>

      <div class="mb-6 flex w-full flex-wrap sm:flex-nowrap">
        <Dropdown
          placeholder="Select an option"
          :fullWidth="true"
          scroll-overflow
          primary-color
          class="w-full sm:w-1/2 sm:mr-4"
          :options="optionsColors"
          :selectedOption="selectedColorOption"
          @selection="changeColor"
          label="Group Color"
        />
        <Dropdown
          placeholder="Select an option"
          :fullWidth="true"
          primary-color
          class="w-full sm:w-1/2 sm:ml-4 mt-8 sm:mt-0"
          :options="optionsClassifications"
          :selectedOption="selectedClassificationOption"
          @selection="changeClassification"
          label="Group Classification"
        />
      </div>

      <div class="flex flex-wrap sm:flex-nowrap w-full mb-8 sm:mb-6">
        <TextInput
          id="groupName"
          label="Group Name"
          customWidth
          :invalid="invalidFields.includes('name')"
          class="sm:mr-4 w-full sm:w-1/2"
          v-model="editedGroup.name"
        >
        </TextInput>
        <TextInput
          id="groupSubtitle"
          label="Group Subtitle (ex: Class of '85)"
          :optional="true"
          customWidth
          class="sm:ml-4 w-full sm:w-1/2 mt-8 sm:mt-0"
          v-model="editedGroup.subtitle"
        >
        </TextInput>
      </div>

      <div class="relative mb-8 sm:mb-12">
        <label class="block sm:hidden text-sm font-semibold mb-2">Group Description</label>
        <VueEditor
          useCustomImageHandler
          :customModules="customModulesForEditor"
          :editorOptions="editorSettings"
          ref="description"
          @input="editorInput()"
          @image-added="handleImageAdded"
          :class="{
            'border-red-600': vueEditorErrorMessage || invalidFields.includes('description'),
          }"
          class="bg-white border shadow text-lg text-gray-900 font-sans rounded mb-4 md:w-full w-full lg:w-full"
          v-model="editedGroup.description"
          placeholder="Add a group description. What is this group for?"
          :editorToolbar="isMobile ? customToolbarMobile : customToolbar"
        ></VueEditor>

        <div
          v-if="vueEditorErrorMessage || invalidFields.includes('description')"
          class="absolute text-sm font-semibold text-red-600 -bottom-6 sm:-bottom-7"
        >
          {{ vueEditorErrorMessage || 'Description is required' }}
        </div>
      </div>

      <div>
        <div class="flex justify-between items-center mb-4">
          <h2 class="text-2xl">Events</h2>
          <div class="flex">
            <div
              class="cursor-pointer hidden sm:flex items-center animation-focus-default"
              @click="addEvent()"
            >
              <PlusCircleIcon class="plus-circle-icon w-5 h-5 mr-2"></PlusCircleIcon>
              <span class="text-sm font-semibold">Add Another Event</span>
            </div>

            <button
              @click="showPastEvents"
              class="animation-focus-default ml-8 p-3 sm:px-8 sm:py-3 text-sm font-semibold rounded border border-gray-400"
            >
              Past Events
            </button>
          </div>
        </div>

        <EventList
          class="w-full pt-2 mb-12"
          :is-preview="true"
          :show-past-events="false"
          :showPastEventsLink="false"
          :showAddNewEvent="true"
        >
        </EventList>
      </div>

      <div>
        <div class="flex justify-between items-center mb-4">
          <h2 class="text-2xl">Fundraisers</h2>
          <div class="flex">
            <div
              class="cursor-pointer hidden sm:flex items-center animation-focus-default"
              @click="addFundraiser()"
            >
              <PlusCircleIcon class="plus-circle-icon w-5 h-5 mr-2"></PlusCircleIcon>
              <span class="text-sm font-semibold">Add Another Fundraiser</span>
            </div>

            <button
              @click="showPastFundraisers"
              class="animation-focus-default ml-8 p-3 sm:px-8 sm:py-3 text-sm font-semibold rounded border border-gray-400"
            >
              Past Fundraisers
            </button>
          </div>
        </div>

        <div
          class="cursor-pointer sm:hidden flex items-center animation-focus-default mb-6"
          @click="addFundraiser()"
        >
          <PlusCircleIcon class="plus-circle-icon w-5 h-5 mr-2"></PlusCircleIcon>
          <span class="text-sm font-semibold">Add Another Fundraiser</span>
        </div>

        <div class="w-full bg-white rounded shadow p-6">
          <h5 class="pb-4 w-4/5 sm:w-full">Add a GoFundMe link so group members can give</h5>
          <div v-for="(fundraiser, index) in currentFundraisers" :key="fundraiser.id">
            <div
              v-if="!fundraiser.isDeleted"
              class="flex"
              :class="{ 'mb-4': index !== currentFundraisers.length - 1 }"
            >
              <div class="flex w-full items-center">
                <TextInput
                  :invalid="!!getErrorMessage('fundraisers', index)"
                  :error-message="
                    getErrorMessage('fundraisers', index) &&
                    getErrorMessage('fundraisers', index).url[0]
                  "
                  class=""
                  v-model="fundraiser.url"
                ></TextInput>
                <CloseIcon
                  @click="removeFundraiser(index)"
                  class="close-icon cursor-pointer animation-focus-default w-6 h-6 ml-6 sm:ml-8 sm:mr-1"
                ></CloseIcon>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-8 mb-4 p-6 bg-white shadow rounded">
        <span class="text-gray-600">
          Need help changing your group? Check out our
          <a
            target="_blank"
            rel="noopener noreferrer"
            class="text-primary underline"
            href="https://reunacy.com/guide/"
          >
            guide</a
          >.
        </span>
      </div>
    </div>

    <div class="flex justify-between sm:justify-start w-full border-t border-gray-400 pt-2 sm:pt-4">
      <Button
        class="cancel-btn mt-7 sm:mr-4 sm:mb-8"
        type="text"
        :disabled="isLoading"
        @onClick="onCancel()"
      >
        <span class="text-0_8xl font-semibold mx-2">Cancel</span>
      </Button>
      <Button
        class="save-btn mt-7 sm:mb-8"
        type="primary"
        color-custom
        :primaryFullSize="false"
        :disabled="isLoading"
        :loading="isLoading"
        @onClick="updateGroup()"
      >
        <span class="text-0_8xl text-white font-normal mx-6">Save</span>
      </Button>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue';
import TextInput from '@/components/TextInput.vue';
import Dropdown from '@/components/Dropdown.vue';
import CloseIcon from '@/assets/icon_close.svg';
import PlusCircleIcon from '@/assets/icon_plus_circled.svg';
import { VueEditor } from 'vue2-editor';
import ImageResize from 'quill-image-resize-module';
import { mapActions, mapGetters } from 'vuex';
import EventList from '@/components/events/EventList.vue';

export default {
  name: 'EditProfileForm',
  components: {
    Button,
    TextInput,
    Dropdown,
    VueEditor,
    EventList,
    CloseIcon,
    PlusCircleIcon,
  },
  props: {
    /**
     * Profile to display
     */
    currentGroup: {
      default: () => {},
    },
    /**
     * Save button handler
     */
    onSave: {
      type: Function,
    },
    /**
     * Cancel button handler
     */
    onCancel: {
      type: Function,
    },
    /**
     * the list of possible errors
     */
    editErrors: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    this.editedGroup = { ...this.currentGroup };
    if (this.currentGroup && this.currentGroup.groupClassification) {
      this.editedGroup.groupClassification = this.currentGroup.groupClassification.id;
    }
    // eslint-disable-next-line prefer-destructuring
    this.selectedColorOption = this.optionsColors.filter(
      (color) => color.value === this.editedGroup.primaryColor,
    )[0];
    // eslint-disable-next-line prefer-destructuring
    this.selectedClassificationOption = this.optionsClassifications.filter(
      (type) => type.id === this.editedGroup.groupClassification,
    )[0];

    this.currentFundraisers = this.editedGroup.fundraisers ? [...this.editedGroup.fundraisers] : [];

    if (this.currentFundraisers && !this.currentFundraisers.length) {
      this.addFundraiser();
    }
  },
  computed: {
    ...mapGetters(['group', 'groupEvents', 'isLoading']),
    showEventDateErrorState() {
      return (
        this.invalidFields.includes('eventDate') ||
        (this.editErrors.length && this.editErrors.indexOf('event date') >= 0)
      );
    },
    isMobile() {
      return document.body.clientWidth <= 576;
    },
  },
  data() {
    return {
      editedGroup: {},
      jsDatePicker: {},
      invalidFields: [],
      requiredFields: ['name', 'description'],
      customToolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['image'],
        ['clean'],
        ['link'],
      ],
      customToolbarMobile: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['clean'],
      ],
      customModulesForEditor: [
        {
          alias: 'imageResize',
          module: ImageResize,
        },
      ],
      editorSettings: {
        modules: {
          imageResize: {
            modules: ['Resize'],
          },
        },
      },
      optionsColors: [
        {
          label: 'Primary Blue',
          value: '3, 129, 143',
          iconColorLarge: 'bg-group-color-primaryBlue',
        },
        {
          label: 'Maroon',
          value: '128, 0, 0',
          iconColorLarge: 'bg-group-color-maroon',
        },
        {
          label: 'Crimson',
          value: '220, 20, 60',
          iconColorLarge: 'bg-group-color-crimson',
        },
        {
          label: 'Red',
          value: '255, 36, 0',
          iconColorLarge: 'bg-group-color-red',
        },
        {
          label: 'Burnt Orange',
          value: '204, 85, 0',
          iconColorLarge: 'bg-group-color-burntOrange',
        },
        {
          label: 'Orange',
          value: '255, 106, 0',
          iconColorLarge: 'bg-group-color-orange',
        },
        {
          label: 'Green',
          value: '0, 128, 0',
          iconColorLarge: 'bg-group-color-green',
        },
        {
          label: 'Royal Blue',
          value: '65, 105, 225',
          iconColorLarge: 'bg-group-color-royalBlue',
        },
        {
          label: 'Navy Blue',
          value: '0, 0, 128',
          iconColorLarge: 'bg-group-color-navyBlue',
        },
        {
          label: 'Midnight Blue',
          value: '4, 30, 66',
          iconColorLarge: 'bg-group-color-midnightBlue',
        },
        {
          label: 'Light Blue',
          value: '173, 216, 230',
          iconColorLarge: 'bg-group-color-lightBlue',
        },
        {
          label: 'Purple',
          value: '138, 43, 226',
          iconColorLarge: 'bg-group-color-purple',
        },
        {
          label: 'Gold',
          value: '219, 174, 27',
          iconColorLarge: 'bg-group-color-gold',
        },
        {
          label: 'Black',
          value: '0, 0, 0',
          iconColorLarge: 'bg-group-color-black',
        },
        {
          label: 'Brown',
          value: '139, 69, 19',
          iconColorLarge: 'bg-group-color-brown',
        },
      ],
      optionsClassifications: [
        {
          label: 'Academic',
          id: 1,
          icon: 'academic',
        },
        {
          label: 'Family',
          id: 2,
          icon: 'home',
        },
        // {
        //   label: 'Hobby',
        //   id: 3,
        //   icon: 'home',
        // },
        {
          label: 'Work',
          id: 4,
          icon: 'work',
        },
        {
          label: 'Club',
          id: 5,
          icon: 'users',
        },
        {
          label: 'Faith',
          id: 6,
          icon: 'users',
        },
      ],
      selectedColorOption: {
        label: 'Primary Blue',
        value: '3, 129, 143',
        iconColorLarge: 'bg-group-color-primaryBlue',
      },
      selectedClassificationOption: {
        label: 'Work',
        id: 1,
        icon: 'work',
      },
      vueEditorErrorMessage: '',
      currentFundraisers: [],
    };
  },
  methods: {
    ...mapActions(['uploadGroupFile']),
    changeColor(option) {
      this.selectedColorOption = option;
      this.editedGroup.primaryColor = option.value;
    },
    changeClassification(option) {
      this.selectedClassificationOption = option;
      this.editedGroup.groupClassification = option.id;
    },
    updateGroup() {
      this.checkForErrors();
      if (this.invalidFields.length) {
        return;
      }

      if (
        typeof this.editedGroup.eventDate === 'string' &&
        this.editedGroup.eventDate.length === 0
      ) {
        this.editedGroup.eventDate = null;
      }

      const invalidFundraisers = this.currentFundraisers
        ? this.currentFundraisers.filter((item) => !item.url)
        : [];

      if (invalidFundraisers.length) {
        const that = this;
        invalidFundraisers.forEach((fundraiser) => {
          that.currentFundraisers.splice(that.currentFundraisers.indexOf(fundraiser), 1);
        });
      }

      this.editedGroup.fundraisers = [...this.currentFundraisers];

      this.onSave(this.editedGroup, this.group.slug);
    },
    checkForErrors() {
      this.invalidFields = []; // reset errors before checking for them
      this.requiredFields.forEach((field) => {
        if (!this.editedGroup || !this.editedGroup[field]) {
          this.invalidFields.push(field);
        }
      });
      return !!this.invalidFields.length;
    },
    getErrorMessage(field, index) {
      if (field && index >= 0) {
        const errorField = this.editErrors.find((el) => !!el[field]);
        return errorField && errorField[field] && errorField[field][index];
      }

      return this.editErrors.find((el) => !!el[field]) || null;
    },
    editorInput() {
      if (
        this.editedGroup.description.length &&
        (this.vueEditorErrorMessage || this.invalidFields.includes('description'))
      ) {
        this.vueEditorErrorMessage = '';
        const descriptionIndex = this.invalidFields.indexOf('description');
        this.invalidFields.splice(descriptionIndex, 1);
      }
    },
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      this.vueEditorErrorMessage = '';
      const formData = new FormData();
      formData.append('file', file);
      this.uploadGroupFile({
        groupSlug: this.editedGroup.slug,
        file: formData,
      })
        .then((result) => {
          const { url } = result; // Get url from response
          Editor.insertEmbed(cursorLocation, 'image', url);
          resetUploader();
        })
        .catch(() => {
          this.vueEditorErrorMessage = 'Error: Your image could not be inserted. Please try again.';
        });
    },
    addFundraiser() {
      this.currentFundraisers.push({
        id: null,
        url: '',
        isDeleted: false,
      });
    },
    addEvent() {
      this.$router.push({
        name: 'create-event',
      });
    },
    removeFundraiser(index) {
      this.currentFundraisers[index].isDeleted = true;
    },
    showPastFundraisers() {},
    showPastEvents() {
      this.$router.push({
        name: 'events',
        params: {
          group: this.$route.params.group,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.flex-2 {
  flex: 2;
}

.save-btn {
  min-width: 137px;
}

.close-icon path {
  stroke: #c8cacd;
}

.quillWrapper .ql-snow.ql-toolbar {
  @apply py-3 px-5;
  border-bottom: 1px solid #dfdfe0;
}

#quill-container .ql-editor {
  @apply break-words;
  word-break: break-word;
}

@media all and (max-width: 576px) {
  .quillWrapper .ql-toolbar.ql-snow .ql-formats {
    margin-right: 10px;
  }
}
</style>
