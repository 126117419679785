<template>
  <div
    @mouseover="hoveredElement = groupIndex"
    @mouseleave="hoveredElement = null"
    @click="chooseGroup(group.slug)"
    class="group-card"
    :style="[
      {
        borderColor:
          hoveredElement === groupIndex ? `var(--group${groupIndex})` : '',
      },
    ]"
    :key="`group_${groupIndex}_${updatedAt}`"
  >
    <div
      class="group-card-bar"
      :style="`backgroundColor: rgba(${group.primaryColor});`"
    ></div>

    <div class="flex flex-col-reverse sm:flex-row sm:justify-between">
      <div class="flex flex-col p-6 sm:py-8 sm:px-9">
        <div class="mb-4">
          <h2 class="flex justify-between sm:inline-block truncate max-w-550px">
            {{ group.name }}
            <Badge v-if="group.groupClassification && group.groupClassification.name"
                   class="group-card-badge ml-3 sm:ml-0"
                   :icon="group.groupClassification && group.groupClassification.name.toLowerCase()"
                   :is-short="true"
                   :is-transparent="false"
                   :text="group.groupClassification && group.groupClassification.name.toLowerCase()"
                   :background="getColorForBadge(group.primaryColor)"
            ></Badge>
          </h2>
          <div v-if="group.subtitle"
               class="text-gray-700 font-semibold text-base">
            {{ group.subtitle }}
          </div>
        </div>

        <p class="text-sm font-normal text-gray-900">
          {{ group.memberCount }}
          {{ 'member' | pluralize(group.memberCount) }}
        </p>

        <ProfilesBanner
          @click.stop.prevent
          :custom-border-color="group.primaryColor"
          :current-group-slug="group.slug"
          :showViewAllProfilesLink="false"
          :profiles="group.profiles"
          :totalMemberCount="group.memberCount"
          :useMargins="false"
        />

        <div class="badges flex">
           <Badge
            class="mr-4 mt-3 white"
            icon="diamond"
            :background="group.primaryColor"
            text="Exclusive"
            v-if="group.isSubscribed"
          ></Badge>
          <Badge
            class="mr-4 mt-3 white"
            icon="people"
            :background="group.primaryColor"
            text="Public"
            v-if="!group.isSubscribed"
          ></Badge>
          <Badge
            class="mr-4 mt-3"
            icon="calendar"
            :background="getColorForBadge(group.primaryColor)"
            :text="group.futureEventCount + (group.futureEventCount === 1 ? ' Event' : ' Events')"
            v-if="group.futureEventCount"
          ></Badge>
          <Badge
            class="mt-3"
            icon="heart"
            :background="getColorForBadge(group.primaryColor)"
            text="Fundraisers"
            v-if="group.donation_link"
          ></Badge>
        </div>
      </div>

      <div v-if="group.groupPhoto" class="group-photo">
        <ProfilePhoto :url="group.groupPhoto" useGroupPlaceholder/>
      </div>
    </div>
  </div>
</template>

<script>
import ProfilesBanner from '@/components/ProfilesBanner.vue';
import ProfilePhoto from '@/components/ProfilePhoto.vue';
import Badge from '@/components/Badge.vue';

export default {
  name: 'GroupItem',
  components: {
    Badge,
    ProfilesBanner,
    ProfilePhoto,
  },
  props: {
    group: {
      required: true,
    },
    groupIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      updatedAt: '',
      hoveredElement: null,
    };
  },
  methods: {
    chooseGroup(slug) {
      this.$emit('chooseGroup', slug);
    },
    getColorForBadge(rgb) {
      return `${rgb}, 0.2`;
    },
  },
};
</script>

<style lang="scss">
.group-card {
  @apply relative bg-white rounded shadow cursor-pointer transition-colors duration-200 ease-out;

  .group-card-bar {
    @apply h-full w-1 absolute top-0 left-0 right-auto rounded-l;
  }

  &:hover {
    @apply shadow-md duration-200 ease-out;
  }

  .group-photo {
    @apply flex-shrink-0 rounded-r overflow-hidden text-xs opacity-100;
    width: 250px;
    height: auto;
    position: relative;
    overflow: hidden;

    img {
      max-width: none;
      width: calc(262px * 2.67); // 1440:540
      height: 262px; // maximum for dynamic container
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @media all and (max-width: 576px) {
        width: calc(140px * 2.67);
        height: 140px;
      }
    }
  }

  .group-card-badge {
    top: 16px;
    right: 16px;
    @apply absolute;
  }
}

.fade-left-enter-active,
.fade-left-leave-active {
  transition: opacity 0.2s ease, transform 0.2s ease;
}

.fade-left-enter,
.fade-left-leave-to {
  opacity: 0;
  transform: translateX(-10px);
}

@media screen and (max-width: 576px) {
  .group-card {
    .group-card-bar {
      @apply h-1 w-full top-0 right-0 rounded-t rounded-b-none z-10;
    }

    .group-photo {
      @apply rounded-t rounded-b-none;

      width: auto;
      height: 140px;
      overflow: hidden;

      img {
        min-width: 100%;
        height: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .group-card-badge {
      top: auto;
      right: auto;
      @apply relative;
    }
  }
}
</style>
