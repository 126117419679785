<template>
  <div class="fixed bottom-4 right-0">
    <div v-if="isOpenMenu" class="absolute -top-44 right-4 w-[155px]">
      <Button
        class="w-full h-10 mb-2"
        :compact="true"
        :primary-full-size="false"
        type="primary"
        @onClick="upload"
      >
        Upload Media
      </Button>
      <Button
        v-if="isCanCreateFolder"
        class="w-full h-10 flex bg-white"
        :compact="true"
        :primary-full-size="false"
        type="secondary"
        @onClick="create"
        custom-class="bg-white border-orange-400"
      >
        <template v-slot:icon>
          <IconPlus class="w-[16px]" />
        </template>
        <span class="text-primary">Create a Folder</span>
      </Button>
    </div>
    <button
      type="button"
      v-if="showButton"
      @click="toggleMenu"
      class="fixed bottom-4 right-4 w-[60px] h-[60px] rounded-full bg-orange-400 flex justify-center items-center cursor-pointer"
    >
      <Plus class="icon" :class="{ 'rotate-45': isOpenMenu }" />
    </button>
  </div>
</template>

<script>
import Plus from '@/assets/icon_plus_simple.svg';
import Button from '@/components/Button.vue';
import IconPlus from '@/assets/icon_plus.svg';

export default {
  name: 'FloatingButton',
  components: {
    IconPlus,
    Button,
    Plus,
  },
  props: {
    isCanCreateFolder: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showButton: false,
      isOpenMenu: false,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleScroll);
  },
  methods: {
    toggleMenu() {
      this.isOpenMenu = !this.isOpenMenu;
    },
    handleScroll() {
      const isMobile = window.innerWidth < 576;
      const scrollPosition = window.scrollY;
      this.showButton = isMobile && scrollPosition > window.innerHeight;
    },
    upload() {
      this.isOpenMenu = false;
      this.$emit('upload');
    },
    create() {
      this.isOpenMenu = false;
      this.$emit('create');
    },
  },
};
</script>
<style lang="scss" scoped>
.icon {
  path {
    stroke: #fff;
  }
}
</style>
