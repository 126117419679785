var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.close),expression:"close"}],staticClass:"relative inline-flex"},[_c('button',{ref:"trigger",staticClass:"trigger flex justify-between items-center text-sm font-normal cursor-pointer origin-bottom w-auto focus:outline-none py-0 px-0",attrs:{"aria-haspopup":"listbox","aria-expanded":_vm.ariaExpanded,"disabled":_vm.disabled},on:{"click":_vm.toggleMenu,"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;$event.stopPropagation();$event.preventDefault();return _vm.close.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;$event.stopPropagation();$event.preventDefault();return _vm.open.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab"))return null;return _vm.close.apply(null, arguments)}]}},[(_vm.label)?_c('span',{staticClass:"dropdown-label text-sm font-sans font-normal"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),(_vm.selectedOptions.length)?_c('span',{staticClass:"counter"},[_vm._v(" "+_vm._s(_vm.selectedOptions.length)+" ")]):_vm._e(),_c('span',{staticClass:"inline-block transition-transform duration-200 ease transform ml-2 mt-1",class:{
            'rotate-180': _vm.ariaExpanded,
          },attrs:{"aria-hidden":"true"}},[_c('Chevron',{staticClass:"w-3"})],1)]),_c('div',{ref:"menu",staticClass:"menu absolute bg-white rounded z-20",class:[
         {'menu-hidden pointer-events-none': !_vm.ariaExpanded},
         ],attrs:{"role":"listbox"},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab"))return null;return _vm.close.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;$event.stopPropagation();$event.preventDefault();return _vm.moveDown()},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;$event.stopPropagation();$event.preventDefault();return _vm.moveUp()}]}},[_c('ul',{staticClass:"p-2 border border-gray-300 rounded"},[_vm._l((_vm.options),function(option,index){return _c('li',{key:`${option.value}_${index}`,staticClass:"cursor-pointer px-3 py-2 text-base text-left w-full whitespace-no-wrap hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition-colors duration-100 ease rounded flex items-center justify-between",class:[{
          'selected': _vm.selected(option),
          'mb-1': index !== _vm.options.length - 1
         }],attrs:{"role":"option","tabindex":"-1"},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.stopPropagation();$event.preventDefault();return _vm.selectOption(option)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;$event.stopPropagation();$event.preventDefault();return _vm.selectOption(option)}],"click":function($event){return _vm.selectOption(option)}}},[_c('div',{staticClass:"text-sm font-normal flex items-center"},[(option.iconImport)?_c(option.iconImport,{tag:"component",staticClass:"mr-2"}):_vm._e(),_vm._v(" "+_vm._s(option.label)+" ")],1),_c('div',{staticClass:"px-2 text-small font-semibold text-center min-w-8 rounded-3xl bg-gray-200",class:{'bg-group-color-primaryBlue text-white': _vm.selected(option)}},[_vm._v(" "+_vm._s(option.count)+" ")])])}),_vm._t("default")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }